<template>
  <li
    class="nav-item"
    :class="{
      'has-children': hasChildren,
      'has-image': hasImage
    }"
    @click="$emit('click', $event)">
    <router-link
      :to="link"
      v-if="hasLink">
      {{ text }}
    </router-link>
    <slot v-if="hasChildren || hasImage || !hasLink"></slot>
  </li>
</template>

<script>
export default {
  name: 'NavItem',
  props: {
    link: Object,
    text: String,
    hasChildren: {
      type: Boolean,
      default: false
    },
    hasImage: {
      type: Boolean,
      default: false
    },
    hasLink: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss">
.nav-item {
  display: inline-flex;
  align-items: center;
  height: 100%;
  padding: 1.25rem 0;
  margin: 0 .75rem;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  &.has-children {
    position: relative;
    &.has-image {
      > a {
        &:after {
          display: none;
        }
      }
    }
    &:hover {
      .sub-menu {
        opacity: 1;
        pointer-events: all;
      }
    }
    > a {
      &:after {
        content: '';
        width: 0;
        height: 0;
        border-top: 5px solid #fff;
        border-bottom: 4px solid transparent;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        display: inline-flex;
        transform: translateY(-2px);
        margin-left: 4px;
      }
    }
    .sub-menu {
      opacity: 0;
      position: absolute;
      z-index: 999;
      background: #fff;
      box-shadow: 0px 4px 10px #d8dfe4;
      top: 100%;
      // bottom: -60px;
      padding: .75rem 0;
      width: auto;
      min-width: 200px;
      left: 50%;
      transform: translateX(-50%);
      pointer-events: none;
      transition: all 0.3s ease-in-out;
      li {
        padding: .5rem .75rem;
        max-width: 100%;
        display: flex;
        color: #212741;
        a {
          color: #212741;
          &:hover {
            color: #0158E3;
          }
        }
      }
    }
  }
  a {
    color: #fff;
    text-decoration: none;
  }
}
</style>
