<template>
  <BaseLayout
    :title="marketingCompany !== {} && 'marketing_company' in marketingCompany
      ? `${marketingCompany.marketing_company.name}`
      : 'ARC Score'"
    :customClasses="['agency']"
    :image="marketingCompany !== {} && 'marketing_agency' in marketingCompany && 'favicon_url' in marketingCompany.marketing_company
      ? marketingCompany.marketing_company.favicon_url
      : ''"
    :showImage="
      marketingCompany !== {} && 'marketing_agency' in marketingCompany && 'favicon_url' in marketingCompany.marketing_company
        ? (marketingCompany.marketing_company.favicon_url !== null)
        : false">
    <template slot="action-slot">
      <action-links
        :noMargin="true">
        <Input
          type="file"
          ref="icon"
          :hasLabel="true"
          labelText="add icon"
          inputId="uploadIcon"
          @change="handleIconUpload(marketingCompany.marketing_company, $event)" />
      </action-links>
    </template>
    <template slot="page-heading">
      <div
        class="marketing-logo"
        v-if="marketingCompany !== {} && 'marketing_company' in marketingCompany">
        <action-links
          :noMargin="true">
          <Input
            type="file"
            ref="icon"
            :hasLabel="true"
            labelText="edit"
            inputId="uploadIcon"
            @change="handleIconUpload(marketingCompany.marketing_company, $event)" />
        </action-links>
        <img
          :src="marketingCompany.marketing_company.icon_url"
          :alt="marketingCompany.marketing_company.name">
      </div>
    </template>
    <template slot="page-header">
      <p
        v-if="!loading"
        style="
          margin-top: 0;
          font-size: 25px;
        ">
        Avg Score: {{ avgArcScore }}
      </p>
    </template>
    <Chart
      width="100%"
      height="350px"
      :options="chartOptions"
      v-if="!loading" />
    <Form
      @submit.prevent="filterData">
      <strong>Filters</strong>
      <Input
        placeholder="Url"
        v-model="form.url"
        :disabled="loading"
        :hasLabel="true"
        labelText="Url" />
      <Dropdown
        :options="filteredLocations"
        :isSearch="true"
        dropdownPlaceholder="City"
        ref="filterDropdown1"
        @select="form.city = $event"
        @input="form.city = $event"
        :inputVal="form.city"
        :isDisabled="loading"
        :inputHasLabel="true"
        inputLabelText="City" />
      <Dropdown
        :hasIcon="true"
        :options="formData.practiceAreas"
        dropdownPlaceholder="Practice Area"
        inputId="practiceArea"
        @select="form.practiceArea = $event"
        @input="form.practiceArea = $event"
        :inputVal="form.practiceArea"
        :isDisabled="loading"
        :inputHasLabel="true"
        inputLabelText="Practice Area" />
      <Dropdown
        :options="form.tierOptions"
        :hasIcon="true"
        dropdownPlaceholder="Tier"
        ref="filterDropdown"
        @select="form.tier = $event"
        :isDisabled="loading"
        :inputHasLabel="true"
        inputLabelText="Market Tier" />
      <Dropdown
        dropdownPlaceholder="Results per page"
        :hasIcon="true"
        :options="pagination.dropdownOptions"
        @select="pagination.per_page = $event"
        :inputVal="pagination.per_page"
        :isDisabled="loading"
        :inputHasLabel="true"
        inputLabelText="Results Per Page" />
      <div class="flex">
        <label>Arc Score Range</label>
        <Input
          placeholder="Min"
          type="number"
          v-model="form.arcScoreRange.min"
          :disabled="loading" />
        <Input
          placeholder="Max"
          type="number"
          v-model="form.arcScoreRange.max"
          :disabled="loading" />
      </div>
      <Button
        type="submit"
        text="Apply"
        :isDisabled="loading" />
    </Form>
    <action-links>
      <Toggle
        :hasLabel="true"
        labelText="Show All Results"
        @change="toggleResults($event.target.checked)"
        :isChecked="showAllResults" />
      <Button
        @click="clearFilters"
        text="Clear filters" />
    </action-links>
    <Loading
      v-show="loading"
      text="Loading agency scores" />
    <Table
      :loading="loading"
      v-if="sortedResults.length > 0">
      <template slot="head">
        <t-row>
          <t-heading
            @click="sortBy('number')"
            :class="sortedClass('number')">
            #
          </t-heading>
          <t-heading
            @click="sortBy('website')"
            :class="sortedClass('website')">
            Url
          </t-heading>
          <t-heading
            @click="sortBy('arc_score')"
            :class="sortedClass('arc_score')">
            ARC Score
          </t-heading>
          <t-heading>City</t-heading>
          <t-heading>Practice Area</t-heading>
          <t-heading
            @click="sortBy('market_tier')"
            :class="sortedClass('market_tier')">
            Market Tier
          </t-heading>
          <t-heading
            @click="sortBy('last_checked_at')"
            :class="sortedClass('last_checked_at')">
            Date Last Checked
          </t-heading>
          <t-heading></t-heading>
        </t-row>
      </template>
      <template slot="body">
        <t-row
          v-for="(result, i) in paginatedData.data"
          :key="i">
          <t-cell>{{ result.number }}</t-cell>
          <t-cell>
            <div class="logo-container">
              <img height="24" width="24" :src="result.favicon_url || result.icon_url ||  require('../../assets/img/fallback_fav.png')"/>
               <a
                  :href="result.website"
                  target="_blank"
                  rel="noopener"
                  v-if="result.website">
                  {{ result.website }}
                </a>
                <span v-else>N/A</span>
            </div>
          </t-cell>
          <t-cell>{{ result.arc_score }}</t-cell>
          <t-cell>{{ formatLocation(result.location_name) }}</t-cell>
          <t-cell>{{ capitalizeString(result.practice_area_name) }}</t-cell>
          <t-cell>{{ result.market_tier }}</t-cell>
          <t-cell>{{ result.last_checked_at | moment('M/DD/YYYY') }}</t-cell>
          <t-cell>
            <Button
              :hasLink="true"
              :btnLink="{
                name: 'site-history',
                query: {
                  domain: formatDomain(result.website)
                }
              }"
              :isView="true" />
          </t-cell>
        </t-row>
      </template>
    </Table>
    <Pagination
      v-if="!loading"
      :totalItems="paginatedData.total"
      :perPage="paginatedData.per_page"
      :currentPage="paginatedData.page"
      :nextNum="paginatedData.next_page"
      :prevNum="paginatedData.prev_page"
      :isComputed="true"
      @pageChanged="changePage($event)" />
    <p v-if="!loading && sortedResults.length === 0">
      No results. Try changing or clearing filters.
    </p>
    <div
      id="marketing-company-info"
      class="flex"
      v-if="!loading">
      <div class="left">
        <Notes
          @create:note="createCompanyNote($route.query.id, $event)">
          <Note
            v-for="(note, idx) in sortedNotes"
            :key="idx"
            :author="note.author_name"
            :date="note.created_at"
            :content="note.content"
            @update:content="editCompanyNote(note, $event)"
            @delete:note="deleteCompanyNote(note.id, idx)" />
          <p v-if="sortedNotes.length === 0">No notes found</p>
        </Notes>
      </div>
      <div class="right">
        <Files
          @upload:file="createCompanyFile($route.query.id, $event)">
          <File
            v-for="(file, idx) in sortedFiles"
            :key="idx"
            :title="file.filename.substring(0, 16) + '..'"
            :url="file.download_url"
            :date="file.created_at"
            :type="file.mime_type"
            :id="parseInt(file.id)"
            @download:file="downloadCompanyFile(file.id, file.filename)"
            @delete:file="deleteCompanyFile(file.id, idx)" />
          <p v-if="sortedFiles.length === 0">No files found</p>
        </Files>
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import Table from '@/components/table/Table'
import TableRow from '@/components/table/TableRow'
import TableDataCell from '@/components/table/TableDataCell'
import TableHeading from '@/components/table/TableHeading'
import Input from '@/components/form/Input'
import Dropdown from '@/components/form/Dropdown'
import Button from '@/components/form/Button'
import Form from '@/components/form/Form'
import BaseLayout from '@/views/BaseLayout'
import Loading from '@/components/misc/Loading'
import Pagination from '@/components/misc/Pagination'
import ActionLinks from '@/components/misc/ActionLinks'
import Toggle from '@/components/misc/Toggle'
import Notes from '@/components/global/notes/Notes'
import Note from '@/components/global/notes/Note'
import Files from '@/components/global/files/Files'
import File from '@/components/global/files/File'
import Chart from '@/components/charts/Chart'
import { mapState } from 'vuex'
import _ from 'lodash'
// import { dateFormat } from 'highcharts'
// import axios from 'axios'

export default {
  name: 'ScoreAgency',
  components: {
    Table,
    Input,
    Form,
    Button,
    Notes,
    Note,
    Files,
    File,
    Chart,
    Dropdown,
    Pagination,
    Loading,
    BaseLayout,
    't-row': TableRow,
    't-cell': TableDataCell,
    't-heading': TableHeading,
    'action-links': ActionLinks,
    Toggle
  },
  data () {
    return {
      loading: true,
      form: {
        url: '',
        city: '',
        tierOptions: [1, 2, 3, 4, 5],
        tier: '',
        practiceArea: '',
        arcScoreRange: {
          min: '',
          max: ''
        }
      },
      pagination: {
        per_page: 100,
        currentPage: 1,
        dropdownOptions: [20, 50, 100, 250, 500, 1000]
      },
      marketingCompany: {},
      marketingCompanies: [],
      sort: {
        key: 'arc_score',
        isAsc: true
      },
      showAllResults: false,
      domainsForAvg: [],
      chartOptions: {
        chart: {
          type: 'scatter',
          zoomType: 'xy',
          spacingBottom: 60,
          backgroundColor: '#fbfdff'
        },
        credits: {
          enabled: false
        },
        title: {
          text: 'Arc Scores in each Market Tier by Practice Area'
        },
        subtitle: {
          text: 'Source: https://arc.1point21interactive.com'
        },
        xAxis: {
          title: {
            enabled: true,
            text: 'ARC Score'
          },
          startOnTick: true,
          endOnTick: true,
          showLastLabel: true
        },
        yAxis: {
          title: {
            text: 'Tier Level'
          },
          min: 0,
          max: 5
        },
        legend: {
          align: 'center',
          verticalAlign: 'bottom',
          y: 30,
          floating: true
        },
        plotOptions: {
          series: {
            opacity: 0.75,
            marker: {
              symbol: 'circle'
            },
            turboThreshold: 7000
          },
          scatter: {
            jitter: {
              y: 0.5,
              x: -0.15
            },
            marker: {
              radius: 4,
              states: {
                hover: {
                  enabled: true,
                  lineWidth: 0
                }
              }
            },
            states: {
              hover: {
                marker: {
                  enabled: false
                }
              }
            },
            tooltip: {
              headerFormat: '',
              pointFormat: `
                <strong>
                  {point.name}
                </strong>
                <br>
                <span style="text-transform:capitalize;">
                  {series.name}
                </span>
                <br>
                Score: {point.x}, Tier: {point.y}
              `
            }
          }
        },
        series: []
      }
    }
  },
  computed: {
    ...mapState({
      formData: state => state.form
    }),

    filteredLocations () {
      return this.searchLocationList(this.form.city, this.formData.usLocations).slice(0, 100)
    },

    sortedNotes () {
      return this.marketingCompany !== {} && 'notes' in this.marketingCompany
        ? this.marketingCompany.notes.slice().sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        : []
    },

    sortedFiles () {
      return this.marketingCompany !== {} && 'files' in this.marketingCompany
        ? this.marketingCompany.files.slice().sort((a, b) => new Date(b.created_at - new Date(a.created_at)))
        : []
    },

    sortedResults () {
      const list = this.domainsForAvg !== []
        ? this.domainsForAvg
        : []
      if (this.sort.key) {
        list.sort((a, b) => {
          a = a[this.sort.key]
          b = b[this.sort.key]
          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
        })
      }

      return list.filter(domain => !this.isBlacklisted(domain.website))
    },

    paginatedData () {
      return this.sortedResults.length > 0
        ? this.paginateData(
          this.sortedResults.length > 0 ? this.sortedResults : [],
          this.pagination.currentPage,
          this.pagination.per_page
        )
        : []
    },

    // domains () {
    //   return this.getAllDomains()
    // },

    avgArcScore () {
      const arcScoreList = []
      if (this.domainsForAvg.length > 0) {
        this.domainsForAvg.forEach(domain => {
          arcScoreList.push(domain.arc_score)
        })
      }
      return arcScoreList.length > 0 ? (arcScoreList.reduce((a, b) => a + b) / arcScoreList.length).toFixed(2) : null
    }

  },
  watch: {
    domainsForAvg: {
      handler: function () {
        this.chartOptions.series = this.getChartData()
      }
    }
  },
  created () {
    this.getCompanyData()
    // this.getCompanyAverage()
    // this.getAllDomains()
  },
  methods: {
    getRandomColor (seedString) {
      let hash = 0
      for (let i = 0; i < seedString.length; i++) {
        hash = seedString.charCodeAt(i) + ((hash << 5) - hash)
      }

      let color = '#'
      for (let j = 0; j < 3; j++) {
        const value = ((hash >> (j * 8)) & 0xFF) % 128 + 127
        color += ('00' + value.toString(16)).substr(-2)
      }

      return color
    },
    getChartData () {
      const groupedByPa = _.groupBy(this.domainsForAvg, 'practice_area_name')
      const data = Object.entries(groupedByPa).map(item => {
        const obj = {}
        switch (item[0]) {
          case 'personal injury':
            obj.color = '#f20b47'
            break
          case 'family law':
            obj.color = '#06d1b9'
            break
          case 'criminal law':
            obj.color = '#ab1aee'
            break
          case 'employment law':
            obj.color = '#2c6cfd'
            break
          default:
            obj.color = this.getRandomColor(item[0])
        }
        obj.name = item[0]
        obj.data = item[1].map(res => {
          return {
            x: res.arc_score,
            y: res.market_tier,
            name: res.website
          }
        })
        return obj
      })
      const cleanedData = data.map(lawArea => {
        const uniqueEntries = {}
        const counts = {}

        lawArea.data.forEach(entry => {
          const key = `${entry.name}-${entry.y}`
          if (uniqueEntries[key]) {
            counts[key]++
            uniqueEntries[key].x = ((uniqueEntries[key].x * (counts[key] - 1)) + entry.x) / counts[key]
            uniqueEntries[key].x = Number(uniqueEntries[key].x.toFixed(2))
          } else {
            uniqueEntries[key] = { ...entry }
            counts[key] = 1
          }
        })

        return {
          color: lawArea.color,
          name: lawArea.name,
          data: Object.values(uniqueEntries)
        }
      })
      return cleanedData
    },
    editCompanyNote (note, content) {
      this.$arc.patch(`notes/${note.id}`, {
        content: content
      })
        .then(res => {
          this.$set(note, 'content', res.data.content)
        })
        .catch(err => {
          console.error(err)
        })
    },

    deleteCompanyNote (id, i) {
      this.$arc.delete(`notes/${id}`)
        .then(res => {
          this.marketingCompany.notes.splice(i, 1)
        })
        .catch(err => {
          console.error(err)
        })
    },

    filterData () {
      this.loading = true

      let domains = _.cloneDeep(this.marketingCompany.domains)

      if (this.form.url) {
        const url = this.form.url.trim().toLowerCase()
        domains = domains.filter(domain => domain.website.toLowerCase().includes(url))
      }

      if (this.form.city) {
        const city = this.form.city.split(', ')[0].toLowerCase()
        domains = domains.filter(domain => domain.location_name.toLowerCase().includes(city))
      }

      if (this.form.tier) {
        domains = domains.filter(domain => domain.market_tier === this.form.tier)
      }

      if (this.form.practiceArea) {
        domains = domains.filter(domain => domain.practice_area_name.toLowerCase().includes(this.form.practiceArea.toLowerCase()))
      }

      if (this.form.arcScoreRange.min) {
        domains = domains.filter(domain => domain.arc_score >= parseInt(this.form.arcScoreRange.min))
      }

      if (this.form.arcScoreRange.max) {
        domains = domains.filter(domain => domain.arc_score <= parseInt(this.form.arcScoreRange.max))
      }

      this.$set(this, 'domainsForAvg', domains)

      this.getArcRank()
      this.loading = false
    },

    createCompanyNote (id, content) {
      this.$arc.post(`marketing_companies/${id}/notes`, {
        content: content
      }).then(res => {
        this.marketingCompany.notes.push(res.data)
      }).catch(err => {
        console.error(err)
      })
    },

    createCompanyFile (id, file) {
      const formData = new FormData()
      formData.append('file', file)
      this.$arc.post(`marketing_companies/${id}/files`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        this.marketingCompany.files.push(res.data)
      }).catch(err => {
        console.error(err)
        alert('Unsupported file type or file is too large, pleasy try again.')
      })
    },

    downloadCompanyFile (id, filename) {
      const split = filename.split('.')
      const splitFilename = split[0]
      const splitExt = split[1]
      const newFilename = splitFilename.substring(0, 15) + '.' + splitExt
      this.$arc.get(`files/${id}`, {
        responseType: 'blob'
      })
        .then(res => {
          const url = URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', newFilename)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
        .catch(err => {
          console.error(err)
        })
    },

    deleteCompanyFile (id, i) {
      this.$arc.delete(`files/${id}`)
        .then(res => {
          this.marketingCompany.files.splice(i, 1)
        })
        .catch(err => {
          console.error(err)
        })
    },

    log (e) {
      console.log(e)
    },

    getArcRank () {
      if (this.sort.key === 'arc_score' && this.sort.isAsc) {
        this.sortedResults.forEach((result, i) => {
          result.number = i + 1
        })
      }
      this.loading = false
    },

    async getCompanyData () {
      this.domainsForAvg = await this.getAllDomains()
      this.getArcRank()

      if (this.marketingCompany !== {} && 'marketing_company' in this.marketingCompany) {
        await this.$arc.get('marketing_companies', {
          params: {
            name_like: this.marketingCompany.marketing_company.name
          }
        })
      }
    },

    async getDomains (id, page, perPage) {
      return await this.$arc.get(`marketing_companies/${id}`, {
        params: {
          ...(page ? { page: page } : {}),
          ...(perPage ? { per_page: perPage } : {}),
          ...(this.showAllResults ? { agg: 'location' } : {})
        }
      })
    },

    async getAllDomains () {
      const { data } = await this.getDomains(this.$route.query.id, 1, 500)

      // eslint-disable-next-line camelcase
      const { files, marketing_company, notes } = data

      this.marketingCompany = {
        files,
        marketing_company,
        notes
      }

      const promises = [Promise.resolve({ data })]

      if (data.next_num !== null) {
        let currentPage = data.next_num
        const totalPages = Math.ceil(data.total / data.per_page)

        for (currentPage; currentPage <= totalPages; currentPage++) {
          promises.push(this.getDomains(this.$route.query.id, currentPage, 500))
        }
      }

      const asyncResults = await Promise.all(promises)
      const allDomains = asyncResults.map(obj => obj.data.domains).flat()
      this.marketingCompany.domains = allDomains
      return allDomains
    },

    randomNumber () {
      return (Math.random() * (10 - 1) + 1).toFixed(2)
    },

    sortedClass (key) {
      return this.sort.key === key
        ? `sorted ${this.sort.isAsc ? 'asc' : 'desc'}`
        : ''
    },

    sortBy (key) {
      this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false
      this.sort.key = key
    },

    changePage (e) {
      this.pagination.currentPage = e
      console.log(e)
    },

    paginateData (items, currentPage, perPageItems) {
      const page = currentPage || 1
      const perPage = perPageItems || 25
      const offset = (page - 1) * perPage
      const paginatedItems = items.slice(offset).slice(0, perPageItems)
      const totalPages = Math.ceil(items.length / perPage)

      return {
        page: page,
        per_page: perPage,
        prev_page: page - 1 ? page - 1 : null,
        next_page: (totalPages > page) ? page + 1 : null,
        total: items.length,
        total_pages: totalPages,
        data: paginatedItems
      }
    },

    handleIconUpload (obj, evt) {
      const formData = new FormData()
      formData.append('file', evt.target.files[0])
      this.$arc.put(`marketing_companies/${obj.id}/icon`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        this.$set(obj, 'icon_url', res.data.icon_url)
        this.$forceUpdate()
      }).catch(err => {
        console.error(err)
      })
    },

    formatDomain (domain) {
      return domain.toString().includes('https://www.')
        ? domain.toString().replace('https://www.', '')
        : domain.toString().includes('http://')
          ? domain.toString().replace('http://', '')
          : domain.toString().replace('https://', '')
    },

    clearFilters () {
      this.$refs.filterDropdown.selected = ''
      this.form.url = ''
      this.form.city = ''
      this.form.tier = ''
      this.form.practiceArea = ''
      this.form.arcScoreRange.min = ''
      this.form.arcScoreRange.max = ''
      this.domainsForAvg = this.marketingCompany.domains
    },

    toggleResults (e) {
      this.showAllResults = e
      this.pagination.page = 1
      this.getCompanyData()
      // this.getCompanyAverage(this.$route.query.id)
    }
  }
}
</script>

<style lang="scss">
.agency {
  .container {
    form {
      align-items: flex-end;
      margin-bottom: 30px;
      justify-content: space-between;
      > div {
        flex-basis: calc((100% / 3) - 30px);
        &.flex {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          label {
            font-weight: 600;
            margin-bottom: 10px;
            display: block;
            width: 100%;
          }
          .input-container {
            flex-basis: calc((100% / 2) - 5px);
          }
        }
      }
      strong {
        margin-right: 15px;
        margin-bottom: 1rem;
      }
    }
    .action-links {
      justify-content: space-between;
    }
    .page-heading, .marketing-logo {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
      label {
        color: #c0c1c2;
        font-size: 12px;
        transition: all .3s ease-in-out;
        &:hover {
          color: #3b80e8;
        }
      }
      img {
        margin-bottom: 0;
        margin-top: 5px;
      }
      .input-container.is-file-input {
        width: 100px;
        label {
          text-align: left;
        }
      }
    }
    .page-header {
      align-items: flex-end;
    }
    #marketing-company-info {
      display: flex;
      justify-content: space-between;
      margin-top: 60px;
      .left {
        max-width: 800px;
        width: 100%;
        margin-right: 20px;
        margin-right: 40px;
      }
      .right {
        flex-grow: 1;
        max-width: calc(100% - 800px);
      }
    }
    .logo-container {

      img {
        margin: 0 1rem 0 0;
        vertical-align: bottom;
        display: inline-block;
      }
      a {
        word-break: break-all;
      }
    }
  }
}
</style>
