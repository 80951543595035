<template>
  <form
    @submit="$emit('submit', $event)"
    :class="{
      'has-label': hasLabel,
      'is-grid': isGrid
    }"
    :style="[isGrid ? gridStyles : {}]">
    <span class="form-label" v-if="hasLabel">
      {{ labelText }}
    </span>
    <slot></slot>
  </form>
</template>

<script>
export default {
  name: 'Form',
  props: {
    hasLabel: {
      type: Boolean,
      default: false
    },
    labelText: {
      type: String,
      default: ''
    },
    isGrid: {
      type: Boolean,
      default: false
    },
    columnCount: {
      type: Number,
      default: 2
    },
    rowGap: {
      type: Number,
      default: 0
    }
  },
  computed: {
    gridStyles () {
      return {
        display: 'grid',
        gridTemplateColumns: `repeat(${this.columnCount}, 1fr)`,
        rowGap: `${this.rowGap}px`
      }
    }
  }
}
</script>

<style lang="scss">
form {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  position: relative;
  > div {
    margin-right: 10px;
  }
  &.has-label {
    flex-wrap: wrap;
    &:not(:first-of-type) {
      margin-top: 50px;
    }
    .form-label {
      display: block;
      width: 100%;
      font-weight: 600;
      font-size: 18px;
      line-height: 1;
      margin-bottom: 30px;
    }
  }
}
</style>
