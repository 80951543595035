<template>
  <button
    class="button"
    :class="[
      btnType ? `is-${btnType}` : '',
      {
        'is-disabled': isDisabled,
        'is-inverse': isInversed,
        'is-bordered': isBordered,
        'is-rounded': isRounded,
        'is-small': isSmall,
        'is-plain': isPlain,
        'is-light': isLight,
        'left-aligned': leftAligned,
        'is-view': isView,
        'has-icon': hasIcon,
        'has-icon-only': hasIconOnly,
        'is-uppercase': isUppercase,
        'no-background': noBackground,
        'is-icon-reversed': iconReversed
      }
    ]"
    :type="type"
    :disabled="isDisabled"
    @click="$emit('click', $event)">
    <router-link
      :to="btnLink"
      v-if="hasLink"
      target="_blank"
      rel="noopener">
      {{ btnLinkText }}
    </router-link>
    <span v-else-if="!hasLink && !isView && !hasIconOnly">{{ text }}</span>
    <font-awesome-icon
      v-if="hasIcon"
      :icon="icon" />
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    text: String,
    type: String,
    isDisabled: {
      type: Boolean,
      default: false
    },
    iconReversed: {
      type: Boolean,
      default: false
    },
    noBackground: {
      type: Boolean,
      default: false
    },
    leftAligned: {
      type: Boolean,
      default: false
    },
    isLight: {
      type: Boolean,
      default: false
    },
    isInversed: {
      type: Boolean,
      default: false
    },
    isRounded: {
      type: Boolean,
      default: false
    },
    isPlain: {
      type: Boolean,
      default: false
    },
    isSmall: {
      type: Boolean,
      default: false
    },
    isBordered: {
      type: Boolean,
      default: false
    },
    isUppercase: {
      type: Boolean,
      default: false
    },
    hasIcon: {
      type: Boolean,
      default: false
    },
    hasIconOnly: {
      type: Boolean,
      default: false
    },
    icon: String,
    hasLink: {
      type: Boolean,
      default: false
    },
    btnType: String,
    btnLink: [Object, String],
    btnLinkText: String,
    isView: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.button {
  background: #212741;
  border: none;
  padding: 1.15rem 1.75rem;
  font-size: 16px;
  color: #fff;
  border-radius: 6px;
  max-height: 53px;
  cursor: pointer;
  transition: all .3s ease-in-out;
  display: flex;
  align-items: center;
  &.is {
    &-plain {
      background: none;
      padding: 0;
      color: #0158E3;
      font-weight: 700;
      text-decoration: underline;
    }
    &-uppercase {
      text-transform: uppercase;
    }
    &-rounded {
      border-radius: 100%;
      padding: 1.15rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-primary {
      background: #0158E3;
      color: #fff;
      &:hover {
        background: darken(#0158E3, 5%)
      }
    }
    &-inverse {
      background: none;
      color: #212741;
      &.is-bordered {
        border: 1px solid #e0e9ef;
      }
      &:hover {
        background: #e0e9ef;
      }
    }
    &-danger {
      background: red;
      color: #fff;
      &:hover {
        background: darken(red, 5%);
      }
    }
    &-small {
      padding: 0;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 18px;
      height: 18px;
    }
    &-purple {
      background: #9f7cf3;
      color: #fff;
      font-size: 11px;
      svg {
        path {
          fill: #fff;
        }
      }
      &:hover {
        background: darken(#9f7cf3, 5%);
      }
    }
    &-blue {
      background: #0158E3;
      color: #fff;
      font-size: 9px;
      svg {
        path {
          fill: #fff;
        }
      }
      &:hover {
        background: darken(#0158E3, 5%);
      }
    }
    &-light {
      background: #def1fd;
      font-weight: 700;
      color: #0158E3;
      padding: .5rem 1rem;
      border-radius: 5px;
      font-size: .875rem;
    }
    &-view {
      padding: 0;
      background: none;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      &.left-aligned {
        margin-right: auto;
        margin-left: 0;
      }
      a {
        height: 18px;
        width: 18px;
        background: #0158E314;
        border: 1px solid #0158E314;
        border-radius: 100%;
        &:before {
          content: '';
          border-style: solid;
          border-width: 0.15em 0.15em 0 0;
          display: inline-block;
          height: 0.35em;
          position: relative;
          width: 0.35em;
          transform: rotate(45deg);
          margin-left: -1px;
          margin-bottom: 4px;
          color: #2c3e50;
        }
      }
    }
    &-fancy {
      border-radius: 25px;
      background: linear-gradient(to bottom, #0158E3, #012C72);
      font-family: 'Open Sans', sans-serif;
      font-size: 15px;
      font-weight: 700;
      line-height: 1;
      padding: 17px 32px 19px;
      min-width: 104px;
      letter-spacing: .075em;
      text-transform: uppercase;
      display: inline-flex;
      justify-content: center;
      position: relative;
      z-index: 1;
      &:hover {
        &:before {
          opacity: 1;
        }
      }
      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background: #012C72;
        opacity: 0;
        z-index: -1;
        border-radius: inherit;
        transition: all .3s ease-in-out;
      }
    }
  }
  &.has-icon {
    &-only {
      span {
        margin-right: 0 !important;
      }

      &.is-small {
        width: 22px;
        height: 22px;
        font-size: 12px;
        border-radius: 4px;
      }

      &.is-danger {
        svg {
          path {
            fill: #fff;
          }
        }
      }
    }
    span {
      margin-right: 10px;
    }

    &.is-icon-reversed {
      flex-direction: row-reverse;
      gap: 10px;

      span {
        margin: 0;
      }
    }
  }
  &[disabled=disabled] {
    opacity: .3;
    cursor: not-allowed;
  }

  &.no-background {
    background: none;
  }
}

@media screen and (max-width: 767px) {
  .button {
    &.is {
      &-fancy {
        font-size: 12px;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1170px) {
  .button {
    &.is {
      &-fancy {
        font-size: 14px;
      }
    }
  }
}
</style>
