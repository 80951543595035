<template>
  <div class="page-content">
    <Loading
      v-show="loading"
      text="Loading overview" />
    <settings-panel
      :actionLinkMethod="toggleFormModal">
      <template slot="modal">
        <div
          class="form-modal"
          :class="{ show: formModalActive }">
          <div class="form-modal-wrapper">
            <h3>Add New Market Tier Override</h3>
            <Form
              @submit.prevent="addNewMarketTierOverride">
              <div class="field-group">
                <Dropdown
                  :options="filteredLocations"
                  :isSearch="true"
                  dropdownPlaceholder="Start typing location"
                  :inputHasLabel="true"
                  inputId="city"
                  inputLabelText="Select city"
                  @select="form.cityAndState = $event"
                  @input="debounceLocationName($event)"
                  :inputVal="form.cityAndState"
                  :inputRequired="true" />
                <!-- <Input
                  type="text"
                  placeholder="City, State"
                  :hasLabel="true"
                  labelText="City and State"
                  v-model="form.cityAndState" /> -->
                <Input
                  type="number"
                  :hasLabel="true"
                  labelText="Tier"
                  placeholder="Tier"
                  v-model="form.tier" />
                <ButtonGroup
                  btnAlign="right"
                  style="width:100%;">
                  <Button
                    text="Cancel"
                    :isInversed="true"
                    :isBordered="true"
                    @click.prevent="toggleFormModal" />
                  <Button
                    type="submit"
                    text="Submit"
                    :isDisabled="loading"
                    btnType="primary" />
                </ButtonGroup>
              </div>
            </Form>
          </div>
        </div>
      </template>
      <Table v-if="marketTierOverrides.length > 0">
        <template slot="head">
          <t-row>
            <!-- <t-heading>ID</t-heading> -->
            <t-heading
              :hasTip="true"
              thTip="(Double click to edit)">
              City/State
            </t-heading>
            <t-heading
              :hasTip="true"
              thTip="(Double click to edit)">
              Tier
            </t-heading>
            <t-heading></t-heading>
          </t-row>
        </template>
        <template slot="body">
          <t-row
            v-for="(market, idx) in marketTierOverrides"
            :key="idx">
            <!-- <t-cell>{{ market.id }}</t-cell> -->
            <t-cell
              :isEditable="true"
              :isInput="true"
              :defaultValue="market.city_and_state"
              @editInput="form.cityAndState = $event"
              @doneEditing="updateMarketTierOverride(idx, {
                id: market.id,
                cityAndState: form.cityAndState
              })">
              {{ market.city_and_state }}
            </t-cell>
            <t-cell
              :isEditable="true"
              :isInput="true"
              :defaultValue="market.tier"
              @editInput="form.tier = $event"
              @doneEditing="updateMarketTierOverride(idx, {
                id: market.id,
                tier: form.tier
              })">
              {{ market.tier }}
            </t-cell>
            <t-cell>
              <div class="table-action">
                <span
                  class="table-action-icon"
                  @click.prevent="toggleTableAction($event)">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
                <ul>
                  <li @click="deleteMarketTierOverride(idx)">
                    Delete
                  </li>
                </ul>
              </div>
            </t-cell>
          </t-row>
        </template>
      </Table>
      <p v-if="marketTierOverrides.length === 0">
        No results found. Please try again.
      </p>
      <alert ref="alert"></alert>
    </settings-panel>
  </div>
</template>

<script>
import Loading from '@/components/misc/Loading'
import SettingsPanel from '@/components/global/user/SettingsPanel'
import Table from '@/components/table/Table'
import TableDataCell from '@/components/table/TableDataCell'
import TableRow from '@/components/table/TableRow'
import TableHeading from '@/components/table/TableHeading'
import Button from '@/components/form/Button'
import ButtonGroup from '@/components/form/ButtonGroup'
import Form from '@/components/form/Form'
import Input from '@/components/form/Input'
import Dropdown from '@/components/form/Dropdown'
import _ from 'lodash'

export default {
  name: 'UserMarketTiers',
  components: {
    Loading,
    SettingsPanel,
    Table,
    't-row': TableRow,
    't-cell': TableDataCell,
    't-heading': TableHeading,
    Button,
    ButtonGroup,
    Form,
    Input,
    Dropdown
  },
  data () {
    return {
      loading: false,
      marketTierOverrides: [],
      formModalActive: false,
      form: {
        cityAndState: '',
        tier: ''
      },
      filteredLocations: []
    }
  },
  mounted () {
    this.getMarketTierOverrides()
  },
  methods: {
    getLocations (name) {
      this.filteredLocations = this.searchUsLocationList(name).map(location => {
        return location.replace(/,/g, ', ').replace(/, United States/g, '')
      })
    },

    debounceLocationName: _.debounce(function (val) {
      if (val.length > 2) {
        const cleanedVal = val.replace(/, /g, ',')
        this.getLocations(cleanedVal)
      } else {
        this.filteredLocations = []
      }
    }, 200),

    async getMarketTierOverrides () {
      this.loading = true
      try {
        const { data } = await this.$arc.get('market_tier_overrides')
        this.marketTierOverrides = data?.market_tier_overrides
        this.loading = false
      } catch (error) {
        console.error(error)
        this.loading = false
      }
    },

    async addNewMarketTierOverride () {
      this.loading = true
      try {
        await this.$arc.post('market_tier_overrides', {
          city_and_state: this.form.cityAndState.split(',').map(str => str.trim()).join(','),
          tier: this.form.tier
        })
        this.getMarketTierOverrides()
        this.toggleFormModal()
        this.$refs.alert.showAlert('Successfully added new market tier override', 'success')
      } catch (error) {
        const errorRes = error.response?.data
        if (errorRes.validation_error.body_params) {
          errorRes.validation_error.body_params.forEach(err => {
            this.$refs.alert.showAlert(err.msg, 'error')
          })
        } else {
          this.$refs.alert.showAlert('Error adding new market tier override', 'error')
        }
        this.loading = false
      }
    },

    async updateMarketTierOverride (idx, opts) {
      this.loading = true
      try {
        const { data } = await this.$arc.patch(`market_tier_overrides/${opts.id}`, {
          ...(opts.cityAndState && { city_and_state: opts.cityAndState }),
          ...(opts.tier && { tier: opts.tier })
        })
        this.$set(this.marketTierOverrides[idx], 'city_and_state', data?.city_and_state)
        this.$set(this.marketTierOverrides[idx], 'tier', data?.tier)
        this.$forceUpdate()
        this.$refs.alert.showAlert('Successfully updated the market tier override', 'success')
        this.loading = false
      } catch (error) {
        const errorRes = error.response?.data
        if (errorRes.validation_error.body_params) {
          errorRes.validation_error.body_params.forEach(err => {
            this.$refs.alert.showAlert(err.msg, 'error')
          })
        } else {
          this.$refs.alert.showAlert('Error updating the market tier override', 'error')
        }
        this.loading = false
      }
    },

    toggleFormModal () {
      this.formModalActive = !this.formModalActive
      if (!this.formModalActive) {
        this.form = {
          cityAndState: '',
          tier: ''
        }
        this.debounceLocationName('')
      }
    },

    toggleTableAction (evt) {
      const icon = evt.target.closest('.table-action-icon')
      const ul = icon.nextElementSibling
      ul.classList.toggle('show')
    },

    async deleteMarketTierOverride (idx) {
      if (confirm('Are you sure you want to delete this market tier override?')) {
        const marketTierOverrideToDelete = this.marketTierOverrides[idx]

        if (marketTierOverrideToDelete.id) {
          try {
            await this.$arc.delete(`market_tier_overrides/${marketTierOverrideToDelete.id}`)
            this.marketTierOverrides.splice(idx, 1)
            this.$forceUpdate()
            this.$refs.alert.showAlert('Successfully deleted market tier override', 'success')
          } catch (error) {
            this.$refs.alert.showAlert('Error deleting market tier override', 'error')
            console.error(error)
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
section {
  &.user-profile {
    .container {
      .form-modal {
        &-wrapper {
          max-width: 500px;
        }
      }
    }
  }
}
</style>
