<template>
  <div class="tabs">
    <ul class="tabs-header">
      <li
        v-for="(tab, index) in tabs"
        :key="tab.title"
        @click="selectTab(index)"
        class="tab-heading"
        :class="{
          'selected': (index === selectedIndex)
        }
        ">
        {{ tab.title }}
      </li>
    </ul>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Tabs',
  data () {
    return {
      selectedIndex: 0,
      tabs: []
    }
  },
  created () {
    this.tabs = this.$children
  },
  mounted () {
    this.selectTab(0)
  },
  methods: {
    selectTab (i) {
      this.selectedIndex = i
      this.tabs.forEach((tab, index) => {
        tab.isActive = (index === i)
      })
    }
  }
}
</script>

<style lang="scss">
.tabs-header {
  display: flex;
  border-bottom: 5px solid #212741;
  .tab {
    &-heading {
      // background: #7C90F8;
      color: #212741;
      padding: 20px 40px;
      border-radius: 6px 6px 0 0;
      margin-right: 5px;
      font-size: 18px;
    }
  }
}
</style>
