<template>
  <div class="page-content">
    <Loading
      v-show="loading"
      text="Loading overview" />
    <settings-panel
      :actionLinkMethod="toggleFormModal">
      <template slot="modal">
        <div
          class="form-modal"
          :class="{ show: formModalActive }">
          <div class="form-modal-wrapper">
            <h3>{{ form.mode === 'edit' ? 'Update Practice Area' : 'Add New Practice Area' }}</h3>
            <Form
              ref="practiceAreaForm"
              v-if="!loadedPracticeAreas">
              <div class="field-group">
                <Input
                  type="text"
                  :hasLabel="true"
                  labelText="Practice Area Name"
                  placeholder="practice area"
                  v-model="form.practiceAreaName" />
                <Table>
                  <template slot="head">
                    <t-row>
                      <t-heading>Keyword Name</t-heading>
                      <t-heading>Keyword Weight</t-heading>
                      <t-heading :noPadding="true"></t-heading>
                    </t-row>
                  </template>
                  <template slot="body">
                    <t-row
                      v-for="(keyword, idx) in form.keywords"
                      :key="idx">
                      <t-cell>
                        {{ keyword.name }}
                      </t-cell>
                      <t-cell>
                        {{ keyword.weight }}
                      </t-cell>
                      <t-cell :noPadding="true">
                        <Button
                          :isInversed="true"
                          :isBordered="true"
                          :hasIcon="true"
                          :hasIconOnly="true"
                          :isRounded="true"
                          icon="minus"
                          @click.prevent="removeKeyword(idx)" />
                      </t-cell>
                    </t-row>
                    <t-row>
                      <t-cell>
                        <Input
                          type="text"
                          placeholder="name"
                          :required="true"
                          id="keywordName"
                          v-model="keywordsToAdd.name" />
                      </t-cell>
                      <t-cell>
                        <Input
                          type="number"
                          placeholder="(defaults to 1 if not set)"
                          style="flex-basis:65%;"
                          id="keywordWeight"
                          v-model="keywordsToAdd.weight" />
                      </t-cell>
                      <t-cell
                        :noPadding="true">
                        <Button
                          :isInversed="true"
                          :isBordered="true"
                          :hasIcon="true"
                          :hasIconOnly="true"
                          :isRounded="true"
                          icon="plus"
                          @click.prevent="addKeyword" />
                      </t-cell>
                    </t-row>
                  </template>
                </Table>
              </div>
            </Form>
            <div
              class="form-file-upload"
              v-if="form.keywords.length === 0 && !loadedPracticeAreas">
              <Form style="margin-top:20px;">
                <Input
                  type="file"
                  :hasLabel="true"
                  labelText="Upload csv"
                  inputId="uploadCsv"
                  @change="uploadPaCsv($event)"
                  style="flex-basis:95px;width:auto;" />
                <a
                  style="font-size:13px;text-decoration:underline;cursor:pointer;color:inherit;"
                  href="/pa_example.csv"
                  download="pa_example.csv">
                  (Download Example)
                </a>
              </Form>
            </div>
            <Table
              v-if="loadedPracticeAreas">
              <template slot="head">
                <t-row>
                  <t-heading>Practice Area Name</t-heading>
                  <t-heading>Keywords</t-heading>
                </t-row>
              </template>
              <template slot="body">
                <t-row
                  v-for="(pa, idx) in loadedPracticeAreas"
                  :key="idx">
                  <t-cell>{{ pa.name }}</t-cell>
                  <t-cell>
                    <ul style="max-height: 70px;overflow-y:auto;">
                      <li
                        v-for="(keyword, idx) in pa.keywords"
                        :key="idx">
                        <span>{{ keyword.name }}, {{ keyword.weight }}</span>
                      </li>
                    </ul>
                  </t-cell>
                </t-row>
              </template>
            </Table>
            <button-group
              class="form-buttons"
              btnAlign="right">
              <Button
                text="Cancel"
                :isInversed="true"
                :isBordered="true"
                @click="toggleFormModal" />
              <Button
                text="Submit"
                btnType="primary"
                @click="submitPracticeAreaForm" />
            </button-group>
          </div>
        </div>
      </template>
      <Table
        :loading="loading"
        v-if="practiceAreas.length > 0">
        <template slot="head">
          <t-row>
            <t-heading
              :hasTip="true"
              thTip="(Double-Click Name To Edit)">
              Name
            </t-heading>
            <t-heading>Divisor</t-heading>
            <t-heading>Date Created</t-heading>
            <t-heading>Keywords</t-heading>
            <t-heading></t-heading>
          </t-row>
        </template>
        <template slot="body">
          <t-row
            v-for="(practiceArea, idx) in practiceAreas"
            :key="idx">
            <t-cell
              :isEditable="true"
              :isInput="true"
              :defaultValue="practiceArea.name"
              @editInput="updatePracticeArea(practiceArea, $event)"
              @doneEditing="updatePracticeAreaName(practiceArea, idx)">
              {{ practiceArea.name }}
            </t-cell>
            <t-cell>{{ practiceArea.divisor_magic_num }}</t-cell>
            <t-cell>{{ practiceArea.created_at | moment('MM/DD/YYYY') }}</t-cell>
            <t-cell>
              <a
                @click.prevent="showTableModal(idx)"
                style="cursor:pointer;">
                View
              </a>
            </t-cell>
            <t-cell>
              <div class="table-action">
                <span
                  class="table-action-icon"
                  @click.prevent="toggleTableAction($event)">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
                <ul>
                  <li @click.prevent="togglePracticeAreaEditForm(idx)">Edit</li>
                  <li @click.prevent="deletePracticeArea(idx)">
                    Delete
                  </li>
                </ul>
              </div>
            </t-cell>
          </t-row>
        </template>
      </Table>
      <p v-else-if="!loading && practiceAreas.length === 0">
        No results. Please try again.
      </p>
      <div class="table-modal" style="display: none;" ref="table-modal">
        <div class="table-modal-wrapper">
          <div class="table-modal-header">
            <Button
              :hasIcon="true"
              :hasIconOnly="true"
              icon="times"
              type="button"
              :isSmall="true"
              :noBackground="true"
              @click="closeTableModal" />
          </div>
          <div class="table-modal-content">
            <Table>
              <template slot="head">
                <t-row>
                  <t-heading
                    :hasTip="true"
                    thTip="(Double-Click Name to Edit)">
                    Keyword Name
                  </t-heading>
                  <t-heading
                    :hasTip="true"
                    thTip="(Double-Click Weight to Edit)">
                    Keyword Weight
                  </t-heading>
                </t-row>
              </template>
              <template slot="body">
                <t-row
                  v-for="(keyword, idx) in selectedKeywords"
                  :key="idx">
                  <t-cell
                    :isEditable="true"
                    :isInput="true"
                    :defaultValue="keyword.name"
                    @editInput="updateKeywordName(idx, $event)"
                    @doneEditing="updateKeywordList(keyword, $event)">
                    {{ keyword.name }}
                  </t-cell>
                  <t-cell
                    :isEditable="true"
                    :isInput="true"
                    :defaultValue="keyword.weight"
                    @editInput="updateKeywordWeight(idx, $event)"
                    @doneEditing="updateKeywordList(keyword, $event)">
                    {{ keyword.weight }}
                  </t-cell>
                </t-row>
              </template>
            </Table>
          </div>
        </div>
      </div>
      <alert ref="alert"></alert>
    </settings-panel>
  </div>
</template>

<script>
import Loading from '@/components/misc/Loading'
import SettingsPanel from '@/components/global/user/SettingsPanel'
import Table from '@/components/table/Table'
import TableDataCell from '@/components/table/TableDataCell'
import TableRow from '@/components/table/TableRow'
import TableHeading from '@/components/table/TableHeading'
import Button from '@/components/form/Button'
import ButtonGroup from '@/components/form/ButtonGroup'
import Form from '@/components/form/Form'
import Input from '@/components/form/Input'
const csv = require('csvtojson')

export default {
  name: 'UserProfile',
  components: {
    Loading,
    SettingsPanel,
    Table,
    't-row': TableRow,
    't-cell': TableDataCell,
    't-heading': TableHeading,
    Button,
    ButtonGroup,
    Form,
    Input
  },
  data () {
    return {
      loading: false,
      practiceAreas: [],
      selectedKeywords: [],
      formModalActive: false,
      form: {
        practiceAreaName: '',
        keywords: [],
        mode: 'add'
      },
      keywordsToAdd: {
        name: '',
        weight: ''
      },
      currentPracticeArea: null,
      loadedPracticeAreas: null,
      tableActionShow: false
    }
  },
  mounted () {
    this.getPracticeAreas()

    window.addEventListener('keydown', (evt) => {
      if (evt.key === 'Enter') {
        evt.preventDefault()
        if (evt.repeat) return

        const fieldId = evt.target.parentElement.id

        if (fieldId === 'keywordName' || fieldId === 'keywordWeight') {
          this.addKeyword()
        }
      }
    })
  },
  computed: {
    keywords () {
      return this.keywordsToAdd
    }
  },
  methods: {
    async getPracticeAreas () {
      this.loading = true
      try {
        const { data } = await this.$arc.get('practice_areas')
        this.practiceAreas = data?.practice_areas
        this.loading = false
      } catch (error) {
        alert('An error occurred while fetching practice areas')
        console.error(error)
        this.loading = false
      }
    },

    log (evt) {
      console.log(evt)
    },

    addKeyword () {
      if (!this.keywordsToAdd.name) return
      if (!this.keywordsToAdd.weight) this.keywordsToAdd.weight = 1

      this.form.keywords.push({
        name: this.keywordsToAdd.name,
        weight: parseInt(this.keywordsToAdd.weight)
      })
      this.keywordsToAdd = {
        name: '',
        weight: ''
      }
    },

    removeKeyword (idx) {
      this.form.keywords.splice(idx, 1)
    },

    showTableModal (idx) {
      Object.entries(this.practiceAreas[idx].keywords).map(([key, value]) => {
        this.selectedKeywords.push({
          name: key,
          weight: value
        })
      })
      this.currentPracticeArea = this.practiceAreas[idx]
      this.$refs['table-modal'].style.display = 'flex'
    },

    updatePracticeArea (practiceArea, evt) {
      practiceArea.name = evt
    },

    async updatePracticeAreaName (practiceArea, idx) {
      try {
        const res = await this.$arc.patch(`practice_areas/${practiceArea.id}`, {
          name: practiceArea.name
        })
        this.$refs.alert.showAlert('Successfully updated practice area name', 'success')
        this.$set(practiceArea, 'name', res.data?.name)
      } catch (error) {
        this.$refs.alert.showAlert('Error updating the practice area name', 'error')
        console.error(error)
      }
    },

    async updateKeywordList (keyword, evt) {
      const keywords = this.selectedKeywords.reduce((acc, curr) => {
        acc[curr.name.toLowerCase()] = curr.weight
        return acc
      }, {})

      try {
        const res = await this.$arc.patch(`practice_areas/${this.currentPracticeArea.id}`, {
          keywords
        })
        this.$set(this.currentPracticeArea, 'keywords', res.data?.keywords)
        this.$refs.alert.showAlert('Successfully updated practice area keywords', 'success')
      } catch (error) {
        this.$refs.alert.showAlert('Error updating the practice area keywords', 'error')
        console.error(error)
      }
    },

    updateKeywordName (idx, evt) {
      this.selectedKeywords[idx].name = evt
    },

    updateKeywordWeight (idx, evt) {
      this.selectedKeywords[idx].weight = evt
    },

    closeTableModal () {
      this.selectedKeywords = []
      this.$refs['table-modal'].style.display = 'none'
    },

    toggleFormModal () {
      this.formModalActive = !this.formModalActive

      if (!this.formModalActive) {
        this.form = {
          practiceAreaName: '',
          keywords: []
        }
        this.keywordsToAdd = {
          name: '',
          weight: ''
        }
        this.loadedPracticeAreas = null
      }
    },

    toggleTableAction (evt) {
      const icon = evt.target.closest('.table-action-icon')
      const ul = icon.nextElementSibling
      ul.classList.toggle('show')
    },

    submitPracticeAreaForm () {
      if (this.form.keywords.length === 0 && this.keywordsToAdd.name === '' && !this.loadedPracticeAreas) {
        this.$refs.alert.showAlert('Please add at least 1 keyword', 'error')
        return
      }
      if (this.form.keywords.every(keyword => keyword.weight === 1)) {
        this.$refs.alert.showAlert('Please add at least 1 keyword with a weight greater than 1', 'error')
        // eslint-disable-next-line no-useless-return
        return
      }
      if ((this.form.practiceAreaName) || this.loadedPracticeAreas.length > 0) {
        if (this.form.mode === 'edit') {
          this.editPracticeArea()
        } else {
          this.addNewPracticeArea()
        }
      } else {
        this.$refs.alert.showAlert('Please fill out practice area name and at least 1 keyword', 'error')
      }
    },

    async editPracticeArea () {
      if (this.keywordsToAdd.name) {
        if (!this.keywordsToAdd.weight) this.keywordsToAdd.weight = 1
        this.form.keywords.push({
          name: this.keywordsToAdd.name,
          weight: parseInt(this.keywordsToAdd.weight)
        })
        this.keywordsToAdd = {
          name: '',
          weight: ''
        }
      }

      const formKeywords = this.form.keywords.reduce((acc, curr) => {
        acc[curr.name.toLowerCase()] = curr.weight
        return acc
      }, {})

      if ((JSON.stringify(this.currentPracticeArea.keywords) === JSON.stringify(formKeywords)) && this.currentPracticeArea.name === this.form.practiceAreaName) {
        this.$refs.alert.showAlert('No changes made.', 'error')
        return
      }

      const formData = {
        ...(this.currentPracticeArea.name !== this.form.practiceAreaName) && { name: this.form.practiceAreaName.toLowerCase() },
        keywords: this.form.keywords.reduce((acc, curr) => {
          acc[curr.name.toLowerCase()] = curr.weight
          return acc
        }, {})
      }

      try {
        const response = await this.$arc.patch(`practice_areas/${this.currentPracticeArea.id}`, formData)
        const practiceAreaToUpdate = this.practiceAreas.find(pa => pa.id === this.currentPracticeArea.id)
        this.$set(practiceAreaToUpdate, 'name', response.data.name)
        this.$set(practiceAreaToUpdate, 'keywords', response.data.keywords)
        const divisor = Object.values(response.data.keywords).reduce((acc, curr) => {
          acc += curr
          return acc
        }, 0)
        this.$set(practiceAreaToUpdate, 'divisor_magic_num', divisor)
        this.$refs.alert.showAlert('Successfully updated practice area', 'success')
        this.toggleFormModal()
      } catch (error) {
        const errorRes = error.response?.data
        if (errorRes.validation_error.body_params) {
          errorRes.validation_error.body_params.forEach(err => {
            if (err.type === 'string_too_short') {
              this.$refs.alert.showAlert('Practice area name too short', 'error')
            } else {
              this.$refs.alert.showAlert(err.msg, 'error')
            }
          })
        }
      }
    },

    async addNewPracticeArea () {
      if (this.loadedPracticeAreas) {
        this.loadedPracticeAreas.forEach(async pa => {
          pa.name = pa.name.toLowerCase()
          pa.keywords = pa.keywords.reduce((acc, curr) => {
            acc[curr.name.toLowerCase()] = curr.weight
            return acc
          }, {})
          try {
            const response = await this.$arc.post('practice_areas', pa)
            this.practiceAreas.push(response.data)
            this.loadedPracticeAreas = null
            this.toggleFormModal()
            this.$refs.alert.showAlert('Successfully added new practice area', 'success')
          } catch (error) {
            const errorRes = error.response?.data
            if (errorRes.validation_error.body_params) {
              errorRes.validation_error.body_params.forEach(err => {
                this.$refs.alert.showAlert(err.msg, 'error')
              })
            }
          }
        })
      } else {
        if (this.keywordsToAdd.name) {
          if (!this.keywordsToAdd.weight) this.keywordsToAdd.weight = 1
          this.form.keywords.push({
            name: this.keywordsToAdd.name,
            weight: parseInt(this.keywordsToAdd.weight)
          })
          this.keywordsToAdd = {
            name: '',
            weight: ''
          }
        }
        const formData = {
          name: this.form.practiceAreaName.toLowerCase(),
          keywords: this.form.keywords.reduce((acc, curr) => {
            acc[curr.name.toLowerCase()] = curr.weight
            return acc
          }, {})
        }

        try {
          const response = await this.$arc.post('practice_areas', formData)
          this.practiceAreas.push(response.data)
          this.toggleFormModal()
          this.$refs.alert.showAlert('Successfully added new practice area', 'success')
        } catch (error) {
          const errorRes = error.response?.data
          if (errorRes.validation_error.body_params) {
            errorRes.validation_error.body_params.forEach(err => {
              if (err.type === 'string_too_short') {
                this.$refs.alert.showAlert('Practice area name too short', 'error')
              } else {
                this.$refs.alert.showAlert(err.msg, 'error')
              }
            })
          }
        }
      }
    },

    togglePracticeAreaEditForm (idx) {
      this.currentPracticeArea = this.practiceAreas[idx]
      this.form.practiceAreaName = this.currentPracticeArea.name
      this.form.keywords = Object.entries(this.currentPracticeArea.keywords).map(([key, value]) => {
        return {
          name: key,
          weight: value
        }
      })
      this.toggleFormModal()
      this.form.mode = 'edit'
    },

    deletePracticeArea (idx) {
      if (confirm('Are you sure you want to delete this practice area?')) {
        const practiceAreaToDelete = this.practiceAreas[idx]

        if (practiceAreaToDelete.id) {
          this.$arc.delete(`practice_areas/${practiceAreaToDelete.id}`)
            .then((res) => {
              this.practiceAreas.splice(idx, 1)
              this.$refs.alert.showAlert('Successfully deleted the practice area', 'success')
            })
            .catch(error => {
              alert('An error occurred while deleting the practice area')
              this.$refs.alert.showAlert('An error occurred while deleting the practice area', 'error')
              console.error(error)
            })
        }
      }
    },

    uploadPaCsv (evt) {
      const file = evt.target.files[0]
      const reader = new FileReader()

      reader.onload = e => {
        const csvData = e.target.result

        csv()
          .fromString(csvData)
          .then(obj => {
            const practiceAreas = {}

            obj.forEach(item => {
              const { name, keyword, value } = item
              if (!practiceAreas[name]) {
                practiceAreas[name] = {
                  name,
                  keywords: []
                }
              }
              practiceAreas[name].keywords.push({
                name: keyword,
                weight: parseInt(value)
              })
            })

            this.loadedPracticeAreas = Object.values(practiceAreas)
          })
      }
      reader.readAsText(file)
    }
  }
}
</script>

<style lang="scss">
section {
  &.user-profile {
    .container {
      .page-header {
        margin-bottom: 40px;
      }

      .form-buttons {
        margin-top: 30px;
      }

      .form-modal,
      .table-modal {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: none;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, .3);
      }

      .form-modal {
        thead {
          &::before {
            border-radius: 3px 3px 0 0;
          }
          th {
            font-size: 15px;
          }
        }
        tbody {
          &::before {
            border-radius: 0 0 3px 3px;
          }
        }
        .input-container {
          font-size: 15px;
          margin: 0 -5px;

          input {
            border: 1px solid #E0E9EF;
          }
        }
      }

      .form-modal {
        &.show {
          display: flex;
        }

        &-wrapper {
          background: #fff;
          padding: 3rem;
          border-radius: 14px;
          border: 1px solid #E0E9EF;
          max-width: 800px;
          width: 100%;

          h3 {
            font-size: 23px;
            font-weight: 700;
          }

          .btn-group {
            .button {
              font-size: 15px;
              padding: .75rem 1.25rem;
            }
          }

          .field-group {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            margin: 0;

            .input-container,
            .dropdown-container {
              flex-basis: calc(100% + 10px);
              input {
                height: 45px;
                padding: .75rem 1.25rem;
                font-size: 15px;
              }

              .dropdown-select {
                top: 72px;
                width: auto;
                left: -5px;
                right: -5px;
                border-radius: 0;

                li {
                  // height: 45px;
                  padding: .75rem 1.25rem;
                  font-size: 15px;
                }
              }
            }

            tbody {
              span {
                position: relative;
              }
              td {
                padding-block: .75rem;
                height: 45px;
                font-size: 15px;
                &.no-padding {
                  button {
                    width: 30px;
                    height: 30px;
                    padding: 0;
                    position: absolute;
                    right: -20px;
                    background: #fff;
                    svg {
                      width: .5em
                    }
                    &:only-child {
                      top: -15px;
                    }
                  }
                }
              }
            }
          }

          .flex {
            display: flex;
            gap: 15px;
            width: 100%;
            align-items: flex-end;
          }
        }
      }

      .table-modal {
        &-wrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          gap: 20px;
        }

        &-content {
          h3 {
            margin-bottom: 15px;
          }

          ul {
            li {
              padding: 12px;

              &:not(:last-child) {
                border-bottom: 1px solid #E0E9EF;
              }

              span {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}
</style>
