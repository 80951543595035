<template>
  <div class="note">
    <div class="note-header">
      <div class="note-meta">
        <span>{{ author }}</span>
        <span>{{ date | moment('M/DD/YYYY') }}</span>
      </div>
      <div class="note-actions">
        <action-links
          v-if="!editing">
          <Button
            text="edit"
            @click="beginEditing" />
          <Button
            text="delete"
            @click="deleteNote" />
        </action-links>
      </div>
    </div>
    <div class="note-content">
      <div
        class="note-editing"
        v-if="editing">
        <span
          class="note-cancel"
          @click="cancelEdit">
          <font-awesome-icon
            icon="times" />
        </span>
        <span
          class="note-create"
          @click="doneEditing">
          <font-awesome-icon
            icon="check" />
        </span>
        <textarea
          v-model="editContent"></textarea>
      </div>
      <span v-if="!editing">
        {{ content }}
      </span>
    </div>
  </div>
</template>

<script>
import ActionLinks from '@/components/misc/ActionLinks'
import Button from '@/components/form/Button'

export default {
  name: 'Note',
  components: {
    Button,
    'action-links': ActionLinks
  },
  data () {
    return {
      editing: false,
      editContent: this.content
    }
  },
  props: {
    author: String,
    date: String,
    content: String
  },
  methods: {
    beginEditing () {
      this.editing = true
    },
    doneEditing () {
      this.editing = false
      this.$emit('update:content', this.editContent)
    },
    deleteNote () {
      this.$emit('delete:note')
    },
    cancelEdit () {
      this.editing = false
      this.editContent = this.content
    }
  }
}
</script>

<style lang="scss">
.note {
  border: 1px solid #e4edf2;
  background: #fff;
  border-radius: 10px;
  padding: 25px;
  margin-bottom: 15px;
  &-editing {
    position: relative;
    textarea {
      border: 1px solid #e4edf2;
      width: 100%;
      border-radius: 5px;
      resize: none;
      padding: 15px 65px 10px 20px;
      background: #fff;
      margin-bottom: 15px;
      font-family: "Poppins", sans-serif;
      color: #212741;
      font-size: 16px;
      &:focus {
        outline: 0;
      }
    }
  }
  &-cancel, &-create {
    position: absolute;
    z-index: 999;
    top: 5px;
    right: 5px;
    font-size: 10px;
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #eee;
    padding: 3px 5px;
    cursor: pointer;
    border-radius: 5px;
  }
  &-create {
    background: #2cca6e;
    color: #fff;
  }
  &-cancel {
    right: 35px;
  }
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .action-links {
    margin: 0;
    .button:last-child {
      margin: 0;
    }
  }
  &-meta {
    span {
      margin-right: 5px;
      display: inline-block;
      font-weight: 700;
      font-size: 16px;
    }
  }
  &-content {
    padding-top: 15px;
  }
}
</style>
