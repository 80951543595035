import Vue from 'vue'
import { createAuth0Client } from '@auth0/auth0-spa-js'
import axios from 'axios'

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname)

let instance

export const getInstance = () => instance

export const useAuth0 = ({
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  redirectUri = window.location.origin,
  ...options
}) => {
  if (instance) return instance

  instance = new Vue({
    data () {
      return {
        loading: true,
        isAuthenticated: false,
        user: {},
        auth0Client: null,
        popupOpen: false,
        error: null,
        role: null,
        managementClient: null
      }
    },
    methods: {
      getAuthData () {
        const data = {
          isAuthenticated: this.isAuthenticated,
          user: this.user,
          auth0Client: this.auth0Client,
          popupOpen: this.popupOpen,
          error: this.error,
          role: this.role
        }
        return data
      },
      async loginWithPopup (options, config) {
        this.popupOpen = true
        try {
          await this.auth0Client.loginWithPopup(options, config)
          this.user = await this.auth0Client.getUser()
          this.isAuthenticated = await this.auth0Client.isAuthenticated()
          if (this.isAuthenticated && this.user) {
            this.role = await this.checkUserRole(this.user.email)
          }
          this.error = null
        } catch (e) {
          console.error(e)
          this.error = e
        } finally {
          this.popupOpen = false
        }
      },
      async handleRedirectCallback () {
        this.loading = true
        try {
          await this.auth0Client.handleRedirectCallback()
          this.user = await this.auth0Client.getUser()
          this.isAuthenticated = true
          if (this.isAuthenticated && this.user) {
            this.role = await this.checkUserRole(this.user.email)
          }
          this.error = null
        } catch (e) {
          console.error(e)
          this.error = e
        } finally {
          this.loading = false
        }
      },
      loginWithRedirect (o) {
        return this.auth0Client.loginWithRedirect(o)
      },
      getIdTokenClaims (o) {
        return this.auth0Client.getIdTokenClaims(o)
      },
      getTokensSilently (o) {
        return this.auth0Client.getTokenSilently(o)
      },
      getTokenWithPopup (o) {
        return this.auth0Client.logout(o)
      },
      logout (o) {
        this.auth0Client.logout(o)
      },
      async checkUserRole (email) {
        let accessToken
        const resp = await axios({
          method: 'POST',
          url: `https://${process.env.VUE_APP_AUTH0_DOMAIN}/oauth/token`,
          data: new URLSearchParams({
            client_id: `${process.env.VUE_APP_AUTH0_MANAGEMENT_API_SERVICE_CLIENT_ID}`,
            client_secret: `${process.env.VUE_APP_AUTH0_MANAGEMENT_API_SERVICE_CLIENT_SECRET}`,
            audience: `${process.env.VUE_APP_AUTH0_MANAGEMENT_API_SERVICE_AUDIENCE}`,
            grant_type: `${process.env.VUE_APP_AUTH0_MANAGEMENT_API_SERVICE_GRANT_TYPE}`
          }),
          headers: { 'content-type': 'application/x-www-form-urlencoded' }
        })

        if (resp.status === 200) {
          accessToken = resp.data.access_token
        } else {
          throw new Error('Could not get access token')
        }

        this.managementClient = axios.create({
          baseURL: `${process.env.VUE_APP_AUTH0_MANAGEMENT_API_SERVICE_AUDIENCE}`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          }
        })

        try {
          const { data: roles } = await this.managementClient.get('roles')
          const adminRole = roles.find(role => role.name === 'arc-admin')
          const { data: adminUsers } = await this.managementClient.get(`roles/${adminRole.id}/users`)
          return adminUsers.find(user => user.email === email) ? 'admin' : 'regular'
        } catch (e) {
          console.error(e)
          return false
        }
      }
    },
    async created () {
      this.auth0Client = await createAuth0Client({
        ...options,
        client_id: options.clientId,
        authorizationParams: {
          redirect_uri: redirectUri,
          ...options.authorizationParams
        }
      })

      try {
        if (
          window.location.search.includes('code=') &&
          window.location.search.includes('state=')
        ) {
          const { appState } = await this.auth0Client.handleRedirectCallback()
          this.error = null
          onRedirectCallback(appState)
        }
      } catch (e) {
        console.error(e)
        this.error = e
      } finally {
        this.isAuthenticated = await this.auth0Client.isAuthenticated()
        this.user = await this.auth0Client.getUser()
        if (this.isAuthenticated && this.user) {
          this.role = await this.checkUserRole(this.user.email)
        }
        this.loading = false
      }
    }
  })

  return instance
}

export const Auth0Plugin = {
  install (Vue, options) {
    Vue.prototype.$auth = useAuth0(options)
  }
}
