var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"button",class:[
    _vm.btnType ? ("is-" + _vm.btnType) : '',
    {
      'is-disabled': _vm.isDisabled,
      'is-inverse': _vm.isInversed,
      'is-bordered': _vm.isBordered,
      'is-rounded': _vm.isRounded,
      'is-small': _vm.isSmall,
      'is-plain': _vm.isPlain,
      'is-light': _vm.isLight,
      'left-aligned': _vm.leftAligned,
      'is-view': _vm.isView,
      'has-icon': _vm.hasIcon,
      'has-icon-only': _vm.hasIconOnly,
      'is-uppercase': _vm.isUppercase,
      'no-background': _vm.noBackground,
      'is-icon-reversed': _vm.iconReversed
    }
  ],attrs:{"type":_vm.type,"disabled":_vm.isDisabled},on:{"click":function($event){return _vm.$emit('click', $event)}}},[(_vm.hasLink)?_c('router-link',{attrs:{"to":_vm.btnLink,"target":"_blank","rel":"noopener"}},[_vm._v(" "+_vm._s(_vm.btnLinkText)+" ")]):(!_vm.hasLink && !_vm.isView && !_vm.hasIconOnly)?_c('span',[_vm._v(_vm._s(_vm.text))]):_vm._e(),(_vm.hasIcon)?_c('font-awesome-icon',{attrs:{"icon":_vm.icon}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }