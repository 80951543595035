<template>
  <div class="issue-card">
    <div class="issue-card-wrapper">
      <div class="issue-card-header">
        <img :src="require(`@/assets/img/issues-icons/${icon}`)">
        <span class="issue-card-header-title">{{ title }}</span>
      </div>
      <div class="issue-card-content" v-html="content"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PowerRankingsIssue',
  props: {
    icon: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
.issue-card {
  border: 1px solid #495A81;
  border-radius: 10px;
  padding: 40px 30px;
  // display: flex;
  // align-items: center;

  &-wrapper {
    display: flex;
    flex-direction: column;
    gap: 22px;
    align-items: flex-start;
    justify-content: center;
  }

  &-content {
    color: #fff;
    text-align: left;
    font-size: 18px;
    line-height: 28px;

    span.dynamic-content {
      color: #95BDFF;
    }
  }

  &-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 26px;
    &-title {
      color: #EE6152;
      font-size: 22px;
      line-height: 30px;
      font-weight: 700;
    }
    img {
      max-width: unset;
      object-fit: contain;
      margin: 0 !important;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .issue-card {
    padding: 60px 77px;

    &-header {
      flex-direction: row;
      align-items: center;
    }
  }
}
</style>
