<template>
  <Form
    ref="bulkForm"
    :class="{
      'dropdown-toggled': dropdownToggled
    }">
    <Input
      :hasLabel="true"
      labelText="Enter url"
      inputId="webUrl"
      placeholder="Url"
      v-model="bulkFormData.url"
      :disabled="loading" />
    <Dropdown
      :options="filteredLocations"
      :isSearch="true"
      dropdownPlaceholder="Start Typing Location"
      :inputHasLabel="true"
      inputId="city"
      inputLabelText="Select city"
      @dropdownToggled="toggleDropdownClass"
      @select="bulkFormData.dfs_location_name = $event"
      @input="debounceLocationName($event)"
      :inputVal="bulkFormData.dfs_location_name"
      :isDisabled="loading" />
    <Dropdown
      :options="formData.practiceAreas"
      :hasIcon="true"
      dropdownPlaceholder="Practice Area"
      :inputHasLabel="true"
      inputId="practiceArea"
      inputLabelText="Select practice area"
      @dropdownToggled="toggleDropdownClass"
      @select="bulkFormData.practice_area_name = $event"
      @input="bulkFormData.practice_area_name = $event"
      :inputVal="bulkFormData.practice_area_name"
      :isDisabled="loading" />
    <Loading
      :isSpinner="true"
      v-if="loading" />
    <Button
      :hasIcon="true"
      :hasIconOnly="true"
      icon="minus"
      :isInversed="true"
      :isBordered="true"
      :isRounded="true"
      type="button"
      @click="$emit('minusClick', $event)"
      v-else />
    <p
      v-if="completedTask.completed_at">
      This search is complete! View your results
      <a
        :href="formattedSlug"
        target="_blank"
        rel="noopener">
        here
      </a>
    </p>
    <div
      class="validation-messages"
      v-if="formError">
      <p
        v-for="(error, i) in formErrors"
        :key="i">
        {{ error }}
        <!-- Please <a @click="runNewSearch">try again.</a> -->
      </p>
    </div>
  </Form>
</template>

<script>
import Form from '@/components/form/Form'
import Input from '@/components/form/Input'
import Dropdown from '@/components/form/Dropdown'
import Button from '@/components/form/Button'
import Loading from '@/components/misc/Loading'
// import { bus } from '@/utils/event-bus'
import { mapGetters, mapState } from 'vuex'
import _ from 'lodash'

export default {
  name: 'BulkForm',
  components: {
    Form,
    Input,
    Dropdown,
    Button,
    Loading
  },
  data () {
    return {
      loading: false,
      completed: false,
      completedTask: {},
      formError: false,
      formErrors: [],
      location: '',
      dropdownToggled: false,
      filteredLocations: []
    }
  },
  props: {
    bulkForm: Object
  },
  computed: {
    ...mapState({
      formData: state => state.form
    }),

    ...mapGetters({
      allUsLocations: 'form/allUsLocations'
    }),

    // use this.searchLocations() to update computed value
    // filteredLocations () {
    //   return this.searchLocationList(this.bulkFormData.location, this.allUsLocations).slice(0, 100)
    // },

    bulkFormData: {
      get: function () {
        return this.bulkForm.data
      },
      set: function (newVal) {
        this.bulkForm.data = newVal
      }
    },
    bulkFormStatus: {
      get: function () {
        return this.bulkForm.status
      },
      set: function (newVal) {
        this.bulkForm.status = newVal
      }
    },
    formattedSlug () {
      return this.getTaskSlug(
        this.completedTask.completed_at,
        this.completedTask.dfs_location_name,
        this.completedTask.practice_area_name,
        this.completedTask.id
      )
    }
  },
  watch: {
    bulkFormStatus: {
      handler: function (val) {
        this.loading = val
        if (val) {
          this.enqueueTask(
            this.bulkFormData.practice_area_name,
            this.bulkFormData.dfs_location_name,
            this.bulkFormData.url
          )
        } else {
          this.bulkFormStatus = false
        }
      }
    }
  },
  methods: {
    debounceLocationName: _.debounce(function (val) {
      if (val.length > 2) {
        const cleanedVal = val.replace(/, /g, ',')
        this.getLocations(cleanedVal)
      } else {
        this.filteredLocations = []
      }
    }, 200),

    getLocations (name) {
      this.filteredLocations = this.searchUsLocationList(name).map(location => {
        return location.replace(/,/g, ', ')
      })
    },

    enqueueTaskTest (practiceArea, location, url) {
      this.bulkFormStatus = true
      const task = {
        pa: practiceArea,
        loc: location,
        url: url
      }
      console.log(task)
      setTimeout(() => {
        this.bulkFormStatus = false
      }, 8000)
    },

    enqueueTask (practiceArea, location, url) {
      this.bulkFormStatus = true
      this.$arc.post('tasks', {
        practice_area_name: `${practiceArea}`,
        dfs_location_name: `${location.replace(/, /g, ',')}`,
        ...(url && { url })
      })
        .then(response => {
          const task = response.data
          this.waitForTask(task.id)
        })
        .catch(error => {
          console.error(error)
          this.bulkFormStatus = false
        })
    },

    randomFloatFromInterval (lowerBound = 5000, upperBound = 10000) {
      return Math.random() * (upperBound - lowerBound) + lowerBound
    },

    // gets task by id and opens up websocket connection
    // waits for the connections to return a response
    // sets tableData once the response is complete
    async waitForTask (id) {
      const now = new Date()

      try {
        const resp = await this.$arc.get(`tasks/${id}/status`)

        let shouldRetry = false

        const { created_at: createdAt, completed_at: completedAt } = resp.data

        shouldRetry = !completedAt && (now.getTime() - new Date(createdAt).getTime() < this.TEN_MINS)

        if (completedAt) {
          this.getTaskById(id)
        } else if (!shouldRetry) {
          this.formError = true
          this.formErrors = ['Report couldn\'t complete in under 10 minutes. Please check the archives at a later time.']
          this.loading = false
        }

        if (shouldRetry) {
          setTimeout(() => {
            this.waitForTask(id)
          }, this.randomFloatFromInterval())
        }
      } catch (error) {
        this.bulkFormStatus = false
        console.error('error fetching task status:', error)
        this.loading = false
      }
    },

    getTaskById (id) {
      this.loading = true
      this.$arc.get(`tasks/${id}`)
        .then(res => {
          this.completedTask = res.data
          this.bulkFormStatus = false
          this.loading = false
        })
        .catch(err => {
          console.error(err)
          this.formError = true
          this.formErrors = err
          this.bulkFormStatus = false
        })
    },

    // reset state to run a new search
    runNewSearch () {
      this.formError = false
      this.$refs.bulkForm.$el.reset()
      this.$store.dispatch('table/setTableData', {})
    },

    toggleDropdownClass () {
      this.dropdownToggled = !this.dropdownToggled
    }
  }
}
</script>

<style lang="scss" scoped>
form {
  margin-bottom: 40px;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
  &.dropdown-toggled {
    z-index: 999;
  }
  .input-container,
  .dropdown-container {
    flex-grow: 1;
  }
  p {
    width: 100%;
    text-align: left;
  }
}
</style>
