<template>
  <section
    class="power-rankings-issues"
    v-show="!hide">
    <span class="h1 text-centered">
      Multiple Issues Preventing Your Site From Ranking Higher on Google
    </span>
    <div class="issues-grid">
      <PowerRankingsIssue
        v-for="issue in formattedIssues"
        :key="issue.id"
        :icon="issue.icon"
        :title="issue.title"
        :content="issue.content"
      />
    </div>
  </section>
</template>

<script>
import sectionData from '../../../data/issues.json'
import PowerRankingsIssue from './PowerRankingsIssue.vue'

export default {
  name: 'PowerRankingsIssues',
  components: {
    PowerRankingsIssue
  },
  props: {
    hide: {
      type: Boolean,
      default: false
    },
    practiceArea: {
      type: String,
      required: true
    },
    domain: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      sectionData
    }
  },
  computed: {
    formattedIssues () {
      return this.sectionData.issues.map(issue => {
        return {
          ...issue,
          content: issue.content
            .replace(/{practice_area}/g, `<span class="dynamic-content">${this.practiceArea}</span>`)
            .replace(/{domain}/g, `<span class="dynamic-content">${this.domain}</span>`)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.power-rankings-issues {
  background: #061945;
  margin-top: 80px;
  padding-block: 80px;

  > span.h1.text-centered {
    color: #fff;
    max-width: 714px;
    margin-inline: auto;
    margin-top: 0;

    &::after {
      margin-bottom: 47px;
    }
  }

  .issues-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 50px 56px;
    max-width: 1366px;
    margin-inline: auto;
    grid-auto-rows: minmax(385px, auto);
  }
}

@media only screen and (min-width: 768px) {
  .power-rankings-issues {
    .issues-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media only screen and (min-width: 1200px) {
  .power-rankings-issues {
    margin-top: 147px;
    padding-block: 142px 148px;
    > span.h1.text-centered {
      &::after {
        margin-bottom: 85px;
      }
    }
    .issues-grid {
    }
  }

}
</style>
