export const table = {
  namespaced: true,
  state: {
    tableData: {},
    sort: {
      isAsc: true,
      key: ''
    }
  },
  mutations: {
    setTableData (state, data) {
      state.tableData = data
    },
    sortTableData (state, tableData) {
      state.tableData = tableData
    },
    setSortKey (state, key) {
      state.sort.key = key
    },
    setIsAsc (state, asc) {
      state.sort.isAsc = asc
    }
  },
  actions: {
    setTableData ({ commit }, data) {
      commit('setTableData', data)
    },
    setIsAsc ({ commit }, asc) {
      commit('setIsAsc', asc)
    },
    setSortKey ({ commit, dispatch, state }, key, asc) {
      commit('setSortKey', key)
      if (key === state.sort.key) {
        commit('setIsAsc', !state.sort.isAsc)
      } else {
        commit('setIsAsc', asc)
      }
      dispatch('sortTableData', key)
    },
    sortTableData ({ commit, state }, sortKey) {
      state.sort.isAsc = sortKey === state.sort.key ? !state.sort.isAsc : true
      // state.sort.key = sortKey
      const tableData = state.tableData
      if (sortKey) {
        tableData.results.sort((a, b) => {
          a = a[sortKey]
          b = b[sortKey]
          return (a === b ? 0 : a > b ? 1 : -1) * (state.sort.isAsc ? 1 : -1)
        })
      }
      commit('sortTableData', tableData)
    }
  },
  getters: {
    getTableData: (state) => state.tableData,
    getSortKey: (state) => state.sort.key,
    getIsAsc: (state) => state.sort.isAsc
  }
}
