<template>
  <BaseLayout
    :title="pageTitle"
    :customClasses="['user-profile']">
    <div class="page-main">
      <div class="page-sidebar">
        <div class="sidebar-wrapper">
          <div class="sidebar-header">
            <h3>Settings</h3>
          </div>
          <div class="sidebar-content">
            <ul>
              <li>
                <router-link to="/user/settings/profile">
                  Personal Information
                </router-link>
              </li>
              <li v-if="$auth.role === 'admin'">
                <router-link to="/user/settings/practiceareas">
                  Practice Areas
                </router-link>
              </li>
              <li>
                <router-link to="/user/settings/marketingcompanies">
                  Marketing Companies
                </router-link>
              </li>
              <li v-if="$auth.role === 'admin'">
                <router-link to="/user/settings/markettiers">
                  Market Tiers
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <router-view />
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/views/BaseLayout'

export default {
  name: 'UserSettings',
  components: {
    BaseLayout
  },
  data () {
    return {
      pageTitle: ''
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler () {
        this.pageTitle = this.$route.meta.title
      }
    }
  },
  mounted () {
    this.pageTitle = this.$route.meta.title
  }
}
</script>

<style lang="scss">
.page-main {
  display: grid;
  grid-template-columns: 360px 1fr;
  gap: 40px;

  .page-sidebar {
    .sidebar {
      &-wrapper {
        background: #fff;
        border-radius: 14px;
        border: 1px solid #E0E9EF;
      }
      &-header {
        padding: 1.25rem;
        border-bottom: 1px solid #E0E9EF;
        h3 {
          text-align: left;
          font-size: 17px;
          font-weight: 700;
          margin-bottom: 0;
        }
      }
      &-content {

        ul {
          // padding-block: 1.25rem;
          padding-inline: 5px;
          li {
            a {
              text-decoration: none;
              color: inherit;
              font-weight: 500;
              padding: 1rem 1.25rem;
              display: block;

              &.router-link-active {
                color: #0158E3;
                background: #fbfdff;
              }
            }
            &:not(:last-child) {
              a {
                border-bottom: 1px solid #E0E9EF;
              }
            }
          }
        }
      }
    }
  }
}
</style>
