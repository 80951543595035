<template>
  <div class="files">
    <div class="files-header">
      <h2>Files</h2>
      <action-links>
        <Input
          type="file"
          ref="icon"
          :hasLabel="true"
          labelText="Add new"
          inputId="uploadFile"
          @change="uploadFile($event)" />
      </action-links>
    </div>
    <div class="files-container">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import ActionLinks from '@/components/misc/ActionLinks'
import Input from '@/components/form/Input'

export default {
  name: 'Files',
  components: {
    Input,
    'action-links': ActionLinks
  },
  methods: {
    uploadFile (e) {
      this.$emit('upload:file', e.target.files[0])
    }
  }
}
</script>

<style lang="scss">
.files {
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .action-links {
      margin: 0;
      button {
        &:last-child {
          margin: 0;
        }
      }
    }
  }
  .input-container {
    &.has-label {
      width: 70px;
      label {
        font-weight: 400;
        text-align: right;
      }
    }
  }
}
</style>
