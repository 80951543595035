<template>
  <div
    v-if="alert.show"
    class="alert"
    :class="{
      'animate': alert.animate,
      [alert.type]: alert.type
    }">
    <span>{{ alert.message }}</span>
    <button @click="closeAlert">X</button>
  </div>
</template>

<script>
export default {
  name: 'Alert',
  data () {
    return {
      alert: {
        show: false,
        message: '',
        type: '',
        animate: false
      }
    }
  },
  methods: {
    closeAlert () {
      this.alert.show = false
    },
    showAlert (message, type) {
      this.alert.show = true
      this.alert.message = message
      this.alert.type = type
      setTimeout(() => {
        this.alert.animate = true
      }, 1000)

      setTimeout(() => {
        this.alert.animate = false
      }, 5000)

      setTimeout(() => {
        this.alert.show = false
        this.alert.message = ''
        this.alert.type = ''
      }, 6000)
    }
  }
}
</script>

<style lang="scss" scoped>
.alert {
  position: fixed;
  top: 90px;
  // left: 10px;
  right: 10px;
  padding: 10px 60px 10px 30px;
  min-height: 60px;
  min-width: 500px;
  max-width: 700px;
  text-align: left;
  z-index: 1000;
  border-radius: 14px;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 0;
  transform: translateY(25px);
  transition: all 0.3s ease;
  &.animate {
    opacity: 1;
    transform: translateY(0);
  }
  &.success {
    background-color: #D9F2D9;
    color: #4F8B4F;
    border-color: #4F8B4F;
  }
  &.error {
    background-color: #ffe6e8;
    color: #B14343;
    border-color: #B14343;
  }
  &.info {
    background-color: #E6F0FF;
    color: #3A6EB4;
    border-color: #3A6EB4;
  }
  button {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    cursor: pointer;
    color: inherit;
  }
}
</style>
