<template>
  <div class="settings-panel">
    <div class="settings-panel-heading">
      <Button
        v-if="hasActionLink"
        @click="actionLinkMethod"
        text="New"
        btnType="primary"
        :hasIcon="true"
        icon="plus"
        :iconReversed="true"
        :isInversed="true"
        :isBordered="true" />
    </div>
    <div class="settings-panel-body">
      <slot></slot>
    </div>
    <div class="settings-panel-modal">
      <slot name="modal"></slot>
    </div>
  </div>
</template>

<script>
import Button from '@/components/form/Button'

export default {
  name: 'SettingsPanel',
  components: {
    Button
  },
  props: {
    heading: {
      type: String,
      required: false
    },
    actionLinkMethod: {
      type: Function,
      default: () => {}
    },
    hasActionLink: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
.settings-panel {
  // border: 1px solid #B6C3CB;
  position: relative;
  border-radius: 3px;
  &-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: -73px;
    right: 0;

    a {
      text-decoration: underline;
      cursor: pointer;
    }

    h2 {
      font-size: 1rem;
      text-transform: uppercase;
      font-weight: 800;
      letter-spacing: .02em;
    }
  }

  &-body {
    padding-block: 5px;
  }
}
</style>
