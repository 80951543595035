<template>
  <BaseLayout
    :centerHeading="true"
    :customClasses="['domain-detail']">
    <div class="domain-info"
      v-if="!loading">
      <router-link
        :to="{
          name: 'profile',
          query: {
            domain: `${domain.domain}`
          }
        }"
        class="domain-history-link"
        target="_blank"
        rel="noopener">
        www.{{ domain.domain }}
      </router-link>
      <p>Arc Score:</p>
      <h2 class="jumbo">{{ domain.arc_score }}</h2>
      <p>
        Search Location:
        <br> {{ formatLocation(task.dfs_location_name) }}
      </p>
      <p>
        Marketing Company:
        <br>
        <span style="color:#0158E3;margin:5px 0;display:block;" v-if="!editing">
          {{ domain.marketing_company }}
        </span>
        <Dropdown
          v-if="editing"
          :options="filteredCompanies"
          :isSearch="true"
          @input="marketingCompany = $event"
          @select="marketingCompany = $event"
          :inputVal="marketingCompany"
          dropdownPlaceholder="Marketing Company"
          customOption="name" />
        <action-links
          textAlign="center">
          <Button
            text="edit"
            @click="editing = true"
            v-if="!editing" />
          <Button
            text="close"
            @click="editing = false"
            v-if="editing" />
          <Button
            text="apply"
            @click="setMarketingCompany(domain, marketingCompany)"
            v-if="editing" />
        </action-links>
      </p>
      <p style="margin-bottom: 20px;">
        <strong>
          Date: {{ task.completed_at | moment('M/DD/YYYY') }}
        </strong>
      </p>
    </div>
    <Loading
      v-show="loading"
      text="Collecting data" />
    <Table
      :loading="loading"
      v-if="Object.keys(domain).length > 0">
      <template slot="head">
        <t-row>
          <t-heading>Search Term</t-heading>
          <t-heading>Rank</t-heading>
          <t-heading>URL</t-heading>
        </t-row>
      </template>
      <template slot="body">
        <t-row
          v-for="(keyword, i) in domain.keywords"
          :key="i">
          <t-cell>{{ keyword.name || 'N/A' }}</t-cell>
          <t-cell>{{ keyword.rank || 'N/A' }}</t-cell>
          <t-cell>{{ keyword.url || 'N/A' }}</t-cell>
        </t-row>
      </template>
    </Table>
  </BaseLayout>
</template>

<script>
import Table from '@/components/table/Table'
import TableRow from '@/components/table/TableRow'
import TableHeading from '@/components/table/TableHeading'
import TableDataCell from '@/components/table/TableDataCell'
import BaseLayout from '@/views/BaseLayout'
import Loading from '@/components/misc/Loading'
import Button from '@/components/form/Button'
import ActionLinks from '@/components/misc/ActionLinks'
import Dropdown from '@/components/form/Dropdown'
import { mapState } from 'vuex'

export default {
  name: 'DomainDetail',
  components: {
    Table,
    BaseLayout,
    Loading,
    Button,
    't-row': TableRow,
    't-heading': TableHeading,
    't-cell': TableDataCell,
    'action-links': ActionLinks,
    Dropdown
  },
  data () {
    return {
      domain: {},
      loading: false,
      task: {},
      // marketingCompanies: [],
      marketingCompany: '',
      editing: false
    }
  },
  created () {
    this.getTaskById(this.$route.query.id)
  },
  computed: {
    ...mapState({
      formData: state => state.form
    }),

    filteredCompanies () {
      return this.searchMarketingList(this.marketingCompany, this.formData.marketingCompanies).slice(0, 100)
    }
  },
  methods: {
    // getMarketingCompanies () {
    //   this.loading = true
    //   this.$arc.get('marketing_companies')
    //     .then(res => {
    //       this.marketingCompanies = res.data.marketing_companies
    //     })
    //     .catch(err => {
    //       console.error(err)
    //     })
    // },

    getTaskById (id) {
      this.loading = true
      this.$arc.get(`tasks/${id}`)
        .then(res => {
          this.task = res.data
          this.getDomainDetail(this.$route.query.domain)
        })
        .catch(err => {
          console.eror(err)
          this.loading = false
        })
    },

    setMarketingCompany (domain, company) {
      this.$arc.put('domains', {
        name: domain.domain,
        ...(company.length >= 3 && { marketing_company: `${company}` })
      }).then(res => {
        this.$arc.get(`domains/${res.data.name}`)
          .then(res => {
            this.$set(domain, 'marketing_company', res.data.marketing_company)
          })
          .catch(err => {
            console.error(err)
          })
        this.marketingCompany = ''
        this.editing = false
        this.$forceUpdate()
      }).catch(err => {
        console.error(err)
      })
    },

    getDomainDetail (domain) {
      this.domain = this.task.results.find(res => res.domain === domain)
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
.domain-detail {
  .container {
    .domain {
      &-info {
        max-width: 300px;
        margin: 0 auto;
      }
      &-history-link {
        display: block;
        text-align: center;
        color: #0158E3;
        font-weight: 600;
        text-decoration: none;
        font-size: 20px;
      }
    }
    .dropdown-container {
      margin: 10px 0;
      text-align: left;
    }
    .action-links {
      margin: 0;
    }
  }
}
</style>
