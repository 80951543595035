<template>
  <BaseLayout
    title="A.R.C Score"
    :customClasses="['login']"
    :centerHeading="true">
    <p>
      Please login to continue
    </p>
    <div
      class="flex-center">
      <Button
        text="Login"
        btnType="primary"
        :hasLink="false"
        @click="$auth.loginWithRedirect" />
    </div>
  </BaseLayout>
</template>

<script>
import Button from '@/components/form/Button'
import BaseLayout from '@/views/BaseLayout'

export default {
  name: 'Login',
  components: {
    Button,
    BaseLayout
  }
}
</script>

<style lang="scss">
.flex-center {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>
