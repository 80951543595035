<template>
  <BaseLayout
    :showHeading="false"
    :customClasses="['power-rankings-detail']">
    <Loading
      v-show="loading"
      text="loading report"
      style="position:fixed;top:0;left:0;bottom:0;right:0;background:#fff;" />
    <template
      #page-heading>
      <div
        class="page-heading__wrapper"
        :class="{
          'loaded': !loading
        }"
        v-show="!loading">
        <div class="page-heading center-heading">
          <h1> {{ capitalizeString(task.practice_area_name) }} Website Power Rankings Report </h1>
        </div>
        <span class="page-subheading">
          Market Analysis of {{ task.practice_area_name }} in {{ formattedLocation }}
        </span>
        <img
          src="@/assets/img/google_logo.png"
          alt="Google"
          class="google-logo"
          v-show="!loading">
        <p
          class="blurb"
          v-show="!loading">
          ARC Power Rankings analyze Google search results for a geographic area and specific {{ task.practice_area_name }} keywords.
        </p>
      </div>
    </template>
    <div
      class="firm-spotlight"
      v-show="!loading">
      <div class="firm-spotlight__container">
        <div class="firm-spotlight__container--top">
          <div class="firm-spotlight__thumbnail firm-spotlight__spacer">
            <div class="firm-spotlight__thumbnail--wrapper">
              <div
                class="firm-spotlight__spinner">
                <Spinner />
              </div>
              <img
                :src="domain.thumbnail"
                v-if="domain">
              <img src="@/assets/img/phone-white-no-notch.png" alt="">
            </div>
          </div>
          <div class="firm-spotlight__details">
            <a
              :href="`https://${domain.domain}`"
              target="_blank"
              rel="noopener"
              class="domain-link">
              www.{{ domain.domain }}
            </a>
            <span class="firm-spotlight__rank--title">
              Your Power Ranking:
            </span>
            <span class="firm-spotlight__rank">
              {{ arcRankString }}
            </span>
          </div>
        </div>
        <div class="firm-spotlight__container--bottom">
          <div class="firm-spotlight__spacer"></div>
          <div class="firm-spotlight__information content">
            <strong>
              The Power Ranking Report was created for <span>{{ task.practice_area_name }}</span>
              and <span>{{ formattedLocation }}</span> and evaluates the strength of {{ task.practice_area_name }} websites, based on a website's ability
              to rank for important keyword phrases used by consumers to find attorneys online.
            </strong>
            <p>
              {{ domain.domain }} is
              <span v-if="domain.arc_rank < 101">currently ranked as the {{ addSuffix(domain.arc_rank) }} strongest website among</span>
              <span v-else>not currently ranked in the top 100</span>
              {{ task.practice_area_name }} in {{ formattedLocation }}.
            </p>
            <p>
              This means there are {{ !domain.arc_rank ? '100+' : domain.arc_rank > 100 ? '100+' : domain.arc_rank !== 1 ? domain.arc_rank - 1 : 0 }}
              other companies that have better search engine visibility on Google. Consumers are finding other {{ formattedLocation }} {{ task.practice_area_name }} online.
            </p>
          </div>
        </div>
      </div>
    </div>
    <span
      class="h1 text-centered"
      v-show="!loading">
      Your Google Rankings in {{ formattedLocation }}
    </span>
    <Table
      :loading="loading">
      <template slot="head">
        <t-row>
          <t-heading>Search Term</t-heading>
          <t-heading
            :textCentered="true">
            Rank
          </t-heading>
        </t-row>
      </template>
      <template slot="body">
        <t-row
          v-for="(keyword, idx) in domain.keywords"
          :key="idx">
          <t-cell>
            {{ keyword.name }}
          </t-cell>
          <t-cell
            :textCentered="true">
            {{ keyword.rank > 100 ? 'Not in top 100 results' : `${keyword.rank}` }}
          </t-cell>
        </t-row>
        <t-row v-if="domain.keywords && domain.keywords.length === 0">
          <t-cell>
            No keywords found. Please try again later. Note: Locations may not match.
          </t-cell>
        </t-row>
      </template>
    </Table>
    <PowerRankingsIssues
      v-if="!loading"
      :hide="!arcScoreCheck"
      :practiceArea="task && task.practice_area_name"
      :domain="domain && domain.domain" />
    <span
      class="h1 text-centered mt-lg"
      v-show="!loading">
      Power Rankings for {{ capitalizeString(task.practice_area_name) }} <br /> in {{ formattedLocation }}
    </span>
    <div class="view-report"
      v-show="!loading">
      <a
        :href="reportSlug"
        target="_blank">
        View Report Here
      </a>
    </div>
    <div
      class="show-agency-toggle"
      v-if="showAgencyToggle && !loading">
      <Toggle
        v-model="showAgency"
        :isChecked="showAgency"
        :hasLabel="true"
        labelText="Mkt Data"
        @change="toggleAgency" />
    </div>
    <Table
      :loading="loading"
      :isResponsive="true">
      <template slot="head">
        <t-row>
          <t-heading></t-heading>
          <t-heading>
            Domain
          </t-heading>
          <t-heading
            :hasTip="true"
            thTip="(Double click to edit)"
            v-if="showAgency && showAgencyToggle">
            Marketing Co.
          </t-heading>
          <t-heading
            :textCentered="true">
            Market Tier
          </t-heading>
          <t-heading
            :textCentered="true">
            ARC Score
          </t-heading>
          <t-heading
            :textCentered="true">
            Power Rankings
          </t-heading>
        </t-row>
      </template>
      <template slot="body">
        <t-row
          v-for="(result, i) in sortedResults"
          :key="i"
          :class="{
            'highlighted': result.domain === domain.domain
          }">
          <t-cell>
            <img
              :src="getFaviconString(result.domain)"
              v-if="getFaviconString(result.domain) !== null"
              width="22"
              height="22">
          </t-cell>
          <t-cell
            item="Domain">
            <a
              :href="`https://${result.domain}`"
              target="_blank"
              rel="noopener">
              {{ result.domain }}
            </a>
          </t-cell>
          <t-cell
            :isEditable="true"
            @doneEditing="updateMarketingAgency(result, marketingCompany)"
            @dropdownInput="marketingCompany = $event"
            @dropdownSelect="marketingCompany = $event"
            :dropdownOptions="filteredCompanies"
            customOption="name"
            :defaultValue="
              result.marketing_company === ''
                ? ''
                : result.marketing_company
            "
            v-if="showAgency && showAgencyToggle">
            {{ result.marketing_company === 'null' ? '' : result.marketing_company }}
          </t-cell>
          <t-cell
            :textCentered="true"
            item="Market Tier">
            {{ task.market_tier }}
          </t-cell>
          <t-cell
            item="ARC Score"
            :textCentered="true">
            {{ result.arc_score }}
          </t-cell>
          <t-cell
            :textCentered="true"
            item="Power Rankings">
            <!-- {{ result.keyword_rank > 100 ? 'Not in top 100 results' : result.keyword_rank }} -->
              {{  i + 1 > 100 ? 'Not in top 100 results' : i + 1 }}
          </t-cell>
        </t-row>
      </template>
    </Table>
    <action-links
      v-if="!loading && sortedResults.length > 0">
      <Button
        btnType="fancy"
        @click="copy(shareableSlug)"
        text="Click to copy shareable URL"
        v-if="$auth.isAuthenticated" />
    </action-links>
  </BaseLayout>
</template>

<script>
import Table from '@/components/table/Table'
import TableRow from '@/components/table/TableRow'
import TableHeading from '@/components/table/TableHeading'
import TableDataCell from '@/components/table/TableDataCell'
import BaseLayout from '@/views/BaseLayout'
import Loading from '@/components/misc/Loading'
import { mapGetters, mapState } from 'vuex'
import Spinner from '@/components/misc/Spinner'
import ActionLinks from '@/components/misc/ActionLinks'
import Button from '@/components/form/Button'
import Toggle from '@/components/misc/Toggle'
import PowerRankingsIssues from '@/components/global/power-rankings/PowerRankingsIssues'

export default {
  name: 'PowerRankingsDetail',
  components: {
    Table,
    BaseLayout,
    Loading,
    Spinner,
    Button,
    Toggle,
    'action-links': ActionLinks,
    't-row': TableRow,
    't-heading': TableHeading,
    't-cell': TableDataCell,
    PowerRankingsIssues
  },
  data () {
    return {
      domain: {},
      loading: true,
      task: {},
      sort: {
        key: 'arc_score',
        isAsc: true
      },
      results: [],
      marketingCompany: '',
      showAgency: true
    }
  },
  created () {
    this.getAllData()
  },
  watch: {
    domain: {
      handler: function (domain) {
        if (domain) {
          this.$urlbox(`https://${domain.domain}`, {
            width: 360,
            height: 767,
            wait_until: 'mostrequestsfinished'
          })
            .then(res => {
              this.$set(domain, 'thumbnail', res)
              this.$forceUpdate()
            })
        }
      }
    }
  },
  computed: {
    ...mapState({
      formData: state => state.form
    }),

    ...mapGetters({
      profile: 'auth/getProfile'
    }),

    formattedLocation () {
      return this.formatLocation(this.task.dfs_location_name)
    },

    filteredCompanies () {
      return this.searchMarketingList(this.marketingCompany, this.formData.marketingCompanies).slice(0, 100)
    },

    userCompany () {
      return this.taskAuthor && this.taskAuthor.email ? this.getCompanyByEmail(this.taskAuthor.email) : ''
    },

    tagline () {
      return this.userCompany !== '' && (this.userCompany === 'Blue Corona' || this.userCompany === 'Ryno')
        ? 'Home Services'
        : 'Law Firm'
    },

    arcRankString () {
      return this.domain.arc_rank && this.domain.arc_rank < 101
        ? `#${this.domain.arc_rank}`
        : 'Not in top 100 results'
    },

    arcScoreCheck () {
      return this.domain.arc_score === undefined || this.domain.arc_score > 2
    },

    reportSlug () {
      return this.shareableSlug && !this.$auth.isAuthenticated
        ? this.shareableSlug.split('?domain')[0].replace('detail', 'overview')
        : this.getTaskSlug(
          this.task?.completed_at,
          this.task?.dfs_location_name,
          this.task?.practice_area_name,
          this.task?.id,
          'power-rankings-overview'
        )
    },

    shareableSlug () {
      const baseUrl = process.env.VUE_APP_BASE_URL

      const location = this.formatLocation(this.task.dfs_location_name).toLowerCase().replace(', ', '-').replace(' ', '-')
      const practiceArea = this.task?.practice_area_name?.replace(' ', '-').toLowerCase()

      return baseUrl + '/power-rankings/detail/' + `${location}/` + `${practiceArea}/` + this.task.uuid + `?domain=${this.domain.domain}`
    },

    arcRank () {
      const domain = this.sortedResults.find(result => {
        return result.domain === this.domain.domain
      })
      return domain ? domain.arc_rank : 999
    },

    sortedResults () {
      const list = this.results ? this.results : []
      if (this.sort.key) {
        list.sort((a, b) => {
          a = a[this.sort.key]
          b = b[this.sort.key]
          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
        })
      }
      return list
    },

    showAgencyToggle () {
      return this.$auth.isAuthenticated
    },

    taskAuthor () {
      return this.task?.user?.email !== null
        ? this.task?.user
        : this.task?.sales_outreach_user
    }
  },
  methods: {
    getArcRank () {
      if (this.sort.key === 'arc_score' && this.sort.isAsc) {
        this.sortedResults.forEach((result, i) => {
          result.arc_rank = i + 1
        })
      }
    },

    toggleAgency () {
      this.showAgency = !this.showAgency
    },

    getMarketingCompanyFavicon (marketingCompany) {
      const filteredCompany = this.filteredCompanies.filter(company => {
        return company.name === marketingCompany
      })
      return filteredCompany.length === 1 ? filteredCompany[0].favicon_url : null
    },

    updateMarketingAgency (result, marketingAgency) {
      this.$arc.put('domains', {
        name: `${result.domain}`,
        ...(marketingAgency.length >= 3 && { marketing_company: marketingAgency })
      }).then(response => {
        this.$arc.get(`domains/${response.data.name}`)
          .then(res => {
            this.$set(result, 'marketing_company', res.data.marketing_company)
          })
          .catch(err => {
            console.error(err)
          })
        this.marketingCompany = ''
      }).catch(err => {
        console.error(err)
      })
    },

    getAllData () {
      if (this.$route.query.id && this.$auth.isAuthenticated) {
        this.$store.dispatch('footer/setLoading', true)
        this.$arc.get(`tasks/${this.$route.query.id}`)
          .then(res => {
            this.task = res.data
            const user = {
              email: this.task.user.email,
              name: this.task.user.name,
              profile_picture_url: this.task.user.profile_picture_url,
              job_title: this.task.user.job_title,
              calendly_link: this.task.user.calendly_link,
              phone: this.task.user.phone
            }
            this.$store.dispatch('footer/setUserInfo', user.email !== null ? user : this.task.sales_outreach_user)
            // this.results = this.getPowerRank(this.task, true)
            this.results = this.task.results
            this.results.forEach(result => {
              result.domain_slug = this.getDomainSlug(
                this.task.completed_at,
                this.task.dfs_location_name,
                this.task.practice_area_name,
                result.domain,
                this.task.id,
                'power-rankings-detail'
              )
            })
            this.getArcRank()
            this.getDomainDetail(this.$route.query.domain)
            this.$store.dispatch('footer/setLoading', false)
            this.formDisabled = false
            this.loading = false
          })
          .catch(err => {
            console.error(err)
            this.formError = true
            this.formErrors = err
            this.loading = false
            this.$store.dispatch('footer/setLoading', false)
          })
      } else {
        this.$store.dispatch('footer/setLoading', true)
        const taskUUID = this.$route.params.slug.split('/')[2]
        if (taskUUID) {
          const viewArcClient = this.$axios.create({
            baseURL: process.env.VUE_APP_ARC_SCORE_API,
            headers: {
              'Content-Type': 'application/json',
              'X-View-Key': process.env.VUE_APP_ARC_SCORE_VIEW_KEY
            }
          })
          viewArcClient.get(`tasks/${taskUUID}`)
            .then(res => {
              this.task = res.data
              // this.results = this.getPowerRank(this.task, true)
              this.results = this.task.results
              this.results.forEach(result => {
                result.domain_slug = this.getDomainSlug(
                  this.task.completed_at,
                  this.task.dfs_location_name,
                  this.task.practice_area_name,
                  result.domain,
                  this.task.id,
                  'power-rankings-detail'
                )
              })
              this.getArcRank()
              this.getDomainDetail(this.$route.query.domain)
              const user = {
                email: this.task.user.email,
                name: this.task.user.name,
                profile_picture_url: this.task.user.profile_picture_url,
                job_title: this.task.user.job_title,
                calendly_link: this.task.user.calendly_link,
                phone: this.task.user.phone
              }
              this.$store.dispatch('footer/setUserInfo', user.email !== null ? user : this.task.sales_outreach_user)
              this.$store.dispatch('footer/setLoading', false)
              this.formDisabled = false
              this.loading = false
            })
            .catch(err => {
              console.error(err)
              this.formError = true
              this.formErrors = err
              this.loading = false
              this.$store.dispatch('footer/setLoading', false)
            })
        } else {
          this.$router.push({ name: 'NotFound' })
          this.loading = false
        }
      }
    },

    async getDomainDetail (domain) {
      const domainObj = this.task.results.find(res => res.domain === domain)

      if (domainObj) {
        this.domain = domainObj
      } else {
        const keywords = this.task.results[0].keywords
        this.domain = {
          domain,
          keywords: keywords.map(keyword => {
            keyword.rank = 101
            return keyword
          })
        }
      }
    }
  }
}
</script>

<style lang="scss">
.power-rankings-detail {
  padding: 0;
  .container {
    .h1 {
      &.text-centered {
        margin: 140px auto 0;
        font-family: 'DM Serif Display', serif;
        font-size: 42px;
        line-height: calc(52/42);
        color: #01307E;
        font-weight: 400;
        &:after {
          content: '';
          display: block;
          width: 186px;
          height: 1px;
          background: #EE6152;
          margin: 30px auto 47px;
        }
      }
      &.mt-lg {
        margin-top: 6.43rem;
      }
    }
    .view-report {
      text-align: center;
      margin-bottom: 30px;
      a {
        display: block;
        font-size: 1rem;
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
        color: inherit;
      }
    }

    .show-agency-toggle {
      margin: 10px auto 20px;
      max-width: 1125px;
    }
  }
  .page-header {
    display: block !important;
  }
  .page-heading__wrapper {
    padding-top: 94px;
    padding-bottom: 368px;
    text-align: center;
    background: #fff;
    position: relative;
    z-index: 1;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      opacity: 0;
      transition: opacity .5s ease;
      background: linear-gradient(to bottom, #0158E3, #012C72);
      clip-path: ellipse(100% 100% at 50% 0%);
    }
    &.loaded {
      &:before {
        opacity: 1;
      }
    }
    .page {
      &-heading {
        max-width: 755px;
        margin: 0 auto;
        h1 {
          font-size: 69px;
          line-height: calc(72/69);
          color: #fff;
          letter-spacing: 0;
        }
      }
      &-subheading {
        color: #95BDFF;
        font-size: 18px;
        line-height: calc(24/18);
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 1.8px;
        margin-top: 12px;
        display: block;
        &:after {
          content: '';
          background: #EE6152;
          width: 185px;
          height: 1px;
          display: block;
          margin: 41px auto 35px;
        }
      }
    }
    .blurb {
      font-weight: 400;
      color: #fff;
      max-width: 435px;
      font-size: 18px;
      line-height: calc(26/18);
      margin: 9px auto 0;
    }
    .google-logo {
      max-width: 130px;
      margin: 0 auto;
    }
  }
  .container {
    max-width: 100%;
  }
  table {
    max-width: 1125px;
    margin: 0 auto;
    img {
      margin: 0 auto;
    }
  }
  .page-header {
    position: relative;
    margin-bottom: 0 !important;
  }
  .action-links {
    justify-content: center;
    padding-bottom: 192px;
    margin-bottom: 0;
    margin-top: 40px;
    button {
      width: max-content;
    }
  }

  .domain-link {
    margin-bottom: 45px;
    a {
      font-family: "DM Serif Display", serif;
      color: #0158E3;
      display: block;
      text-align: center;
      font-weight: 600;
      font-size: 20px;
      text-decoration: none;
    }
  }
}
.firm-spotlight {
  background: #fff;
  padding: 0;
  max-width: 1260px;
  margin: -250px auto 0;
  position: relative;
  z-index: 1;
  border-radius: 5px;
  box-shadow: 0 5px 20px #26292C3B;
  // padding: 0 109px;
  .firm-spotlight {
    &__title {
      font-family: "DM Serif Display", serif;
      text-align: center;
      display: block;
      color: #0158E3;
      font-weight: 700;
      font-size: 24px;
      margin-bottom: 1.25rem;
      strong {
        color: #212741;
      }
    }
    &__spinner {
      position: absolute;
      top: 28px;
      left: 26px;
      bottom: 28px;
      right: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      border-radius: 20px;
      z-index: -1;
    }
    &__thumbnail {
      margin-top: -139px;
      position: relative;
      &--wrapper {
        // position: relative;
        z-index: 1;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        // background: #fff;
        img {
          max-width: 100%;
          margin: 0;
          &:first-of-type {
            position: absolute;
            z-index: -1;
            border-radius: 26px;
            width: 100%;
            max-width: calc(100% - 54px);
            height: 100%;
            max-height: calc(100% - 59px);
            object-fit: cover;
          }
        }
      }
    }
    &__rank {
      display: block;
      font-size: 65px;
      line-height: calc(88/65);
      color: #E62513;
      font-weight: 700;
      &--title {
        display: block;
        font-family: 'DM Serif Display', serif;
        font-size: 42px;
        line-height: calc(52/42);
        color: #060F24;
      }
    }
    &__information {
      &.content {
        font-size: 18px;
        line-height: calc(26/18);
        color: #1A1A1A;
        strong {
          color: #060F24;
          display: block;
          span {
            color: #EC4C53;
          }
          &:after {
            content: '';
            width: 186px;
            height: 1px;
            background: #EE6152;
            margin: 26px 0;
            display: block;
          }
        }
      }
    }
    &__details {
      text-align: center;
      .domain-link {
        color: #013FA3;
        font-size: 14px;
        line-height: calc(19/14);
        letter-spacing: 1.4px;
        font-weight: 700;
        text-transform: uppercase;
        display: block;
        text-decoration: none;
        margin: 0;
      }
    }
    &__container {
      p {
        text-align: left;
        font-weight: 400;
      }
      &--top {
        display: grid;
        grid-template-columns: 378px 1fr;
        column-gap: 109px;
        padding: 79px 109px 51px;
      }
      &--bottom {
        display: grid;
        grid-template-columns: 378px 1fr;
        background: #EEF5FD;
        column-gap: 65px;
        padding: 71px 109px 109px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .power-rankings-detail {
    .action-links {
      position: relative;
    }
    .page-header {
      flex-direction: column;
    }
    .h1 {
      margin: 2.428rem 0 3rem;
    }
  }
}

@media screen and (max-width: 767px) {
  .power-rankings {
    &-detail {
      .page-heading {
        &__wrapper {
          &:before {
            clip-path: ellipse(150% 100% at 50% 0%);
          }
          .page {
            &-heading {
              &.center-heading {
                h1 {
                  font-size: 34px;
                  max-width: 380px;
                  margin: 0 auto;
                }
              }
            }
            &-subheading {
              font-size: 12px;
              max-width: 260px;
              margin: 12px auto 0;
            }
          }
          .google-logo {
            max-width: 80px;
          }
          .blurb {
            font-size: 16px;
            max-width: 270px;
          }
        }
      }
      .firm-spotlight {
        max-width: 100%;
        margin-top: -290px;
        .firm-spotlight {
          &__details {
            order: 1;
            .domain-link {
              font-size: 12px;
            }
          }
          &__rank {
            font-size: 45px;
            &--title {
              font-size: 28px;
            }
          }
          &__container {
            &--top {
              grid-template-columns: 1fr;
              padding: 60px 1rem;
            }
            &--bottom {
              grid-template-columns: 1fr;
              padding: 60px 2rem;
            }
          }
          &__information {
            &.content {
              font-size: 16px;
            }
          }
          &__thumbnail {
            margin-top: 20px;
            order: 2;
            display: flex;
            justify-content: center;
            &--wrapper {
              position: relative;
              max-width: 380px;
              img {
                &:first-of-type {
                  max-width: calc(100% - 40px);
                  max-height: calc(100% - 45px);
                }
              }
            }
          }
        }
      }
      .container {
        .h1 {
          &.text-centered {
            margin: 80px auto 0;
            font-size: 30px;
            padding: 0 2rem;
          }
        }
        .show-agency-toggle {
          max-width: calc(100% - 4rem);
        }
      }
      table {
        max-width: calc(100% - 4rem);
        font-size: 15px;
        th {
          font-size: 16px;
        }
      }
      .action-links {
        padding-bottom: 72px;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1170px) {
  .power-rankings {
    &-detail {
      .page-heading {
        &__wrapper {
          .page {
            &-heading {
              max-width: 615px;
              h1 {
                font-size: 55px;
              }
            }
            &-subheading {
              font-size: 14px;
              &::after {
                margin: 35px auto 30px;
              }
            }
          }
          .google-logo {
            max-width: 110px;
          }
          .blurb {
            font-size: 16px;
          }
        }
      }
      .container {
        .h1 {
          &.text-centered {
            margin: 100px auto 0;
            max-width: 510px;
          }
        }
        .show-agency-toggle {
          max-width: calc(100% - 4rem);
        }
      }
      table {
        max-width: calc(100% - 4rem);
      }
    }
  }

  .firm-spotlight {
    .firm-spotlight {
      &__container {
        &--top {
          grid-template-columns: 328px 1fr;
          column-gap: 0;
          padding: 65px 2rem 50px;
        }
        &--bottom {
          column-gap: 0;
          padding: 71px 2rem 109px;
        }
      }
      &__details {
        .domain-link {
          font-size: 12px;
        }
      }
      &__rank {
        font-size: 50px;
        &--title {
          font-size: 32px;
        }
      }
      &__spinner {
        top: 24px;
        left: 22px;
        bottom: 24px;
        right: 22px;
      }
      &__thumbnail {
        margin-top: -23px;
        &--wrapper {
          img {
            &:first-of-type {
              max-width: calc(100% - 45px);
              max-height: calc(100% - 53px);
            }
          }
        }
      }
      &__information {
        &.content {
          font-size: 16px;
        }
      }
    }
  }
}
</style>
