export const footer = {
  namespaced: true,
  state: {
    userInfo: {},
    userWhitelist: [
      'Alex Morris',
      'Derek Sanders'
    ],
    loading: false
  },
  mutations: {
    setUserInfo (state, userInfo) {
      state.userInfo = userInfo
    },
    setLoading (state, loading) {
      state.loading = loading
    }
  },
  actions: {
    setUserInfo ({ commit }, userInfo) {
      commit('setUserInfo', userInfo)
    },
    setLoading ({ commit }, loading) {
      commit('setLoading', loading)
    }
  },
  getters: {
    getUserInfo (state) {
      return state.userInfo
    },
    getUserWhitelist (state) {
      return state.userWhitelist
    },
    getLoading (state) {
      return state.loading
    }
  }
}
