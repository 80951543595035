<template>
  <section
    :class="[customClasses]">
    <div class="container">
      <div class="page-header">
        <div
          class="page-heading"
          v-if="showHeading"
          :class="{
            'center-heading': centerHeading
          }">
          <slot v-if="hasAction" name="action-slot"></slot>
          <div v-if="image && showImage" class="logo-container">
            <img :src="image" />
            <h1>
              {{ title }}
            </h1>
          </div>
          <h1 v-else>
            {{ title }}
          </h1>
        </div>
        <slot name="page-heading" v-else></slot>
        <slot name="page-header"></slot>
      </div>
      <slot></slot>
    </div>
  </section>
</template>

<script>
export default {
  name: 'BaseLayout',
  props: {
    title: {
      type: String,
      default: 'A.R.C Score'
    },
    image: {
      type: String,
      default: ''
    },
    centerHeading: {
      type: Boolean,
      default: false
    },
    showImage: {
      type: Boolean,
      default: false
    },
    customClasses: Array,
    showHeading: {
      type: Boolean,
      default: true
    },
    hasAction: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
section {
  padding: 4rem 2rem;
  background: #fbfdff;
  min-height: 100vh;
  position: relative;
  z-index: 1;
  .container {
    max-width: 1500px;
    margin: 0 auto;
    img {
      margin: 0 auto 5px;
      display: block;
      max-width: 200px;
    }
    p {
      text-align: center;
      margin-top: 20px;
    }
    .jumbo {
      font-size: 55px;
      text-align: center;
      font-weight: 700;
    }
    .text-centered {
      text-align: center !important;
    }
    h1,
    .h1 {
      font-size: 40px;
      text-align: left;
      font-weight: 700;
      font-family: 'DM Serif Display', serif;
      color: #0158E3;
      display: block;
    }
    h2 {
      font-size: 30px;
      font-weight: 700;
    }
    .subtitle {
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 15px;
      text-transform: capitalize;
    }
    h3 {
      text-align: center;
      font-size: 15px;
      font-weight: 600;
      margin-bottom: 20px;
    }
    .page {
      &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 35px;
      }
      &-heading {
        &.center-heading {
          width: 100%;
          h1 {
            text-align: center;
          }
        }

        & > .logo-container {
          img {
            margin: 0;
            vertical-align: baseline;
            display: inline-block;
          }
          h1 {
            display: inline;
          }
        }
      }
    }
  }
}
</style>
