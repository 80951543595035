<template>
  <fragment>
    <main class="power-rankings">
      <router-view></router-view>
    </main>
    <PowerRankingsFooter
      v-if="showFooter" />
  </fragment>
</template>

<script>
import PowerRankingsFooter from '@/components/global/power-rankings/PowerRankingsFooter'

export default {
  name: 'PowerRankingsView',
  components: {
    PowerRankingsFooter
  },
  computed: {
    showFooter () {
      return this.$route.matched.some(({ name }) => name === 'power-rankings-detail') ||
        this.$route.matched.some(({ name }) => name === 'power-rankings-overview')
    }
  }
}
</script>

<style>

</style>
