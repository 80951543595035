<template>
  <div
    class="action-links"
    :class="[
      textAlign ? `text-${textAlign}`: '',
      {
        'no-margin': noMargin,
      }
    ]">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ActionLinks',
  props: {
    textAlign: String,
    noMargin: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
div {
  &.action-links {
    text-align: center;
    margin-bottom: 25px;
    margin-top: 25px;
    display: flex;
    &.text {
      &-right {
        justify-content: flex-end !important;
      }
      &-center {
        justify-content: center;
      }
      &-left {
        justify-content: flex-start;
      }
    }
    &.no-margin {
      margin: 0;
    }
    button:not(.is-fancy) {
      margin-right: 10px;
      background: none;
      padding: 0;
      border: 0;
      &:hover {
        background: none;
      }
      a, span {
        color: #3b80e8;
        text-decoration: underline;
        cursor: pointer;
        &:hover {
          color: darken(#3b80e8, 10%);
          background: none;
        }
      }
    }
  }
}
</style>
