<template>
  <div class="file">
    <span class="file-title">
      {{ title }}
    </span>
    <span class="file-type">
      {{ type }}
    </span>
    <span class="file-date">
      {{ date | moment('MM/DD/YYYY') }}
    </span>
    <div class="file-actions">
      <action-links>
        <Button
          :hasIcon="true"
          :hasIconOnly="true"
          icon="download"
          :isInversed="true"
          type="button"
          @click.prevent="downloadFile" />
        <Button
          :hasIcon="true"
          :hasIconOnly="true"
          icon="times"
          :isInversed="true"
          type="button"
          @click="deleteFile" />
      </action-links>
    </div>
  </div>
</template>

<script>
import ActionLinks from '@/components/misc/ActionLinks'
import Button from '@/components/form/Button'

export default {
  name: 'File',
  components: {
    Button,
    'action-links': ActionLinks
  },
  props: {
    title: String,
    url: String,
    date: String,
    type: String,
    id: [String, Number]
  },
  methods: {
    downloadFile () {
      this.$emit('download:file')
    },
    deleteFile () {
      this.$emit('delete:file')
    }
  }
}
</script>

<style lang="scss">
.file {
  border: 1px solid #e4edf2;
  background: #fff;
  border-radius: 10px 10px 0 0;
  padding: 15px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  .action-links {
    margin: 0;
    button {
      font-size: 12px;
      width: 18px;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:last-child {
        margin: 0;
      }
    }
  }
  &:not(:first-child, :last-child) {
    border-radius: 0;
    border-bottom: 0;
  }
  &:last-child {
    border-radius: 0 0 10px 10px;
  }
  &:nth-of-type(2) {
    border-top: 0;
  }
  &:only-child {
    border-radius: 10px;
  }
}
</style>
