<template>
  <BaseLayout
    title="Website & Agency"
    :customClasses="['web-agency']">
    <Form
      @submit.prevent="filterAndSubmit">
      <strong>Filters</strong>
      <Input
        :hasLabel="true"
        labelText="Website"
        placeholder="Website"
        v-model="form.website"
        :disabled="loading" />
      <!-- <Input
        placeholder="Marketing Company"
        v-model="form.marketingCompany"
        :disabled="loading" /> -->
      <Dropdown
        :options="filteredCompanies"
        customOption="name"
        dropdownPlaceholder="Marketing Company"
        :inputHasLabel="true"
        inputLabelText="Marketing Company"
        :isSearch="true"
        @input="form.marketingCompany = $event"
        @select="form.marketingCompany = $event"
        ref="agencyDropdown"
        :isDisabled="loading" />
      <Dropdown
        :options="form.tierOptions"
        :hasIcon="true"
        :inputHasLabel="true"
        inputLabelText="Tier"
        ref="filterDropdown"
        dropdownPlaceholder="Tier"
        :isDisabled="loading" />
        <Dropdown
        dropdownPlaceholder="Results per page"
        :hasIcon="true"
        :options="pagination.dropdownOptions"
        @select="pagination.per_page = $event"
        :inputVal="pagination.per_page"
        :isDisabled="loading" />
      <Button
        type="submit"
        text="Apply filters"
        :isDisabled="loading" />
    </Form>
    <action-links
        textAlign="right"
        v-if="!loading">
      <Button
        @click="clearFilters"
        text="Clear filters" />
    </action-links>
    <Loading
      v-show="loading"
      text="Loading data" />
    <Table
      :loading="loading"
      v-if="sortedResults.length > 0">
      <template slot="head">
        <t-row>
          <t-heading
            @click="sortBy('website')"
            :class="sortedClass('website')">
            Website
          </t-heading>
          <t-heading
            @click="sortBy('marketing_company')"
            :class="sortedClass('marketing_company')">
            Marketing Company
          </t-heading>
          <t-heading
            @click="sortBy('last_checked_at')"
            :class="sortedClass('last_checked_at')">
            Last Date Checked
          </t-heading>
          <t-heading>Rank History?</t-heading>
          <t-heading>View</t-heading>
        </t-row>
      </template>
      <template slot="body">
        <t-row
          v-for="(result, i) in sortedResults"
          :key="i">
          <t-cell>
            <a
              :href="result.website"
              target="_blank"
              rel="noopener">
              {{ result.website }}
            </a>
          </t-cell>
          <t-cell>{{ result.marketing_company }}</t-cell>
          <t-cell>{{ result.last_checked_at | moment('M/DD/YYYY') }}</t-cell>
          <t-cell>{{ result.rank_history_url ? 'Yes' : 'No' }}</t-cell>
          <t-cell>
            <Button
              :hasLink="true"
              :btnLink="{
                name: 'site-history',
                query: {
                  domain: formatDomain(result.website)
                }
              }"
              :isView="true" />
          </t-cell>
        </t-row>
      </template>
    </Table>
    <p v-else-if="!loading && sortedResults.length === 0">
      No results. Try changing or clearing filters.
    </p>
    <Pagination
      v-if="!loading"
      :totalItems="domains.total"
      :perPage="domains.per_page"
      :currentPage="domains.page"
      :nextNum="domains.next_num"
      :prevNum="domains.prev_num"
      @initPagination="$event(pagination)"
      @pageChanged="changePage($event)" />
  </BaseLayout>
</template>

<script>
import Table from '@/components/table/Table'
import TableRow from '@/components/table/TableRow'
import TableDataCell from '@/components/table/TableDataCell'
import TableHeading from '@/components/table/TableHeading'
import Input from '@/components/form/Input'
import Dropdown from '@/components/form/Dropdown'
import Button from '@/components/form/Button'
import Form from '@/components/form/Form'
import BaseLayout from '@/views/BaseLayout'
import Loading from '@/components/misc/Loading'
import ActionLinks from '@/components/misc/ActionLinks'
import Pagination from '@/components/misc/Pagination'
import { mapState } from 'vuex'
import _ from 'lodash'

export default {
  name: 'ScoreWebAgency',
  components: {
    Table,
    Input,
    Form,
    Button,
    Dropdown,
    Loading,
    Pagination,
    BaseLayout,
    't-row': TableRow,
    't-cell': TableDataCell,
    't-heading': TableHeading,
    'action-links': ActionLinks
  },
  data () {
    return {
      loading: false,
      form: {
        website: '',
        marketingCompany: '',
        tierOptions: ['None', 1, 2, 3, 4, 5]
      },
      domains: [],
      sort: {
        key: 'last_checked_at',
        isAsc: false
      },
      pagination: {
        currentPage: 1,
        per_page: 100,
        dropdownOptions: [20, 50, 100, 250, 500, 1000]
      }
    }
  },
  computed: {
    ...mapState({
      formData: state => state.form
    }),

    sortedResults () {
      const list = this.domains.domains ? this.domains.domains : []
      if (this.sort.key) {
        list.sort((a, b) => {
          a = a[this.sort.key]
          b = b[this.sort.key]
          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
        })
      }
      return list
    },

    filteredCompanies () {
      return this.searchMarketingList(this.form.marketingCompany, this.formData.marketingCompanies).slice(0, 100)
    }
  },
  mounted () {
    this.setQueryParams()
    this.getDomains()
  },
  methods: {
    sortedClass (key) {
      return this.sort.key === key
        ? `sorted ${this.sort.isAsc ? 'asc' : 'desc'}`
        : ''
    },

    sortBy (key) {
      this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false
      this.sort.key = key
    },

    getDomains () {
      this.loading = true
      this.$arc.get('domains', {
        params: {
          ...(this.form.website ? { domain_name_like: this.form.website } : {}),
          ...(this.form.marketingCompany ? { marketing_company_name_like: this.form.marketingCompany } : {}),
          ...(this.pagination.currentPage ? { page: this.pagination.currentPage } : {}),
          ...(this.pagination.per_page ? { per_page: this.pagination.per_page } : {})
        }
      })
        .then(res => {
          this.domains = res.data
          this.loading = false
        })
        .catch(err => {
          console.error(err)
          this.loading = false
        })
    },

    setQueryParams () {
      if ('website' in this.$route.query && this.$route.query.website) {
        this.form.website = this.$route.query.website
      }
      if ('agency' in this.$route.query && this.$route.query.agency) {
        this.form.marketingCompany = this.$route.query.agency
        this.$refs.agencyDropdown.select(this.form.marketingCompany)
      }
    },

    filterAndSubmit () {
      const query = {}
      if (this.form.website) {
        query.website = this.form.website
      }

      if (this.form.marketingCompany) {
        query.agency = this.form.marketingCompany
      }

      // handle more query parameters here ...
      // Avoid "Error: Avoided redundant navigation to current location"
      // https://stackoverflow.com/questions/62462276
      if (!_.isEqual(this.$route.query, query)) {
        this.$router.push({ name: this.$route.name, query: query })
      }
      this.pagination.currentPage = null
      this.getDomains()
    },

    updatePerPage (e) {
      this.pagination.perPage = e
      this.getDomains()
    },

    changePage (e) {
      this.pagination.currentPage = e
      this.getDomains()
    },

    formatDomain (domain) {
      return domain.toString().includes('https://www.')
        ? domain.toString().replace('https://www.', '')
        : domain.toString().replace('https://', '')
    },

    formatSlug (completedAt, location, practiceArea, taskId) {
      const completed = this.$moment(completedAt).format('M-DD-YYYY')
      const newLocation = location.toLowerCase().replace(', ', '-').replace(' ', '-')
      const area = practiceArea.toLowerCase().replace(' ', '-')
      return `${newLocation}/${area}/${completed}?id=${taskId}`
    },

    clearFilters () {
      this.$refs.filterDropdown.selected = ''
      this.form.website = ''
      this.form.marketingCompany = ''
      this.getDomains()
    }
  }
}
</script>

<style lang="scss">
.web-agency {
  form {
    align-items: flex-end;
    margin-bottom: 30px;
    justify-content: space-between;
    > div {
      flex-basis: calc((100% / 3) - 85px);
    }
    strong {
      margin-right: 15px;
      margin-bottom: 1rem;
    }
  }
}
</style>
