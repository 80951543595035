<template>
  <table
    v-if="!loading"
    :class="{
      'responsive': isResponsive
    }">
    <div
      class="table-header"
      v-if="hasHeader">
      <slot name="header"></slot>
    </div>
    <thead v-if="headings">
      <t-row>
        <t-heading
          v-for="(cell, i) in headings"
          :key="i">
          {{ cell }}
        </t-heading>
      </t-row>
    </thead>
    <thead v-else>
      <slot name="head"></slot>
    </thead>
    <tbody v-if="data">
      <t-row
        v-for="(row, i) in data"
        :key="i">
        <t-cell
          v-for="(item, i) in row"
          :key="i">
          {{ item }}
        </t-cell>
      </t-row>
    </tbody>
    <tbody v-else>
      <slot name="body"></slot>
    </tbody>
  </table>
</template>

<script>
import TableRow from './TableRow'
import TableHeading from './TableHeading'
import TableDataCell from './TableDataCell'

export default {
  name: 'Table',
  components: {
    't-row': TableRow,
    't-heading': TableHeading,
    't-cell': TableDataCell
  },
  props: {
    headings: Array,
    data: [Array, Object],
    loading: Boolean,
    hasHeader: Boolean,
    isResponsive: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    window.addEventListener('scroll', function () {
      var els = document.querySelectorAll('thead')
      els.forEach(el => {
        if (el.getBoundingClientRect().top < (el.offsetHeight * -1 + el.offsetHeight)) {
          el.classList.add('fixed')
        } else {
          el.classList.remove('fixed')
        }
      })
    })
  }
}
</script>

<style lang="scss">
table {
  width: 100%;
  position: relative;
  .table-header {
    position: absolute;
    bottom: 100%;
    height: 90px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

thead {
  position: relative;
  transform: scale(1);
  &:before {
    content: '';
    position: absolute;
    top: -5px;
    bottom: -5px;
    left: -5px;
    right: -5px;
    background: #fff;
    z-index: -1;
    border-radius: 14px 14px 0 0;
    border: 1px solid #E0E9EF;
  }
  th {
    padding: calc(1.25rem - 10px) 1.25rem 1.25rem;
  }
}

tbody {
  position: relative;
  transform: scale(1);
  &:before {
    content: '';
    position: absolute;
    top: -5px;
    bottom: -5px;
    left: -5px;
    right: -5px;
    background: #fff;
    z-index: -1;
    border-radius: 0 0 14px 14px;
    border: 1px solid #E0E9EF;
  }
  tr {
    position: relative;
    &.is-selected {
      background: #0158E314;
    }

    td {
      .table-action {
        position: relative;
        display: block;
        &-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 4px;
          height: 20px;
          cursor: pointer;
          span {
            display: inline-flex;
            width: 4px;
            height: 4px;
            border-radius: 100%;
            background: #cad4da;
          }

          + ul {
            display: none;
            position: absolute;
            top: calc(100% + 10px);
            left: 50%;
            transform: translateX(-50%);
            background: #fff;
            border: 1px solid #E0E9EF;
            border-radius: 5px;
            z-index: 1;

            &.show {
              display: block;
            }

            li {
              padding: .75rem 1.5rem;
              cursor: pointer;

              &:not(:last-child) {
                border-bottom: 1px solid #E0E9EF;
              }
            }
          }
        }
      }
    }
  }
}

thead {
  &.fixed {
    // position: fixed;
    // top: 15px;
    // z-index: 1;
    // background: #fff;
    // width: unset;
    // left: calc(2rem - 5px);
    // right: calc(2rem - 5px);
    // max-width: inherit;
    // box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    // tr {
    //   width: 100%;
    //   display: flex;
    //   justify-content: space-between;
    // }
    th {
      background: #fff;
      border-top: 1px solid #E0E9EF;
      border-bottom: 1px solid #E0E9EF;
    }
  }

  th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    color: #424345;
    font-size: 17px;
    font-weight: 700;
  }
}

@media screen and (max-width: 767px) {
  table {
    &.responsive {
      display: block;
      thead,
      tbody,
      th,
      td,
      tr {
        display: block;
      }
      thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
      }
      tr {
        margin: 0;
        border: none !important;
      }
      td {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: none;
        border-bottom: 1px solid #e4edf2;
        border-top: none !important;
        position: relative;
      }
      td {
        height: unset;
        max-width: 100%;
        text-align: center;
      }
      tbody tr td:first-of-type {
        border-top: none;
      }
      td:not([align]), th:not([align]) {
        text-align: center;
      }
      td:before {
        content: attr(data-content);
        font-weight: 700;
      }
   }
  }
}
</style>
