<template>
  <th
    @click="$emit('click', $event)"
    :class="{
      'text-center': textCentered,
      'no-padding': noPadding
    }">
    <slot></slot>
    <span
      class="th-tip"
      v-if="hasTip"
      v-html="thTip"></span>
  </th>
</template>

<script>
export default {
  name: 'TableHeading',
  props: {
    hasTip: {
      type: Boolean,
      default: false
    },
    thTip: String,
    isSortable: {
      type: Boolean,
      default: false
    },
    sortKey: String,
    textCentered: Boolean,
    noPadding: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
th {
  padding: 1.25rem 0.75rem;
  font-size: 16px;
  font-weight: 800;
  cursor: pointer;
  .th-tip {
    display: block;
    font-size: 11px;
    color: #798ea3;
  }
  &.no-padding {
    padding: 0;
  }
  &.text-center {
    text-align: center;
  }
  &.sorted {
    &.asc:after {
      display: inline-block;
      content: '▼';
      font-size: 9px;
    }
    &.desc:after {
      display: inline-block;
      content: '▲';
      font-size: 9px;
    }
  }
}
</style>
