<template>
  <BaseLayout>
    <div class="404-notfound">
      <h1>404</h1>
      <h2>Page not found</h2>
      <p>
        The page you are looking for might have been removed, had its name
        changed or is temporarily unavailable.
      </p>
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/views/BaseLayout'

export default {
  components: {
    BaseLayout
  }
}
</script>

<style>

</style>
