import Vue from 'vue'
import Vuex from 'vuex'
import { auth } from './modules/auth'
import { table } from './modules/table'
import { form } from './modules/form'
import { footer } from './modules/footer'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    table,
    form,
    footer
  },
  plugins: [createPersistedState({
    paths: ['auth', 'form', 'table']
  })]
})
