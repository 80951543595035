<template>
  <div id="app">
    <Nav />
    <router-view v-if="!formData.loadingData" />
    <Loading
      id="app-loading"
      v-if="$auth.isAuthenticated && formData.loadingData"
      text="loading app data" />
  </div>
</template>

<script>
import Nav from '@/components/nav/Nav'
import { mapGetters, mapState } from 'vuex'
import Loading from '@/components/misc/Loading'

export default {
  components: {
    Nav,
    Loading
  },
  mounted () {
    this.handleData()
  },
  updated () {
    this.handleData()
  },
  computed: {
    ...mapGetters({
      profile: 'auth/getProfile'
    }),

    ...mapState({
      formData: state => state.form
    })
  },
  methods: {
    handleData () {
      this.$store.dispatch('form/setData')

      if (this.$auth.isAuthenticated && this.$auth.user) {
        this.handleCurrentUser()
      }
    },

    async handleCurrentUser () {
      try {
        const { data: user } = await this.$arc.get('users/me')
        this.$store.dispatch('auth/setProfile', user)

        if (user && user.profile_picture_url !== null) {
          this.$store.dispatch('auth/setProfilePicture', user.profile_picture_url)
        }

        if (user && user.email === null) {
          try {
            const emailResp = await this.$arc.patch('users/me', {
              email: this.$auth.user.email
            })
            this.$store.dispatch('auth/setProfile', emailResp.data)
          } catch (err) {
            console.error(err)
          }
        }
      } catch (err) {
        console.error(err)
      }
    }
  }
}
</script>

<style lang="scss">
@import 'assets/scss/all';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

#app {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #212741;
  #app-loading {
    text-align: center;
    margin-top: 60px;
  }
  > p {
    text-align: center;
    margin-top: 60px;
    a {
      color: #0158E3;
      cursor: pointer;
    }
  }
}
#loop-companion-mv3 {
  display: none !important;
}
</style>
