<template>
  <div class="notes">
    <div class="notes-header">
      <h2>Notes</h2>
      <action-links>
        <Button
          text="add new"
          @click="openAdding" />
      </action-links>
    </div>
    <div
      class="notes-create"
      v-if="adding">
      <span
        class="note-cancel"
        @click="closeAdding">
        <font-awesome-icon
          icon="times" />
      </span>
      <span
        class="note-create"
        @click="createNote">
        <font-awesome-icon
          icon="check" />
      </span>
      <textarea
        placeholder="New note"
        v-model="content">
      </textarea>
    </div>
    <div class="notes-container">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import ActionLinks from '@/components/misc/ActionLinks'
import Button from '@/components/form/Button'

export default {
  name: 'Notes',
  components: {
    Button,
    'action-links': ActionLinks
  },
  data () {
    return {
      adding: false,
      content: ''
    }
  },
  methods: {
    openAdding () {
      this.adding = true
    },
    closeAdding () {
      this.adding = false
      this.content = ''
    },
    createNote () {
      this.adding = false
      if (this.content !== '') {
        this.$emit('create:note', this.content)
      }
      this.closeAdding()
    }
  }
}
</script>

<style lang="scss">
.notes {
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .action-links {
      margin: 0;
      button {
        &:last-child {
          margin: 0;
        }
      }
    }
  }
  &-create {
    position: relative;
    textarea {
      border: 1px solid #e4edf2;
      width: 100%;
      border-radius: 10px;
      resize: none;
      padding: 25px;
      background: #fff;
      margin-bottom: 15px;
      font-family: "Poppins", sans-serif;
      color: #212741;
      font-size: 16px;
      &:focus {
        outline: 0;
      }
    }
  }
}
</style>
