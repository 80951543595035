<template>
  <div
    class="btn-group"
    :class="[
      btnAlign ? `align-${btnAlign}` : ''
    ]">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ButtonGroup',
  props: {
    btnAlign: String
  }
}
</script>

<style lang="scss">
.btn-group {
  display: flex;
  &.align {
    &-right {
      justify-content: flex-end;
    }
    &-left {
      justify-content: flex-start;
    }
    &-center {
      justify-content: center;
    }
  }
  button {
    margin: 0 5px;
    &:last-child {
      margin-right: 0;
    }
    &:only-child {
      margin: 0;
    }
  }
}
</style>
