<template>
  <div id="chart"
    :class="{
      'hide-chart': hideChart
    }">
    <action-links
      textAlign="right">
      <Button
        :text="hideChart ? 'Show Chart' : 'Hide Chart'"
        @click="toggleChart" />
    </action-links>
    <highchart
      ref="chart"
      :width="width"
      :height="height"
      :options="options">
    </highchart>
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue'
import ActionLinks from '@/components/misc/ActionLinks'
import Button from '@/components/form/Button'

export default {
  name: 'Chart',
  components: {
    highchart: Chart,
    'action-links': ActionLinks,
    Button
  },
  props: {
    type: String,
    options: Object,
    width: [Number, String],
    height: [Number, String]
  },
  data () {
    return {
      hideChart: false
    }
  },
  methods: {
    toggleChart () {
      this.hideChart = !this.hideChart
    }
  }
}
</script>

<style lang="scss">
#chart {
  margin: 20px 0;
  transition: all .3s ease-in-out;
  &.hide-chart {
    > div:nth-child(2) {
      height: 0;
    }
  }
  .highcharts-title {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    color: #212741 !important;
    fill: #212741 !important;
  }
  .highcharts-subtitle {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: #212741 !important;
    fill: #212741 !important;
    opacity: .7;
  }
  .highcharts-legend-item {
    text {
      text-transform: capitalize;
    }
  }
}
</style>
