import { formatLocation } from '../utils/format'
import store from '../store/index'
import locStates from '../data/us_states.json'
import locCities from '../data/us_locations.json'
import _ from 'lodash'

const mixins = {
  data () {
    return {
      TEN_MINS: 1000 * 60 * 10
    }
  },
  computed: {
  },
  methods: {
    isBlacklisted (domain) {
      const blacklistedDomains = store.getters['form/allBlacklistedDomains']
      const domainArray = domain.split('.')

      domainArray.map(item => {
        if (item.split('//').length > 1) domainArray[0] = item.split('//')[1]
      })

      if (domainArray[0].includes('www')) domainArray.splice(0, 1)

      domainArray.forEach(item => {
        if (item.includes('/')) {
          domainArray[domainArray.indexOf(item)] = item.split('/')[0]
        }
      })

      domain = domainArray.length > 2
        ? domainArray[0] + '.' + domainArray[1] + '.' + domainArray[2]
        : domainArray[0] + '.' + domainArray[1]

      return blacklistedDomains.some(bl => bl === domain)
    },
    capitalizeString (string) {
      return string ? string.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase()))) : ''
    },
    formatLocation (location) {
      return formatLocation(location)
    },
    formatContact (contact, type) {
      return type === 'email'
        ? `mailto:${contact}`
        : `tel:+1${contact.replace(/[- ) (]/g, '')}`
    },
    searchList (term, list) {
      return (term === '')
        ? list.slice(0, 100)
        : list.filter(item => {
          return item
            .toLowerCase()
            .includes(term.toLowerCase())
        }).sort((a, b) => {
          if (a < b) { return -1 }
          if (a > b) { return 1 }
          return 0
        })
    },
    searchLocationList (term, list) {
      return (term === '')
        ? list.slice(0, 100)
        : list.filter(location => {
          return location
            .toLowerCase()
            .includes(term.toLowerCase())
        }).sort((a, b) => {
          if (a < b) { return -1 }
          if (a > b) { return 1 }
          return 0
        })
    },
    searchUsLocationList (term) {
      const usLocations = store.getters['form/allUsLocations']
      return usLocations.filter(location => location.toLowerCase().includes(term.toLowerCase()))
    },
    searchStateList (term, list) {
      const filteredStates = []

      for (const [key, value] of Object.entries(locStates)) {
        if (key.toLocaleLowerCase().includes(term.toLocaleLowerCase()) || value.toLocaleLowerCase().includes(term.toLocaleLowerCase())) {
          filteredStates.push(value)
        }
      }

      return (term === '')
        ? list.slice(0, 100)
        : filteredStates.sort((a, b) => {
          if (a < b) { return -1 }
          if (a > b) { return 1 }
          return 0
        })
    },
    searchCityList (term1, city, list) {
      const filteredCities = []

      locCities.us_locations.forEach(location => {
        var locationArray = location ? location.name.split(',') : []
        let locationObject

        if (locationArray.length === 4) {
          locationObject = {
            city: locationArray[0] + ', ' + locationArray[1],
            state: locationArray[2],
            country: locationArray[3]
          }
        } else {
          locationObject = {
            city: locationArray[0],
            state: locationArray[1],
            country: locationArray[2]
          }
        }
        if (city === '' && term1 === '') {
          filteredCities.push(locationObject.city)
        }
        if (locStates[locationObject.state.trim()] === term1) {
          if (locationObject.city.toLowerCase().includes(city.toLowerCase())) {
            filteredCities.push(locationObject.city)
          }
        }
      })

      return filteredCities.sort((a, b) => {
        if (a < b) { return -1 }
        if (a > b) { return 1 }
        return 0
      }).slice(0, 100)
    },
    searchMarketingList (term, list) {
      return (term === '' || term === null || term.length <= 2)
        ? list.slice(0, 0)
        : list.filter(company => {
          return company.name
            .toLowerCase()
            .includes(term.toLowerCase())
        }).sort((a, b) => {
          if (a < b) { return -1 }
          if (a > b) { return 1 }
          return 0
        })
    },
    formatTaskSlug (completedAt, location, practiceArea, id) {
      const fCompleted = this.$moment(completedAt).format('M-DD-YYYY')
      const fLocation = this.formatLocation(location).toLowerCase().replace(', ', '-').replace(' ', '-')
      const fPracticeArea = practiceArea?.toLowerCase().replaceAll(' ', '-')
      return `${fLocation}/${fPracticeArea}/${fCompleted}?id=${id}`
    },
    formatDomainSlug (completedAt, location, practiceArea, domain, taskId) {
      const fCompleted = this.$moment(completedAt).format('M-DD-YYYY')
      const fLocation = this.formatLocation(location).toLowerCase().replace(', ', '-').replace(' ', '-')
      const fPracticeArea = practiceArea?.toLowerCase().replaceAll(' ', '-')
      return `${fLocation}/${fPracticeArea}/${fCompleted}?id=${taskId}&domain=${domain}`
    },
    getTaskSlug (completedAt, location, practiceArea, id, routeName = null) {
      const formattedTaskSlug = this.formatTaskSlug(completedAt, location, practiceArea, id)
      const route = this.$router.resolve({ name: routeName ?? 'scores', params: { slug: formattedTaskSlug } })
      return `${decodeURIComponent(route.resolved.path)}`
    },
    getDomainSlug (completedAt, location, practiceArea, domain, taskId, routeName = null) {
      const formattedDomainSlug = this.formatDomainSlug(completedAt, location, practiceArea, domain, taskId)
      const route = this.$router.resolve({ name: routeName ?? 'score', params: { slug: formattedDomainSlug } })
      return `${decodeURIComponent(route.resolved.path)}`
    },
    sortBy (key, asc) {
      store.dispatch('table/setSortKey', key, asc)
    },
    lowest (a, b) {
      if (a < b) {
        return parseFloat(a)
      } else if (a === b) {
        return 0
      } else {
        return parseFloat(b)
      }
    },
    copy (text) {
      this.$copyText(text)
        .then(e => {
          alert('link copied!')
        })
        .catch(err => {
          console.error(err)
        })
    },
    testKeyword (keyword, practiceArea) {
      const regex = new RegExp(`\\b${_.escapeRegExp(practiceArea.slice(0, -1))}s?\\b`, 'i')
      return regex.test(keyword) || _.includes(keyword.toLowerCase(), practiceArea.toLowerCase())
    },
    getPowerRank (data, createDomainSlug = false) {
      const filteredResults = data.results.filter(result => {
        const keywords = result.keywords || []
        return keywords.some(keyword => {
          return this.testKeyword(keyword.name, data.practice_area_name)
        })
      })

      const sortedResults = filteredResults.sort((a, b) => {
        const getMatchingKeyword = (keywords, practiceAreaName) => {
          const practiceAreaRegex = new RegExp(`\\b${_.escapeRegExp(practiceAreaName.slice(0, -1))}s?\\b`, 'i')
          return keywords.find(keyword => practiceAreaRegex.test(keyword.name) || _.includes(keyword.name.toLowerCase(), practiceAreaName.toLowerCase()))
        }
        const keywordA = getMatchingKeyword(a.keywords, data.practice_area_name)
        const keywordB = getMatchingKeyword(b.keywords, data.practice_area_name)
        return keywordA.rank - keywordB.rank
      })

      const newResultsArray = sortedResults.map(result => {
        const getMatchingKeyword = (keywords, practiceAreaName) => {
          const practiceAreaRegex = new RegExp(`\\b${_.escapeRegExp(practiceAreaName.slice(0, -1))}s?\\b`, 'i')
          return keywords.find(keyword => practiceAreaRegex.test(keyword.name) || _.includes(keyword.name.toLowerCase(), practiceAreaName.toLowerCase()))
        }
        const keyword = getMatchingKeyword(result.keywords, data.practice_area_name)
        return {
          arc_rank: result.arc_rank,
          arc_score: result.arc_score,
          campaign_focus: result.campaign_focus,
          domain: result.domain,
          domain_target_id: result.domain_target_id,
          id: result.id,
          keyword_rank: keyword.rank,
          keyword_type: keyword.type,
          keyword_url: keyword.url,
          marketing_company: result.marketing_company,
          results_excl_from_arc_score_calc: result.results_excl_from_arc_score_calc
        }
      })

      if (createDomainSlug) {
        newResultsArray.forEach((result, idx) => {
          result.domain_slug = this.getDomainSlug(
            data.completed_at,
            data.dfs_location_name,
            data.practice_area_name,
            result.domain,
            data.id,
            'power-rankings-detail'
          )
        })
      }

      return newResultsArray
    },
    cleanWebUrl (str) {
      return str ? str.replace(/^(https?:\/\/)?(www\.)?([^/]+)(\/.*)?$/i, '$3').trim() : ''
    },
    getFaviconString (domain) {
      return `https://s2.googleusercontent.com/s2/favicons?domain=${domain}&sz=256`
    },
    addSuffix (num) {
      if (num % 100 >= 11 && num % 100 <= 13) {
        return num + 'th'
      } else {
        switch (num % 10) {
          case 1: return num + 'st'
          case 2: return num + 'nd'
          case 3: return num + 'rd'
          default: return num + 'th'
        }
      }
    },
    getCompanyByEmail (email) {
      if (!email) return null

      const emailDomain = email.split('@')[1]

      switch (emailDomain) {
        case 'bluecorona.com':
          return 'Blue Corona'
        case 'rynoss.com':
          return 'Ryno'
        default:
          return 'iLawyerMarketing'
      }
    },
    getCompanyLogo (email) {
      const company = this.getCompanyByEmail(email)
      const isHealthEnv = process.env.NODE_ENV === 'health'

      switch (company) {
        case 'Blue Corona':
          return 'ryno_white_new.svg'
        case 'Ryno':
          return 'ryno_white_new.svg'
        case 'iLawyerMarketing':
          return isHealthEnv ? 'ihealth-white.svg' : 'ilawyer_logo_white.svg'
        default:
          return 'ilawyer_logo_white.svg'
      }
    }
  }
}

export default {
  install (Vue, options) {
    Vue.mixin(mixins)
  }
}
