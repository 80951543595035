import Vue from 'vue'
import VueRouter from 'vue-router'
import ScoreIndex from '../views/score/ScoreIndex'
import ScoreList from '../views/score/ScoreList'
import MainView from '../views/MainView'
import DomainDetail from '../views/domain/DomainDetail'
import DomainProfile from '../views/domain/DomainProfile'
import ScoreHistory from '../views/score/ScoreHistory'
import ScoreWebsite from '../views/score/ScoreWebsite'
import ScoreArchives from '../views/score/ScoreArchives'
import ScoreBulk from '../views/score/ScoreBulk'
import ScoreWebAgency from '../views/score/ScoreWebAgency'
import ScoreAgency from '../views/score/ScoreAgency'
import ScoreAgencyList from '../views/score/ScoreAgencyList'
import AgencyComparison from '../views/AgencyComparison'
import ScoreAgencyChanges from '../views/score/ScoreAgencyChanges'
import DomainStatusChanges from '../views/domain/DomainStatusChanges'
import DomainRedirects from '../views/domain/DomainRedirects'
import UserSettings from '../views/user/UserSettings'
import UserPracticeAreas from '../views/user/UserPracticeAreas'
import UserMarketingCompanies from '../views/user/UserMarketingCompanies'
import UserMarketTiers from '../views/user/UserMarketTiers'
import UserProfile from '../views/user/UserProfile'
import ScoreSchedule from '../views/score/ScoreSchedule'
import PowerRankings from '../views/power-rankings/PowerRankings'
import PowerRankingsView from '../views/power-rankings/PowerRankingsView'
import PowerRankingsList from '../views/power-rankings/PowerRankingsList'
import PowerRankingsDetail from '../views/power-rankings/PowerRankingsDetail'
import PowerRankingsArchives from '../views/power-rankings/PowerRankingsArchives'
import PowerRankingsBlacklist from '../views/power-rankings/PowerRankingsBlacklist'
import NotFound from '../views/NotFound'
import Login from '../views/auth/Login'
import Cookies from 'js-cookie'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    redirect: {
      name: 'agencies'
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '*',
    redirect: '/404'
  },
  {
    path: '/404',
    name: 'NotFound',
    component: NotFound
  },
  {
    path: '/search',
    component: MainView,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '/',
        name: 'search',
        component: ScoreIndex
      },
      {
        path: '/website',
        name: 'website',
        component: ScoreWebsite
      },
      {
        path: '/bulk',
        name: 'bulk',
        component: ScoreBulk
      }
    ]
  },
  {
    path: '/scores/:slug(.*)',
    name: 'scores',
    component: ScoreList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/score/:slug(.*)',
    name: 'score',
    component: DomainDetail,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/archives',
    name: 'archives',
    component: ScoreArchives,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/schedule',
    name: 'schedule',
    component: ScoreSchedule,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      guest: true
    }
  },
  {
    path: '/site-history',
    name: 'site-history',
    component: ScoreHistory,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/web-agency',
    name: 'web-agency',
    component: ScoreWebAgency,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/agencies',
    name: 'agencies',
    component: ScoreAgencyList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/agency',
    name: 'agency',
    component: ScoreAgency,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/agency-changes',
    name: 'agency-changes',
    component: ScoreAgencyChanges,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/status-changes',
    name: 'status-changes',
    component: DomainStatusChanges,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/domain-redirects',
    name: 'domain-redirects',
    component: DomainRedirects,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: DomainProfile,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/agency-comparison',
    name: 'agency-comparison',
    component: AgencyComparison,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/power-rankings',
    component: PowerRankingsView,
    children: [
      {
        path: '/',
        name: 'power-rankings',
        component: PowerRankings,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/power-rankings/overview/:slug(.*)',
        name: 'power-rankings-overview',
        component: PowerRankingsList,
        meta: {
          guest: true
        }
      },
      {
        path: '/power-rankings/detail/:slug(.*)',
        name: 'power-rankings-detail',
        component: PowerRankingsDetail,
        meta: {
          guest: true
        }
      },
      {
        path: '/power-rankings/archives',
        name: 'power-rankings-archives',
        component: PowerRankingsArchives,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/power-rankings/blacklist',
        name: 'power-rankings-blacklist',
        component: PowerRankingsBlacklist,
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: '/user/settings',
    name: 'user-settings',
    component: UserSettings,
    meta: {
      requiresAuth: true
    },
    redirect: {
      name: 'user-settings-profile'
    },
    children: [
      {
        path: '/user/settings/practiceareas',
        name: 'user-settings-practiceareas',
        component: UserPracticeAreas,
        meta: {
          requiresAuth: true,
          title: 'Manage Practice Areas'
        }
      },
      {
        path: '/user/settings/marketingcompanies',
        name: 'user-settings-marketingcompanies',
        component: UserMarketingCompanies,
        meta: {
          requiresAuth: true,
          title: 'Manage Marketing Companies'
        }
      },
      {
        path: '/user/settings/markettiers',
        name: 'user-settings-markettiers',
        component: UserMarketTiers,
        meta: {
          requiresAuth: true,
          title: 'Manage Market Tiers'
        }
      },
      {
        path: '/user/settings/profile',
        name: 'user-settings-profile',
        component: UserProfile,
        meta: {
          requiresAuth: true,
          title: 'Personal Information'
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// const checkAuth = (to, from, next) => {
//   const items = { ...localStorage }
//   const auth0Key = Object.keys(items).filter(s => s.includes('auth0spajs'))[0]

//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     if (!localStorage.getItem(auth0Key)) {
//       next({ path: '/login' })
//     }
//     next()
//   } else {
//     Cookies.set('_.arc_score.isGuest', true)
//     next({ path: to.path })
//   }
// }

const checkAuth = (to, from, next) => {
  const items = { ...localStorage }
  const auth0Key = Object.keys(items).filter(s => s.includes('auth0spajs'))[0]

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem(auth0Key)) {
      next()
      Cookies.set('_.arc_score.isGuest', false)
    } else {
      next({ path: '/login' })
    }
  } else if (to.matched.some(record => record.meta.guest)) {
    Cookies.set('_.arc_score.isGuest', true)
    next()
  }
}

router.beforeEach((to, from, next) => {
  if (!router.app.$auth.loading) {
    checkAuth(to, from, next)
  }

  router.app.$auth.$watch('loading', loading => {
    if (!loading) {
      checkAuth(to, from, next)
    }
  })
})

export default router
