<template>
  <div
    class="textarea-container"
    :class="{
      'has-label': hasLabel
    }">
    <label
      :for="textareaId"
      v-if="hasLabel"
      @click="$emit('labelClick', $event)">
      {{ labelText }}
      <span v-if="required">*</span>
    </label>
    <textarea
      class="textarea"
      :value="value"
      :readonly="readonly"
      :id="textareaId"
      :disabled="disabled"
      :placeholder="placeholder"
      autocomplete="off"
      :required="required"
      @input="$emit('input', $event.target.value)"
      @blur="$emit('blur', $event)"
      @focus="$emit('focus', $event)"
      @change="$emit('change', $event)"
      @click="$emit('click', $event)">
    </textarea>
    <span class="error-message">
      This field is required.
    </span>
  </div>
</template>

<script>
export default {
  name: 'Textarea',
  props: {
    textareaId: {
      type: String,
      default: ''
    },
    hasLabel: {
      type: Boolean,
      default: false
    },
    labelText: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    value: [String, Number],
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss">
.textarea {
  border: 1px solid #B6C3CB;
  background: #fff;
  border-radius: 3px;
  padding: 1rem 1.5rem;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  color: inherit;
  height: 100px;
  font-family: "Open Sans", sans-serif;
  &::placeholder {
    font-weight: 500;
    color: #b6bfc5;
    opacity: 1;
  }
  &:focus {
    outline: 0;
  }
  &-container {
    &.has-label {
      label {
        font-weight: 700;
        margin-bottom: 7px;
        display: block;
        span {
          color: red;
          font-weight: 700;
        }
      }
    }

    .error-message {
      display: none;
      color: red;
      font-weight: 700;
      font-size: 12px;
      position: absolute;
      bottom: -15px;
    }
    &.error {
      position: relative;
      textarea {
        border-color: red;
      }
      label {
        color: red;
      }
      .error-message {
        display: block;
      }
    }
  }
}
</style>
