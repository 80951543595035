<template>
  <div>
    <span
      class="spinner"
      v-if="isSpinner"></span>
    <p
      class="loading"
      v-else>
      {{ text }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    text: String,
    isSpinner: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: #2c3e50;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 #2c3e50, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 #2c3e50, 0.5em 0 0 #2c3e50;
  }
}
p {
  font-weight: 700;
  &.loading:after {
    content: ".";
    animation: dots 1s steps(5, end) infinite;
  }
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
span {
  &.spinner {
    display: inline-block;
    height: 40px;
    width: 40px;
    border-radius: 100%;
    border: 6px solid #e0e9ef;
    &:after {
      content: " ";
      display: block;
      width: 40px;
      height: 40px;
      margin: -6px;
      border-radius: 100%;
      border: 6px solid #0158E3;
      border-color: #0158E3 transparent transparent transparent;
      animation: spinner 1.2s linear infinite;
    }
  }
}
</style>
