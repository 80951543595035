<template>
  <tr>
    <slot></slot>
  </tr>
</template>

<script>
export default {
  name: 'TableRow'
}
</script>

<style lang="scss">
tbody {
  tr {
    background: #fff;
    &:nth-child(even) {
      background: #fbfdff;
    }

    &.highlighted {
      font-weight: bold;
      background-color: #0158E3;
      color: #fff;

      a {
        color: #97ccf9;
      }
    }
  }
}
</style>
