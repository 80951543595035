<template>
  <footer
    class="power-rankings__footer"
    v-show="!loading">
    <div class="container">
      <span class="h1 text-centered">
        Have Questions?
      </span>
      <p class="description">
        Reach out if you have any questions about this report or are
        interested in improving your Power Rankings and overall visibility on Google.
      </p>
      <div
        class="calendly-cta"
        v-if="userInfo && userInfo.calendly_link">
        <a
          :href="userInfo.calendly_link"
          class="button calendly-link is-fancy"
          target="_blank">
          Click to book a consultation with me
        </a>
      </div>
      <div class="contact-block" v-if="userInfo && userInfo.email">
        <img
          :src="userInfo.profile_picture_url"
          :alt="`${userInfo.name}`"
          v-if="userInfo && userInfo.profile_picture_url">
        <img
          :src="userInfo.avatar_sm_url"
          :alt="`${userInfo.first_name} ${userInfo.last_name}`"
          v-else-if="userInfo && userInfo.avatar_sm_url">
        <img
          src="@/assets/img/ilawyer_favicon.svg"
          v-else>
        <span class="contact-block__name">
          {{ userInfo.name ? userInfo.name : `${userInfo.first_name} ${userInfo.last_name}` }}
        </span>
        <p class="contact-block__title">
          {{ userInfo.job_title ? userInfo.job_title : userInfo.title }}
        </p>
        <div class="contact-block__numbers">
          <div v-if="userInfo && userInfo.phone">
            <span>Cell:</span>
            <a :href="formatContact(userInfo.phone)">
              {{ userInfo.phone }}
            </a>
          </div>
          <div v-if="userInfo && userInfo.email">
            <span>Email:</span>
            <a :href="formatContact(userInfo.email, 'email')">
              {{ userInfo.email }}
            </a>
          </div>
        </div>
      </div>
      <div
        class="info-block"
        :style="[!userInfo.email ? {
          'margin-top': '60px'
        } : {}]">
        <a v-if="companyInfo.url" :href="companyInfo.url">
          <img :src="require(`@/assets/img/${companyInfo.logo}`)" :alt="companyInfo.name">
        </a>
        <span v-if="companyInfo.tagline" class="info-block__title">
          {{ companyInfo.tagline }}
        </span>
        <p class="info-block__description">
          Since 2005, we've been helping {{ companyInfo.type }} generate more leads and cases
          through smarter marketing strategies. What makes us different is our history of success,
          our people and our proprietary software.
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PowerRankingsFooter',
  computed: {
    ...mapGetters({
      userInfo: 'footer/getUserInfo',
      userWhitelist: 'footer/getUserWhitelist',
      loading: 'footer/getLoading'
    }),

    companyInfo () {
      const company = this.getCompanyByEmail(this.userInfo.email)
      const logo = this.getCompanyLogo(this.userInfo.email)

      switch (company) {
        case 'Blue Corona':
          return {
            name: 'Blue Corona',
            logo: logo,
            tagline: 'Blue Corona specializes in digital marketing for home services.',
            url: 'https://bluecorona.com',
            type: 'home services'
          }
        case 'Ryno':
          return {
            name: 'Ryno',
            logo: logo,
            tagline: 'Ryno specializes in digital marketing for home services.',
            url: 'https://rynoss.com',
            type: 'home services'
          }
        case 'iLawyerMarketing':
          if (process.env.NODE_ENV === 'health') {
            return {
              name: 'iHealthMarketing',
              logo: logo,
              tagline: 'iHealth specializes in digital marketing for health care providers.',
              url: 'https://ihealthmarketing.com',
              type: 'health care providers'
            }
          } else {
            return {
              name: 'iLawyerMarketing',
              logo: logo,
              tagline: 'iLawyer specializes in digital marketing for law firms.',
              url: 'https://ilawyermarketing.com',
              type: 'law firms'
            }
          }
        default:
          return {
            name: 'iLawyerMarketing',
            logo: logo,
            tagline: 'iLawyer specializes in digital marketing for law firms.',
            url: 'https://ilawyermarketing.com',
            type: 'law firms'
          }
      }
    }
  }
}
</script>

<style lang="scss">
.power-rankings {
  &__footer {
    background: linear-gradient(to bottom, #0158E3, #012C72);
    padding: 140px 0 182px;
    // margin-top: 0;
    .container {
      max-width: 960px;
      margin: 0 auto;
      text-align: center;
      .h1 {
        font-family: 'DM Serif Display', serif;
        color: #fff;
        font-size: 69px;
        line-height: calc(82/69);
      }
      .description {
        color: #fff;
        font-family: 'Open Sans', sans-serif;
        max-width: 695px;
        margin: 11px auto 0;
        font-weight: 400;
        font-size: 20px;
        line-height: calc(30/20);
      }
      .calendly-cta {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
      }
      .calendly-link {
        background: #fbc617;
        color: #012C72;
        letter-spacing: 0.025em;
        text-decoration: none;

        &:hover {
          color: #fff;
          background: #012C72;
          &::before {
            opacity: 0;
          }
        }
      }
      .contact-block {
        &:after,
        &:before {
          content: '';
          display: block;
          width: 186px;
          height: 1px;
          background: #EE6152;
          margin: 61px auto 58px;
        }
        img {
          display: block;
          width: 70px;
          height: 70px;
          margin: 0 auto 12px;
          border-radius: 100%;
        }
        &__name {
          font-family: "DM Serif Display", serif;
          font-size: 40px;
          line-height: calc(49/40);
          color: #fff;
          display: block;
          margin-bottom: 5px;
        }
        &__title {
          font-size: 1rem;
          line-height: calc(22/16);
          color: #95BDFF;
          letter-spacing: 1.6px;
          text-transform: uppercase;
          margin-bottom: 38px;
        }
        &__numbers {
          font-size: 20px;
          line-height: calc(32/20);
          font-weight: 400;
          span {
            color: #95BDFF;
            font-weight: 700;
          }
          a {
            color: #fff;
            text-decoration: none;
          }
        }
      }
      .info-block {
        img {
          max-width: 273px;
          display: block;
          margin: 0 auto 26px;
        }
        &__title {
          font-size: 17px;
          line-height: calc(25/17);
          color: #95BDFF;
          display: block;
          margin-bottom: 25px;
          font-weight: 700;
        }
        &__description {
          font-size: 17px;
          line-height: calc(25/17);
          color: #fff;
          max-width: 695px;
          margin: 0 auto;
          font-weight: 400;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .power-rankings {
    &__footer {
      padding: 60px 0;
      .container {
        max-width: 400px;
        padding: 0 2rem;
        .h1 {
          font-size: 34px;
        }
        .description {
          max-width: 295px;
          font-size: 16px;
        }
        .contact-block {
          &:after,
          &:before {
            margin: 40px auto;
          }
          &__name {
            font-size: 32px;
          }
          &__title {
            font-size: 12px;
            max-width: 200px;
            margin: 0 auto 38px;
          }
          &__numbers {
            font-size: 16px;
          }
        }
        .info-block {
          img {
            max-width: 193px;
          }
          &__title {
            font-size: 14px;
          }
          &__description {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1170px) {
  .power-rankings__footer {
    padding: 80px 2rem;
    // margin-top: 120px;
    .container {
      .h1 {
        font-size: 55px;
      }
      .description {
        max-width: 495px;
        font-size: 16px;
      }
      .contact-block {
        &:after,
        &:before {
          margin: 45px auto;
        }
        &__name {
          font-size: 35px;
        }
        &__title {
          font-size: 14px;
        }
        &__numbers {
          font-size: 16px;
        }
      }
      .info-block {
        .info-block {
          &__title {
            font-size: 15px;
          }
          &__description {
            font-size: 15px;
            max-width: 565px;
          }
        }
        img {
          max-width: 233px;
        }
      }
    }
  }
}
</style>
