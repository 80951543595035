<template>
  <nav class="pagination">
    <span
      v-if="prevNum"
      class="prev"
      @click="handlePageChanged(prevNum)">
      Prev
    </span>
    <!-- TODO: FIX pagination links -->
    <span
      v-for="(link, index) in linkCount"
      :key="index++"
      :class="[
        'link',
        currentPage === index ? 'current' : '',
        index > currentPage + 3
        || index < currentPage - 3 ? 'disabled' : '',
        index > linkCount - 2 ? 'last' : ''
      ]"
      @click="handlePageChanged(index)">
      <!-- index > 5
      && index < linkCount - 2
      && nextNum !== index
      && prevNum !== index ? 'disabled': '' -->
      {{ index }}
    </span>
    <span
      v-if="nextNum"
      class="next"
      @click="handlePageChanged(nextNum)">
      Next
    </span>
  </nav>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'Pagination',
  props: {
    totalItems: Number,
    nextNum: Number,
    currentPage: Number,
    perPage: Number,
    prevNum: Number,
    isComputed: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    this.$emit('initPagination', this.setQueryPage)
  },
  computed: {
    linkCount () {
      return this.totalItems ? Math.ceil(this.totalItems / this.perPage) : 0
    }
  },
  methods: {
    handlePageChanged (num) {
      this.$emit('pageChanged', num)

      if (!this.isComputed) {
        const query = {}
        if (num != null) {
          query.page = num
        }

        if (!_.isEqual(this.$route.query, query)) {
          this.$router.push({
            name: this.$route.name,
            query: {
              ...this.$route.query,
              ...query
            }
          })
        }
      }
    },
    setQueryPage (pagination) {
      if ('page' in this.$route.query && this.$route.query.page) {
        pagination.currentPage = Number(this.$route.query.page)
      }
    }
  }
}
</script>

<style lang="scss">
nav.pagination {
  background: none;
  height: auto;
  justify-content: center;
  margin: 35px -5px 0;
  padding: 0;
  width: 100%;
  span {
    width: auto;
    height: 30px;
    min-width: 30px;
    padding: 0 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #e4edf2;
    border-radius: 6px;
    margin: 0 5px;
    cursor: pointer;
    transition: all .3s ease-in-out;
    &:hover {
      background: #e4edf2;
    }
    &.disabled {
      display: none;
      &.last {
        display: flex;
      }
    }
    &.current {
      border-color: #0158E3;
      background: #0158E3;
      color: #fff;
      &.disabled {
        display: flex;
      }
      &:hover {
        background: #0a4fc4;
      }
    }
    &:not(.link) {
      width: auto;
      padding: 0 10px;
      &.prev {
        margin-right: auto;
      }
      &.next {
        margin-left: auto;
      }
    }
  }
}
</style>
