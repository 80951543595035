<template>
  <BaseLayout
    title="New Power Rankings Report"
    :customClasses="['power-rankings-search']">
    <Form
      ref="taskSearch"
      :hasLabel="true"
      labelText="Select Market"
      @submit.prevent="enqueueTask(practiceArea, location, webUrl)">
      <Dropdown
        :options="filteredLocations"
        :isSearch="true"
        dropdownPlaceholder="Start typing location"
        :inputHasLabel="true"
        inputId="city"
        inputLabelText="Select city"
        @select="location = $event"
        @input="debounceLocationName($event)"
        :inputVal="location"
        :isDisabled="formDisabled"
        :inputRequired="true" />
      <Dropdown
        :hasIcon="true"
        :options="formData.practiceAreas"
        dropdownPlaceholder="Practice Area"
        :inputHasLabel="true"
        inputId="practiceArea"
        inputLabelText="Select practice area"
        @select="practiceArea = $event"
        @input="practiceArea = $event"
        :inputVal="practiceArea"
        :isDisabled="formDisabled"
        :inputRequired="true" />
      <span class="form-label mt-lg">Select Target Domain</span>
      <Input
        :hasLabel="true"
        labelText="Enter url (use http:// or https://)"
        inputId="webUrl"
        placeholder="https://example.com"
        @input="webUrl = $event"
        v-model="webUrl"
        :disabled="formDisabled" />
      <Button
        text="Submit"
        type="submit"
        :isDisabled="formDisabled" />
    </Form>
    <div
      class="validation-messages"
      v-if="formError">
      <p
        v-for="(error, i) in formErrors"
        :key="i">
        {{ error }}
        <!-- Please <a @click.prevent="runNewSearch">try again.</a> -->
      </p>
    </div>
    <Loading
      v-show="loading"
      text="collecting data" />
  </BaseLayout>
</template>

<script>
import Dropdown from '@/components/form/Dropdown'
import Input from '@/components/form/Input'
import Button from '@/components/form/Button'
import Form from '@/components/form/Form'
import { mapGetters, mapState } from 'vuex'
import BaseLayout from '@/views/BaseLayout'
import Loading from '@/components/misc/Loading'
import _ from 'lodash'

export default {
  name: 'PowerRankings',
  components: {
    Form,
    Button,
    Input,
    Loading,
    Dropdown,
    BaseLayout
  },
  data () {
    return {
      loading: false,
      practiceArea: '',
      location: '',
      webUrl: '',
      formDisabled: false,
      formError: false,
      formErrors: [],
      filteredLocations: [],
      completedTask: {}
    }
  },
  computed: {
    ...mapState({
      formData: state => state.form
    }),

    ...mapGetters({
      tableData: 'table/getTableData'
    }),

    slug () {
      return this.webUrl !== ''
        ? this.getDomainSlug(
          this.completedTask.completed_at,
          this.location?.replace(/, /g, ','),
          this.practiceArea,
          this.cleanWebUrl(this.webUrl),
          this.completedTask.id,
          'power-rankings-detail'
        )
        : this.getTaskSlug(
          this.completedTask.completed_at,
          this.location?.replace(/, /g, ','),
          this.practiceArea,
          this.completedTask.id,
          'power-rankings-overview'
        )
    }
  },
  mounted () {
    // setTimeout(() => {
    //   if (this.formData.practiceAreas.length === 0) {
    //     this.$store.dispatch('form/setData')
    //   }
    // }, 1000)
  },
  methods: {
    runNewSearch () {
      this.location = ''
      this.practiceArea = ''
      this.formError = false
      this.$refs.taskSearch.$el.reset()
      this.$store.dispatch('table/setTableData', {})
    },

    isEmpty (obj) {
      return _.isEmpty(obj)
    },

    debounceLocationName: _.debounce(function (val) {
      if (val.length > 2) {
        const cleanedVal = val.replace(/, /g, ',')
        this.getLocations(cleanedVal)
      } else {
        this.filteredLocations = []
      }
    }, 200),

    enqueueTask (practiceArea, location, webUrl) {
      this.$arc.post('tasks', {
        practice_area_name: `${practiceArea}`,
        dfs_location_name: `${location.replace(/, /g, ',')}`,
        power_ranking: 1,
        ...(webUrl && { url: webUrl })
      })
        .then(response => {
          const task = response.data
          this.waitForTask(task.id)
          this.loading = true
          this.formDisabled = true
        })
        .catch(error => {
          console.error(error)
          this.loading = false
          this.formDisabled = false
        })
    },

    randomFloatFromInterval (lowerBound = 5000, upperBound = 10000) {
      return Math.random() * (upperBound - lowerBound) + lowerBound
    },

    async waitForTask (id) {
      const now = new Date()

      try {
        const resp = await this.$arc.get(`tasks/${id}/status`)

        let shouldRetry = false

        const { created_at: createdAt, completed_at: completedAt } = resp.data

        shouldRetry = !completedAt && (now.getTime() - new Date(createdAt).getTime() < this.TEN_MINS)

        if (completedAt) {
          this.completedTask = resp.data
          this.$router.push({ path: this.slug })
        } else if (!shouldRetry) {
          this.formError = true
          this.formErrors = ['Report couldn\'t complete in under 10 minutes. Please check the archives at a later time.']
          this.loading = false
        }

        if (shouldRetry) {
          setTimeout(() => {
            this.waitForTask(id)
          }, this.randomFloatFromInterval())
        }
      } catch (error) {
        console.error('error fetching task status:', error)
        this.loading = false
      }
    },

    getLocations (name) {
      this.filteredLocations = this.searchUsLocationList(name).map(location => {
        return location.replace(/,/g, ', ')
      })
    },

    getTaskById (id) {
      this.loading = true
      this.$arc.get(`tasks/${id}`)
        .then(res => {
          this.$store.dispatch('table/setTableData', res.data)
          this.$router.push({ path: this.slug })
          this.formDisabled = false
          this.loading = false
        })
        .catch(err => {
          console.error(err)
          this.formError = true
          this.formErrors = err
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss">
.power-rankings-search {
  .action-links {
    display: flex;
    button:last-child {
      font-size: .875rem;
    }
  }
  form {
    margin-bottom: 30px;
    .mt-lg {
      margin-top: 50px;
    }
  }
}
.validation-messages {
  a {
    color: #3b80e8;
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
