var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"input-container",class:{
    'has-button': _vm.hasButton,
    'has-label': _vm.hasLabel,
    'is-file-input': _vm.type === 'file',
    'is-checkbox-input': _vm.type === 'checkbox',
    'is-fancy-file': _vm.fancyFile
  }},[(_vm.hasLabel)?_c('label',{class:{
      'is-checked': _vm.checked
    },style:(_vm.customLabelStyle),attrs:{"for":_vm.inputId},on:{"click":function($event){return _vm.$emit('labelClick', $event)}}},[_vm._v(" "+_vm._s(_vm.labelText)+" "),(_vm.required)?_c('span',[_vm._v("*")]):_vm._e()]):_vm._e(),_c('input',{staticClass:"input",class:{
      'no-padding': _vm.noPadding,
      'is-absolute': _vm.isAbsolute
    },style:([
      _vm.readonly
      ? { 'cursor': 'pointer' }
      : ''
    ]),attrs:{"type":_vm.type,"readonly":_vm.readonly,"id":_vm.inputId,"disabled":_vm.disabled,"placeholder":_vm.placeholder,"min":_vm.min,"max":_vm.max,"autocomplete":"off","required":_vm.required,"pattern":_vm.pattern},domProps:{"value":_vm.value,"checked":_vm.checked},on:{"input":function($event){return _vm.$emit('input', $event.target.value)},"blur":function($event){return _vm.$emit('blur', $event)},"focus":function($event){return _vm.$emit('focus', $event)},"change":function($event){return _vm.$emit('change', $event)},"click":function($event){return _vm.$emit('click', $event)}}}),(_vm.hasButton)?_c('Button',{attrs:{"text":_vm.btnText,"hasIcon":_vm.btnHasIcon,"icon":_vm.btnIcon,"isInversed":_vm.btnIsInversed},on:{"click":function($event){return _vm.$emit('btnClick', $event)}}}):_vm._e(),_c('span',{staticClass:"error-message"},[_vm._v(" This field is required. ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }