<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'MainView'
}
</script>

<style>

</style>
