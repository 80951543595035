<template>
  <div class="page-content">
    <Form
      class="filter-form"
      @submit.prevent="filterMarketingCompanies">
      <strong>Filters</strong>
      <Input
        :hasLabel="true"
        labelText="Name"
        placeholder="Company name"
        v-model="filterForm.companyName"
        :disabled="loading" />
      <Input
        :hasLabel="true"
        labelText="Url"
        placeholder="Company url"
        v-model="filterForm.companyUrl"
        :disabled="loading" />
      <Button
        type="submit"
        text="Apply filters"
        :isDisabled="loading" />
    </Form>
    <settings-panel
      :actionLinkMethod="toggleFormModal">
      <template slot="modal">
        <div
          class="form-modal"
          :class="{ show: formModalActive }"
          >
          <div class="form-modal-wrapper">
            <h3>
              {{ formModalMode === 'edit' ? 'Edit' : 'Add New' }} Marketing Company
            </h3>
            <Form
              @submit.prevent="handleFormSubmit">
              <Loading
                v-show="loading"
                text="Loading company data" />
              <div class="field-group" v-show="!loading">
                <Input
                  :hasLabel="true"
                  labelText="Company Icon"
                  placeholder="Company icon"
                  inputId="companyIcon"
                  type="file"
                  :fancyFile="true"
                  :disabled="loading"
                  @change="handleFileChange($event)"
                  v-if="formModalMode === 'edit'" />
                <Input
                  type="text"
                  :hasLabel="true"
                  labelText="Company Name"
                  placeholder="company name"
                  :required="formModalMode !== 'edit'"
                  v-model="form.companyName" />
                <Input
                  type="url"
                  :hasLabel="true"
                  labelText="Company Url"
                  placeholder="company url"
                  v-model="form.companyUrl" />
                <Input
                  type="text"
                  :hasLabel="true"
                  labelText="Snippet"
                  placeholder="snippet"
                  v-model="form.companySnippet" />
                <ButtonGroup
                  btnAlign="right"
                  style="width:100%;">
                  <Button
                    text="Cancel"
                    :isInversed="true"
                    :isBordered="true"
                    @click.prevent="closeFormModal" />
                  <Button
                    type="submit"
                    text="Submit"
                    :isDisabled="loading"
                    btnType="primary" />
                </ButtonGroup>
              </div>
            </Form>
          </div>
        </div>
      </template>
      <Table v-if="marketingCompanies.length > 0">
        <template slot="head">
          <t-row>
            <t-heading
              :noPadding="true"
              style="width:50px;"></t-heading>
            <t-heading
              :hasTip="true"
              thTip="(Double click to edit)">
              Name
            </t-heading>
            <t-heading
              :hasTip="true"
              thTip="(Double click to edit)">
              URL
            </t-heading>
            <t-heading></t-heading>
          </t-row>
        </template>
        <template slot="body">
          <t-row
            v-for="(company, idx) in marketingCompanies"
            :key="idx">
            <t-cell
              :noPadding="true"
              style="width:50px;">
              <div class="logo-container">
                <img
                  height="24"
                  width="24"
                  :src="company.favicon_url || company.icon_url || require('../../assets/img/fallback_fav.png')"/>
              </div>
            </t-cell>
            <t-cell
              :isEditable="true"
              :isInput="true"
              :defaultValue="company.name"
              @editInput="form.companyName = $event"
              @doneEditing="updateMarketingCompany(company.id, idx)">
              {{ company.name }}
            </t-cell>
            <t-cell
              :isEditable="true"
              :isInput="true"
              :defaultValue="company.url"
              @editInput="form.companyUrl = $event"
              @doneEditing="updateMarketingCompany(company.id, idx)">
              <a
                :href="company.url"
                target="_blank">
                {{ company.url }}
              </a>
            </t-cell>
            <t-cell>
              <div class="table-action">
                <span
                  class="table-action-icon"
                  @click.prevent="toggleTableAction($event)">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
                <ul>
                  <li @click="toggleFormModal('edit', company.id)">
                    Edit
                  </li>
                  <li @click="deleteMarketingCompany(idx)">
                    Delete
                  </li>
                </ul>
              </div>
            </t-cell>
          </t-row>
        </template>
      </Table>
      <Loading
        v-show="loading"
        text="Loading overview" />
      <p v-if="marketingCompanies.length === 0 && !loading">
        No results found. Please try again.
      </p>
      <Pagination
        v-if="!loading"
        :perPage="pagination.per_page"
        :currentPage="pagination.page"
        :nextNum="pagination.next_num"
        :prevNum="pagination.prev_num"
        :totalItems="pagination.total"
        @pageChanged="goToPage($event)" />
    </settings-panel>
    <alert ref="alert"></alert>
  </div>
</template>

<script>
import Loading from '@/components/misc/Loading'
import SettingsPanel from '@/components/global/user/SettingsPanel'
import Table from '@/components/table/Table'
import TableDataCell from '@/components/table/TableDataCell'
import TableRow from '@/components/table/TableRow'
import TableHeading from '@/components/table/TableHeading'
import Button from '@/components/form/Button'
import ButtonGroup from '@/components/form/ButtonGroup'
import Form from '@/components/form/Form'
import Input from '@/components/form/Input'
import Pagination from '@/components/misc/Pagination'

export default {
  name: 'UserMarketingCompanies',
  components: {
    Loading,
    SettingsPanel,
    Table,
    't-row': TableRow,
    't-cell': TableDataCell,
    't-heading': TableHeading,
    Button,
    ButtonGroup,
    Form,
    Input,
    Pagination
  },
  data () {
    return {
      loading: false,
      marketingCompanies: [],
      selectedKeywords: [],
      formModalActive: false,
      formModalMode: 'add',
      currentMarketingCompany: null,
      filterForm: {
        companyName: '',
        companyUrl: ''
      },
      form: {
        companyName: '',
        companyUrl: '',
        companySnippet: '',
        companyIcon: null
      },
      pagination: {
        page: 1,
        next_num: 2,
        prev_num: null,
        per_page: 50,
        total: 0
      }
    }
  },
  mounted () {
    this.getMarketingCompanies()
  },
  methods: {
    handleFileChange (evt) {
      this.form.companyIcon = evt.target.files[0]
    },

    handleFormSubmit () {
      this.formModalMode === 'edit'
        ? this.updateMarketingCompany(this.currentMarketingCompany.id, null)
        : this.addNewMarketingCompany()
    },

    async getMarketingCompanies (pageNum, nameLike, urlLike) {
      this.loading = true
      try {
        const { data } = await this.$arc.get('marketing_companies', {
          params: {
            per_page: this.pagination.per_page,
            ...{ page: pageNum || this.pagination.page },
            ...(nameLike ? { name_like: nameLike } : {}),
            ...(urlLike ? { url_like: urlLike } : {}),
            strict: 0
          }
        })
        this.marketingCompanies = data?.marketing_companies
        this.pagination.page = data?.page
        this.pagination.next_num = data?.next_num
        this.pagination.prev_num = data?.prev_num
        this.pagination.per_page = data?.per_page
        this.pagination.total = data?.total
        this.loading = false
      } catch (error) {
        console.error(error)
        this.loading = false
      }
    },

    async addNewMarketingCompany () {
      this.loading = true
      try {
        // check if this.form.companyUrl is empty
        if (this.form.companyUrl !== '') {
          this.form.companyUrl = this.form.companyUrl.includes('http') || this.form.companyUrl.includes('https')
            ? this.form.companyUrl
            : `https://${this.form.companyUrl}`
        }
        await this.$arc.post('marketing_companies', {
          name: this.form.companyName,
          ...(this.form.companyUrl && { url: this.form.companyUrl }),
          ...(this.form.companySnippet && { snippet: this.form.companySnippet })
        })
        this.$refs.alert.showAlert('Successfully added new marketing company', 'success')
        this.toggleFormModal()
        setTimeout(() => {
          // this.filterForm.companyName = this.form.companyName
          // this.filterForm.companyUrl = this.form.companyUrl
          this.getMarketingCompanies()
        }, 2000)
      } catch (error) {
        const errorRes = error.response?.data
        if (errorRes.validation_error.body_params) {
          errorRes.validation_error.body_params.forEach(err => {
            this.$refs.alert.showAlert(err.msg, 'error')
          })
        } else {
          this.$refs.alert.showAlert('An error occurred while new marketing company', 'error')
        }
        console.error(error)
        this.loading = false
      }
    },

    async getMarketingCompany (id) {
      this.loading = true
      try {
        const { data } = await this.$arc.get(`marketing_companies/${id}`)
        this.currentMarketingCompany = data?.marketing_company
        this.form.companyName = data?.marketing_company?.name
        this.form.companyUrl = data?.marketing_company?.url
        this.form.companySnippet = data?.marketing_company?.snippet
        this.loading = false
      } catch (error) {
        alert('An error occurred while fetching the marketing company.')
        console.error(error)
        this.loading = false
      }
    },

    async updateMarketingCompany (id, idx) {
      if (id) {
        try {
          this.loading = true
          const res = await this.$arc.patch(`marketing_companies/${id}`, {
            ...(this.form.companyName && { name: this.form.companyName }),
            ...(this.form.companyUrl && { url: this.form.companyUrl }),
            ...(this.form.companySnippet && { snippet: this.form.companySnippet })
          })

          if (this.form.companyIcon) {
            try {
              const formData = new FormData()
              formData.append('file', this.form.companyIcon)
              await this.$arc.put(`marketing_companies/${id}/icon`, formData, {
                headers: {
                  'Content-Type': 'multipar/form-data'
                }
              })
            } catch (err) {
              console.error(err)
            }
          }

          this.loading = false
          if (idx) {
            this.$set(this.marketingCompanies[idx], 'name', res.data?.name)
            this.$set(this.marketingCompanies[idx], 'url', res.data?.url)
          }
          this.$refs.alert.showAlert('Successfully updated the marketing company', 'success')
          this.closeFormModal()
          setTimeout(() => {
            this.getMarketingCompanies()
          }, 2000)
        } catch (error) {
          const errorRes = error.response?.data
          if (errorRes.validation_error.body_params) {
            errorRes.validation_error.body_params.forEach(err => {
              this.$refs.alert.showAlert(err.msg, 'error')
            })
          } else {
            this.$refs.alert.showAlert('An error occurred while updating the marketing company', 'error')
          }
          console.error(error)
          this.loading = false
        }
      }
    },

    async deleteMarketingCompany (idx) {
      if (confirm('Are you sure you want to delete this marketing company?')) {
        const marketingCompanyToDelete = this.marketingCompanies[idx]

        if (marketingCompanyToDelete.id) {
          try {
            await this.$arc.delete(`marketing_companies/${marketingCompanyToDelete.id}`)
            this.marketingCompanies.splice(idx, 1)
            this.$forceUpdate()
            this.$refs.alert.showAlert('Successfully deleted the marketing company', 'success')
            setTimeout(() => {
              this.getMarketingCompanies()
            }, 2000)
          } catch (error) {
            this.$refs.alert.showAlert('An error occurred while deleting the marketing company', 'success')
            console.error(error)
          }
        }
      }
    },

    closeFormModal () {
      this.formModalMode = 'add'
      this.formModalActive = false
      this.form = {
        companyName: '',
        companyUrl: '',
        companySnippet: ''
      }
    },

    toggleFormModal (mode, id) {
      if (id) this.getMarketingCompany(id)
      mode === 'edit' ? this.formModalMode = 'edit' : this.formModalMode = 'add'
      this.formModalActive = !this.formModalActive
    },

    toggleTableAction (evt) {
      const icon = evt.target.closest('.table-action-icon')
      const ul = icon.nextElementSibling
      ul.classList.toggle('show')
    },

    filterMarketingCompanies () {
      this.getMarketingCompanies(this.pagination.page, this.filterForm.companyName, this.filterForm.companyUrl)
    },

    goToPage (evt) {
      this.getMarketingCompanies(evt)
    }
  }
}
</script>

<style lang="scss" scoped>
section {
  &.user-profile {
    .container {
      .filter-form {
        margin-bottom: 20px;

        > strong {
          margin-bottom: 16px;
          margin-right: 16px;
        }
      }
      .form-modal {
        &-wrapper {
          max-width: 600px;
        }
      }
      table {
        .logo-container {
          img {
            margin: 0 1rem;
            vertical-align: bottom;
            display: inline-block;
          }
        }
      }
    }
  }
}
</style>
