<template>
  <div
    class="tab-content"
    v-show="isActive">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Tab',
  props: {
    title: {
      type: String,
      default: 'Tab'
    }
  },
  data () {
    return {
      isActive: true
    }
  }
}
</script>

<style lang="scss">
.tab {
  &-content {
    padding: 40px 0;
  }
}
</style>
