<template>
  <BaseLayout
    :showHeading="false"
    :customClasses="['power-rankings-list']">
    <Loading
      v-show="loading"
      text="loading report"
      style="position:fixed;top:0;left:0;bottom:0;right:0;background:#fff;" />
    <template #page-heading>
      <div
        class="page-heading__wrapper"
        :class="{
          'loaded': !loading
        }"
        v-show="!loading">
        <div class="page-heading center-heading">
          <h1>{{ capitalizeString(task.practice_area_name) }} Website Power Rankings Report</h1>
        </div>
        <span class="page-subheading">
          Market Analysis of {{ task.practice_area_name }} in {{ formattedLocation }}
        </span>
        <img
          src="@/assets/img/google_logo.png"
          alt="Google"
          class="google-logo"
          v-show="!loading">
        <p
          class="blurb"
          v-show="!loading">
          ARC Power Rankings analyze Google search results for a geographic area and specific {{ task.practice_area_name }} keywords.
        </p>
      </div>
    </template>
    <div
      class="top-three"
      v-show="!loading">
      <div class="top-three__container">
        <div class="top-three__grid">
          <div
            class="top-three__block"
            v-for="(site, idx) in topThree"
            :key="idx">
            <div class="top-three__block--thumbnail">
              <div class="top-three__block--thumbnail__wrapper">
                <div
                  class="top-three__block--spinner">
                  <Spinner />
                </div>
                <img :src="site.thumbnail">
                <img src="@/assets/img/phone-white-no-notch.png" alt="">
              </div>
            </div>
            <div class="top-three__block--details">
              <span>#{{ site.arc_rank }} Power Ranking</span>
              <a
                :href="`https://${site.domain}`">
                {{ site.domain }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <span
      class="h1 text-centered"
      v-show="!loading">
      Power Rankings for {{ capitalizeString(task.practice_area_name) }} <br /> in {{ formattedLocation }}
    </span>
    <div
      class="show-agency-toggle"
      v-if="showAgencyToggle && !loading">
      <Toggle
        v-model="showAgency"
        :isChecked="showAgency"
        :hasLabel="true"
        labelText="Mkt Data"
        @change="toggleAgency" />
    </div>
    <Table
      :loading="loading"
      :isResponsive="true">
      <template slot="head">
        <t-row>
          <t-heading></t-heading>
          <t-heading>
            Domain
          </t-heading>
          <t-heading
            :hasTip="true"
            thTip="(Double click to edit)"
            v-if="showAgency && showAgencyToggle">
            Marketing Co.
          </t-heading>
          <t-heading>Market Tier</t-heading>
          <t-heading>
            ARC Score
          </t-heading>
          <t-heading>
            Power Rankings
          </t-heading>
          <t-heading
            v-if="$auth.isAuthenticated">
            View
          </t-heading>
        </t-row>
      </template>
      <template slot="body">
        <t-row
          v-for="(result, i) in sortedResults"
          :key="i">
          <t-cell>
            <img
              :src="getFaviconString(result.domain)"
              v-if="getFaviconString(result.domain) !== null"
              width="22"
              height="22">
          </t-cell>
          <t-cell
            item="Domain">
            <a
              :href="`https://${result.domain}`"
              target="_blank"
              rel="noopener">
              {{ result.domain }}
            </a>
          </t-cell>
          <t-cell
            :isEditable="true"
            @doneEditing="updateMarketingAgency(result, marketingCompany)"
            @dropdownInput="marketingCompany = $event"
            @dropdownSelect="marketingCompany = $event"
            :dropdownOptions="filteredCompanies"
            customOption="name"
            :defaultValue="
              result.marketing_company === ''
                ? ''
                : result.marketing_company
            "
            v-if="showAgency && showAgencyToggle">
            {{ result.marketing_company === 'null' ? '' : result.marketing_company }}
          </t-cell>
          <t-cell
            item="Market Tier"
            :textCentered="true">
            {{ task.market_tier }}
          </t-cell>
          <t-cell
            item="ARC Score">
            {{ result.arc_score }}
          </t-cell>
          <t-cell
            item="Power Rankings"
            :textCentered="true">
            <!-- {{ result.keyword_rank > 100 ? 'Not in top 100 results' : result.keyword_rank }} -->
              {{  i + 1 > 100 ? 'Not in top 100 results' : i + 1 }}
          </t-cell>
          <t-cell
            v-if="$auth.isAuthenticated">
            <Button
              :hasLink="true"
              :btnLink="{ path: `${result.domain_slug}` }"
              :isView="true" />
          </t-cell>
        </t-row>
      </template>
    </Table>
    <action-links
      v-if="!loading && sortedResults.length > 0">
      <Button
        btnType="fancy"
        @click="copy(shareableSlug)"
        text="Click to copy shareable URL"
          v-if="$auth.isAuthenticated" />
    </action-links>
  </BaseLayout>
</template>

<script>
import Table from '@/components/table/Table'
import TableRow from '@/components/table/TableRow'
import TableHeading from '@/components/table/TableHeading'
import TableDataCell from '@/components/table/TableDataCell'
import BaseLayout from '@/views/BaseLayout'
import Loading from '@/components/misc/Loading'
import Button from '@/components/form/Button'
import Spinner from '@/components/misc/Spinner'
import ActionLinks from '@/components/misc/ActionLinks'
import Toggle from '@/components/misc/Toggle'
import { mapState } from 'vuex'
import _ from 'lodash'

export default {
  name: 'PowerRankingsList',
  components: {
    Table,
    BaseLayout,
    Loading,
    Button,
    Spinner,
    ActionLinks,
    Toggle,
    't-row': TableRow,
    't-heading': TableHeading,
    't-cell': TableDataCell
  },
  data () {
    return {
      loading: false,
      sort: {
        key: 'arc_score',
        isAsc: true
      },
      results: [],
      // blacklistedDomains: [],
      task: {},
      marketingCompany: '',
      showAgency: true
    }
  },
  created () {
    this.loading = true
    this.getAllData()
    // this.getThumbnails()
  },
  watch: {
    topThree: {
      handler: function (sites) {
        if (sites.length === 3) {
          this.getThumbnails()
        }
      }
    }
  },
  computed: {
    ...mapState({
      formData: state => state.form
    }),

    slug () {
      return `${this.$route.params.slug}?id=${this.$route.query.id}`
    },

    shareableSlug () {
      const baseUrl = process.env.VUE_APP_BASE_URL

      const location = this.formatLocation(this.task.dfs_location_name).toLowerCase().replace(', ', '-').replace(' ', '-')
      const practiceArea = this.task.practice_area_name.replace(' ', '-').toLowerCase()

      return baseUrl + '/power-rankings/overview/' + `${location}/` + `${practiceArea}/` + this.task.uuid
    },

    showAgencyToggle () {
      return this.$auth.isAuthenticated
    },

    filteredCompanies () {
      return this.searchMarketingList(this.marketingCompany, this.formData.marketingCompanies).slice(0, 100)
    },

    formattedLocation () {
      return this.formatLocation(this.task.dfs_location_name)
    },

    sortedResults () {
      const list = this.results ? this.results : []
      if (this.sort.key) {
        list.sort((a, b) => {
          a = a[this.sort.key]
          b = b[this.sort.key]
          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
        })
      }
      return list
    },

    allDomains () {
      const domains = []
      if (this.sortedResults.length > 0) {
        this.sortedResults.forEach(res => {
          domains.push(res.domain)
        })
      }
      return domains
    },

    topThree () {
      return this.getTopThree(this.sortedResults)
    },

    userCompany () {
      return this.taskAuthor && this.taskAuthor.email ? this.getCompanyByEmail(this.taskAuthor.email) : ''
    },

    tagline () {
      return this.userCompany !== '' && (this.userCompany === 'Blue Corona' || this.userCompany === 'Ryno')
        ? 'Home Services'
        : 'Law Firm'
    },

    taskAuthor () {
      return this.task?.user?.email !== null
        ? this.task?.user
        : this.task?.sales_outreach_user
    }
  },
  methods: {
    getMarketingCompanyFavicon (marketingCompany) {
      const filteredCompany = this.filteredCompanies.filter(company => {
        return company.name === marketingCompany
      })
      return filteredCompany.length === 1 ? filteredCompany[0].favicon_url : null
    },

    toggleAgency () {
      this.showAgency = !this.showAgency
    },

    getThumbnails () {
      this.topThree.forEach(site => {
        this.$urlbox(`https://${site.domain}`, {
          width: 360,
          height: 767,
          wait_until: 'mostrequestsfinished'
        })
          .then(res => {
            this.$set(site, 'thumbnail', res)
            this.$forceUpdate()
          })
      })
    },

    getAllData () {
      if (this.$route.query.id && this.$auth.isAuthenticated) {
        this.$store.dispatch('footer/setLoading', true)
        this.$arc.get(`tasks/${this.$route.query.id}`)
          .then(res => {
            this.task = res.data
            // this.results = this.getPowerRank(this.task, true)
            this.results = this.task.results
            this.results.forEach(result => {
              result.domain_slug = this.getDomainSlug(
                this.task.completed_at,
                this.task.dfs_location_name,
                this.task.practice_area_name,
                result.domain,
                this.task.id,
                'power-rankings-detail'
              )
            })
            this.getArcRank()
            const user = {
              email: this.task.user.email,
              name: this.task.user.name,
              profile_picture_url: this.task.user.profile_picture_url,
              job_title: this.task.user.job_title,
              calendly_link: this.task.user.calendly_link,
              phone: this.task.user.phone
            }
            this.$store.dispatch('footer/setUserInfo', user.email !== null ? user : this.task.sales_outreach_user)
            this.$store.dispatch('footer/setLoading', false)
            this.formDisabled = false
            this.loading = false
          })
          .catch(err => {
            console.error(err)
            this.formError = true
            this.formErrors = err
            this.loading = false
            this.$store.dispatch('footer/setLoading', false)
          })
      } else {
        this.$store.dispatch('footer/setLoading', true)
        const taskUUID = this.$route.params.slug.split('/')[2]
        if (taskUUID) {
          const viewArcClient = this.$axios.create({
            baseURL: process.env.VUE_APP_ARC_SCORE_API,
            headers: {
              'Content-Type': 'application/json',
              'X-View-Key': process.env.VUE_APP_ARC_SCORE_VIEW_KEY
            }
          })
          viewArcClient.get(`tasks/${taskUUID}`)
            .then(res => {
              this.task = res.data
              // this.results = this.getPowerRank(this.task, true)
              this.results = this.task.results
              this.results.forEach(result => {
                result.domain_slug = this.getDomainSlug(
                  this.task.completed_at,
                  this.task.dfs_location_name,
                  this.task.practice_area_name,
                  result.domain,
                  this.task.id,
                  'power-rankings-detail'
                )
              })
              this.getArcRank()
              const user = {
                email: this.task.user.email,
                name: this.task.user.name,
                profile_picture_url: this.task.user.profile_picture_url,
                job_title: this.task.user.job_title,
                calendly_link: this.task.user.calendly_link,
                phone: this.task.user.phone
              }
              this.$store.dispatch('footer/setUserInfo', user.email !== null ? user : this.task.sales_outreach_user)
              this.$store.dispatch('footer/setLoading', false)
              this.formDisabled = false
              this.loading = false
            })
            .catch(err => {
              console.error(err)
              this.formError = true
              this.formErrors = err
              this.loading = false
              this.$store.dispatch('footer/setLoading', false)
            })
        } else {
          this.$router.push({ name: 'NotFound' })
          this.loading = false
        }
      }
    },

    updateMarketingAgency (result, marketingAgency) {
      this.$arc.put('domains', {
        name: `${result.domain}`,
        ...(marketingAgency.length >= 3 && { marketing_company: marketingAgency })
      }).then(response => {
        this.$arc.get(`domains/${response.data.name}`)
          .then(res => {
            this.$set(result, 'marketing_company', res.data.marketing_company)
          })
          .catch(err => {
            console.error(err)
          })
        this.marketingCompany = ''
      }).catch(err => {
        console.error(err)
      })
    },

    getTopThree (list) {
      var newList = _.cloneDeep(list)
      return newList ? newList.splice(0, 3) : []
    },

    getArcRank () {
      if (this.sort.key === 'arc_score' && this.sort.isAsc) {
        this.sortedResults.forEach((result, i) => {
          result.arc_rank = i + 1
        })
      }
    },

    sortedClass (key) {
      return this.sort.key === key
        ? `sorted ${this.sort.isAsc ? 'asc' : 'desc'}`
        : ''
    },

    sortBy (key) {
      this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false
      this.sort.key = key
    }
  }
}
</script>

<style lang="scss">
td {
  .svg-inline--fa {
    color: #000;
  }
}

.power-rankings-list {
  overflow: hidden;
  padding: 0;
  table {
    max-width: 1125px;
    margin: 0 auto;
  }
  .h1 {
    margin: 3.4375rem 0 5rem;
  }
  .page-header {
    position: relative;
  }
  .action-links {
    justify-content: center;
    padding-bottom: 192px;
    margin-bottom: 0;
    margin-top: 40px;
    button {
      width: max-content;
    }
  }
  // .google-logo {
  //   max-width: 130px;
  // }
  // .blurb {
  //   font-weight: 400;
  //   max-width: 800px;
  //   margin: 0 auto 30px;
  // }
  .container {
    max-width: 100%;
    .h1 {
      &.text-centered {
        margin: 140px auto 0;
        font-family: 'DM Serif Display', serif;
        font-size: 42px;
        line-height: calc(52/42);
        color: #01307E;
        font-weight: 400;
        &:after {
          content: '';
          display: block;
          width: 186px;
          height: 1px;
          background: #EE6152;
          margin: 30px auto 47px;
        }
      }
      &.mt-lg {
        margin-top: 6.43rem;
      }
    }
    .view-report {
      text-align: center;
      margin-bottom: 30px;
      a {
        display: block;
        font-size: 1rem;
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
        color: inherit;
      }
    }
    .show-agency-toggle {
      max-width: 1125px;
      margin: 10px auto 20px;
    }
  }
  .page-header {
    display: block !important;
  }
  .page-heading__wrapper {
    padding-top: 94px;
    padding-bottom: 368px;
    text-align: center;
    background: #fff;
    position: relative;
    z-index: 1;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      opacity: 0;
      transition: opacity .5s ease;
      background: linear-gradient(to bottom, #0158E3, #012C72);
      clip-path: ellipse(100% 100% at 50% 0%);
    }
    &.loaded {
      &:before {
        opacity: 1;
      }
    }
    .page {
      &-heading {
        max-width: 755px;
        margin: 0 auto;
        h1 {
          font-size: 69px;
          line-height: calc(72/69);
          color: #fff;
          letter-spacing: 0;
        }
      }
      &-subheading {
        color: #95BDFF;
        font-size: 18px;
        line-height: calc(24/18);
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 1.8px;
        margin-top: 12px;
        display: block;
        &:after {
          content: '';
          background: #EE6152;
          width: 185px;
          height: 1px;
          display: block;
          margin: 41px auto 35px;
        }
      }
    }
    .blurb {
      font-weight: 400;
      color: #fff;
      max-width: 435px;
      font-size: 18px;
      line-height: calc(26/18);
      margin: 9px auto 0;
    }
    .google-logo {
      max-width: 130px;
      margin: 0 auto;
    }
  }
}

.top-three {
  // background: #f0f5fb;
  // margin: 0 -2rem;
  // padding: 1.25rem 2rem 2.5rem;
  margin: -340px auto 0;
  .top-three {
    &__container {
      max-width: 1100px;
      margin: 0 auto;
    }
    &__title {
      font-family: "DM Serif Display", serif;
      text-align: center;
      display: block;
      color: #0158E3;
      font-weight: 700;
      font-size: 24px;
      margin-bottom: 1.25rem;
    }
    &__grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 2rem;
    }
    &__block {
      &--spinner {
        position: absolute;
        top: 25px;
        left: 23px;
        bottom: 25px;
        right: 23px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        border-radius: 20px;
        z-index: -1;
      }
      &--thumbnail {
        position: relative;
        // padding-top: calc((688px / 860px) * 100%);
        // margin: 1.25rem 0;
        // background: #e6ecf1;
        // img {
        //   // opacity: 0;
        //   position: absolute;
        //   top: 0;
        //   left: 0;
        //   right: 0;
        //   bottom: 0;
        //   display: block;
        //   object-fit: cover;
        //   max-width: 100%;
        //   width: 100%;
        // }
        &__wrapper {
          // position: relative;
          z-index: 1;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          // background: #fff;
          img {
            max-width: 100%;
            margin: 0;
            &:first-of-type {
              position: absolute;
              z-index: -1;
              border-radius: 26px;
              width: 100%;
              max-width: calc(100% - 46px);
              height: 100%;
              max-height: calc(100% - 52px);
              object-fit: cover;
            }
          }
        }
      }
      &--details {
        padding: 1.75rem 0 0;
        text-align: center;
        span {
          display: block;
          font-family: 'DM Serif Display', serif;
          font-size: 2rem;
          margin-bottom: 4px;
        }
        a {
          color: #013FA3;
          font-size: 14px;
          line-height: 1.3571428571;
          letter-spacing: 1.4px;
          font-weight: 700;
          text-transform: uppercase;
          display: block;
          text-decoration: none;
          margin: 0;
        }
      }
      img {
        max-width: 100%;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .power-rankings-list {
    .action-links {
      position: relative;
    }
    .page-header {
      flex-direction: column;
    }
  }
  .top-three {
    .top-three {
      &__grid {
        grid-template-columns: 1fr;
        grid-row-gap: 3rem;
      }
      &__block {
        &--details {
          padding: 0.5rem 0 0;
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .power-rankings {
    &-list {
      .page-heading {
        &__wrapper {
          &:before {
            clip-path: ellipse(150% 100% at 50% 0%);
          }
          .page {
            &-heading {
              &.center-heading {
                h1 {
                  font-size: 34px;
                  max-width: 380px;
                  margin: 0 auto;
                }
              }
            }
            &-subheading {
              font-size: 12px;
              max-width: 260px;
              margin: 12px auto 0;
            }
          }
          .google-logo {
            max-width: 80px;
          }
          .blurb {
            font-size: 16px;
            max-width: 270px;
          }
        }
      }
      .container {
        .h1 {
          &.text-centered {
            margin: 80px auto 0;
            font-size: 30px;
            padding: 0 2rem;
          }
        }
        .show-agency-toggle {
          max-width: calc(100% - 4rem);
        }
      }
      table {
        max-width: calc(100% - 4rem);
        font-size: 15px;
        th {
          font-size: 16px;
        }
      }
      .action-links {
        padding-bottom: 72px;
      }
      .top-three {
        &__block {
          &--thumbnail {
            padding: 0 2rem;
            &__wrapper {
              max-width: 360px;
              margin: 0 auto;
              img {
                &:first-of-type {
                  border-radius: 20px;
                  max-width: calc(100% - 40px);
                  max-height: calc(100% - 45px);
                }
              }
            }
          }
          &--details {
            span {
              font-size: 1.5rem;
            }
            a {
              font-size: 12px;
            }
          }
          &--spinner {
            top: 22px;
            left: 20px;
            bottom: 22px;
            right: 20px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1170px) {
  .power-rankings {
    &-list {
      .page-heading {
        &__wrapper {
          .page {
            &-heading {
              max-width: 615px;
              h1 {
                font-size: 55px;
              }
            }
            &-subheading {
              font-size: 14px;
              &::after {
                margin: 35px auto 30px;
              }
            }
          }
          .google-logo {
            max-width: 110px;
          }
          .blurb {
            font-size: 16px;
          }
        }
      }
      .container {
        .h1 {
          &.text-centered {
            margin: 100px auto 0;
            max-width: 510px;
            br {
              display: none;
            }
          }
        }
        .show-agency-toggle {
          max-width: calc(100% - 4rem);
        }
      }
      .action-links {
        padding-bottom: 122px;
      }
      table {
        max-width: calc(100% - 4rem);
      }
      .top-three {
        .top-three {
          &__grid {
            column-gap: 15px;
          }
          &__container {
            max-width: calc(100% - 4rem);
          }
          &__block {
            &--spinner {
              top: 15px;
              left: 14px;
              bottom: 15px;
              right: 14px;
            }
            &--thumbnail {
              &__wrapper {
                img {
                  &:first-of-type {
                    border-radius: 16px;
                    max-width: calc(100% - 32px);
                    max-height: calc(100% - 34px);
                  }
                }
              }
            }
            &--details {
              span {
                font-size: 1.5rem;
              }
              a {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
