<template>
 <BaseLayout
   title="Site Status Changes"
   :customClasses="['status-changes']">
   <Form
      @submit.prevent="getSiteStatusChanges">
      <strong>Filters</strong>
      <Input
        :hasLabel="true"
        labelText="Website"
        placeholder="Website"
        v-model="form.website"
        :disabled="loading" />
      <Dropdown
        :options="filteredCompanies"
        customOption="name"
        dropdownPlaceholder="Marketing Company"
        :inputHasLabel="true"
        inputLabelText="Marketing Company"
        :isSearch="true"
        @input="debounceAgencyName($event)"
        ref="filterDropdown1"
        @select="form.marketingCompany = $event"
        :isDisabled="loading" />
      <Input
        :hasLabel="true"
        labelText="Status Code"
        placeholder="Type status code"
        v-model="form.statusCode"
        type="number"
        min="401"
        max="599"
        :disabled="loading" />
      <Dropdown
        dropdownPlaceholder="Results per page"
        :hasIcon="true"
        :options="pagination.dropdownOptions"
        @select="pagination.per_page = $event"
        :inputVal="pagination.per_page"
        :isDisabled="loading" />
      <Button
        type="submit"
        text="Apply filters"
        :isDisabled="loading" />
    </Form>
    <action-links
        textAlign="right"
        v-if="!loading">
      <Button
        @click="clearFilters"
        text="Clear filters" />
    </action-links>
    <Loading v-show="loading" text="Loading changes" />
    <Table
      :loading="loading"
      v-if="sortedResults.length > 0">
      <template slot="head">
        <t-row>
          <t-heading
            @click="sortBy('website')"
            :class="sortedClass('website')">
            Website
          </t-heading>
          <t-heading
            @click="sortBy('status_code')"
            :class="sortedClass('status_code')">
            Status Code
          </t-heading>
          <t-heading
            @click="sortBy('description')"
            :class="sortedClass('description')">
            Description
          </t-heading>
          <t-heading
            @click="sortBy('marketing_company.name')"
            :class="sortedClass('marketing_company.name')">
            Agency
          </t-heading>
          <t-heading
            @click="sortBy('date')"
            :class="sortedClass('date')">
            Date
          </t-heading>
          <t-heading
            @click="sortBy('archive_url')"
            :class="sortedClass('archive_url')">
            Archive
          </t-heading>
        </t-row>
      </template>
      <template slot="body">
        <t-row
          v-for="(result, i) in sortedResults"
          :key="i">
          <t-cell>
            <a
              :href="result.website"
              target="_blank"
              rel="noopener">
              {{ result.website }}
            </a>
          </t-cell>
          <t-cell>{{ result.status_code }}</t-cell>
          <t-cell>{{ result.description }}</t-cell>
          <t-cell>
            <div class="logo-container" v-if="result.marketing_company">
              <img height="24" width="24"
              :src="result.marketing_company.favicon_url || result.marketing_company.icon_url || require('../../assets/img/fallback_fav.png')"/>
              {{ result.marketing_company.name }}
            </div>
          </t-cell>
          <t-cell>{{ result.date | moment('M/DD/YYYY') }}</t-cell>
          <t-cell>
            <div class="logo-container">
              <a v-if="result.archive_url"
              :href="result.archive_url"
              target="_blank"
              rel="noopener">
              archive.org
            </a>
            </div>
          </t-cell>
          <t-cell>
            <Button
              :hasLink="true"
              :btnLink="{
                name: 'profile',
                query: {
                  domain: formatDomain(result.website)
                }
              }"
              :isView="true" />
          </t-cell>
        </t-row>
      </template>
    </Table>
    <p v-else-if="!loading && sortedResults.length === 0">
      No results. Try changing or clearing filters.
    </p>
    <Pagination
      v-if="!loading"
      :totalItems="changes.total"
      :perPage="changes.per_page"
      :currentPage="changes.page"
      :nextNum="changes.next_num"
      :prevNum="changes.prev_num"
      @initPagination="$event(pagination)"
      @pageChanged="changePage($event)" />
  </BaseLayout>
</template>

<script>
import Table from '@/components/table/Table'
import TableRow from '@/components/table/TableRow'
import TableDataCell from '@/components/table/TableDataCell'
import TableHeading from '@/components/table/TableHeading'
import Input from '@/components/form/Input'
import Dropdown from '@/components/form/Dropdown'
import Button from '@/components/form/Button'
import Form from '@/components/form/Form'
import BaseLayout from '@/views/BaseLayout'
import Loading from '@/components/misc/Loading'
import ActionLinks from '@/components/misc/ActionLinks'
import Pagination from '@/components/misc/Pagination'
import { mapState } from 'vuex'
import _ from 'lodash'

export default {
  name: 'DomainStatusChanges',
  components: {
    Table,
    Button,
    Loading,
    Pagination,
    BaseLayout,
    Input,
    Dropdown,
    Form,
    ActionLinks,
    't-row': TableRow,
    't-cell': TableDataCell,
    't-heading': TableHeading
  },
  data () {
    return {
      loading: false,
      changes: {},
      filteredCompanies: [],
      sort: {
        key: 'last_checked',
        isAsc: false
      },
      form: {
        website: '',
        marketingCompany: '',
        statusCodes: ['None', 1, 2, 3, 4, 5]
      },
      pagination: {
        currentPage: 1,
        dropdownOptions: [20, 50, 100, 250, 500, 1000],
        per_page: 100
      }
    }
  },
  computed: {
    ...mapState({
      formData: state => state.form
    }),

    sortedResults () {
      const list = this.changes.status_changes ? this.changes.status_changes : []
      if (this.sort.key) {
        list.sort((a, b) => {
          a = _.get(a, this.sort.key)
          b = _.get(b, this.sort.key)
          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
        })
      }
      return list
    }
  },
  mounted () {
    this.getSiteStatusChanges()
  },
  methods: {
    sortedClass (key) {
      return this.sort.key === key
        ? `sorted ${this.sort.isAsc ? 'asc' : 'desc'}`
        : ''
    },

    debounceAgencyName: _.debounce(function (val) {
      if (val.length > 2) {
        this.form.agencyName = val
        this.getMarketingCompanies(val)
      } else {
        this.filteredCompanies = []
      }
      // this.$forceUpdate()
    }, 200),

    sortBy (key) {
      this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false
      this.sort.key = key
    },

    getMarketingCompanies (name) {
      this.$arc.get('marketing_companies?per_page=1000', {
        params: {
          ...(name ? { name_like: name } : {})
        }
      })
        .then(res => {
          this.filteredCompanies = res.data.marketing_companies
          // this.getPagedResults()
        })
        .catch(err => {
          console.error(err)
        })
    },

    getSiteStatusChanges (e) {
      if (e && e.type === 'submit') {
        this.pagination.currentPage = 1
      }
      this.loading = true
      this.$arc.get('domains/status_changes', {
        params: {
          ...(this.form.website ? { domain_name_like: this.form.website } : {}),
          ...(this.form.marketingCompany ? { marketing_company_name_like: this.form.marketingCompany } : {}),
          ...(this.form.statusCode ? { status_code: this.form.statusCode } : {}),
          ...(this.pagination.currentPage ? { page: this.pagination.currentPage } : {}),
          ...(this.pagination.per_page ? { per_page: this.pagination.per_page } : {})
        }
      })
        .then(res => {
          this.changes = res.data
          this.loading = false
          // this.getPagedResults()
        })
        .catch(err => {
          console.error(err)
          this.loading = false
        })
    },

    getPagedResults () {
      const promises = []
      for (
        let i = 2;
        i < Math.ceil(this.changes.total / this.changes.per_page) + 1;
        i++
      ) {
        promises.push(
          this.$arc.get(`domains/status_changes?page=${i}&per_page=1000`)
        )
      }
      Promise.all(promises).then((values) => {
        values.map(p => {
          this.changes.status_changes.push(...p.data.status_changes)
        })
      })
    },

    changePage (e) {
      this.pagination.currentPage = e
      this.getSiteStatusChanges()
    },

    clearFilters () {
      var self = this
      Object.keys(this.form).forEach(function (key) {
        self.form[key] = ''
        return self.form
      })
      this.$refs.filterDropdown1.selected = ''
      this.getSiteStatusChanges()
      this.pagination.currentPage = 1
    },

    formatDomain (domain) {
      return domain.toString().includes('https://www.')
        ? domain.toString().replace('https://www.', '')
        : domain.toString().replace('https://', '')
    },

    formatSlug (completedAt, location, practiceArea, taskId) {
      const completed = this.$moment(completedAt).format('M-DD-YYYY')
      const newLocation = location.toLowerCase().replace(', ', '-').replace(' ', '-')
      const area = practiceArea.toLowerCase().replace(' ', '-')
      return `${newLocation}/${area}/${completed}?id=${taskId}`
    }
  }
}
</script>

<style lang="scss">
.status-changes {
  form {
    align-items: flex-end;
    margin-bottom: 30px;
    justify-content: space-between;
    strong {
      margin-bottom: 1rem;
    }
    > div {
      flex-basis: calc((100% / 4) - 30px) !important;
      &.flex {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        label {
          font-weight: 600;
          margin-bottom: 10px;
          display: block;
          width: 100%;
        }
        .input-container {
          flex-basis: calc((100% / 2) - 7px);
        }
      }
    }
    strong {
      margin-right: 15px;
    }
  }
  .logo-container {
    img {
      margin: 0 1rem;
      vertical-align: bottom;
      display: inline-block;
    }
  }
}
</style>
