<template>
  <BaseLayout
    title="Law Firm Website Power Rankings Report"
    :centerHeading="true"
    :customClasses="['power-rankings-blacklist']">
    <p><strong>Blacklist</strong></p>
    <p>
      Enter each domain you want to blacklist below, each on a separate line.
      Blacklisted domains will be excluded and not appear on any Power Rankings reports OR
      any regular ARC reports.
      <br />
      <br />
      <strong>
        NOTE: By setting a domain to be blacklisted, you will not be able to remove it for now.
      </strong>
    </p>
    <div class="spotlight__block">
      <div class="spotlight__form">
        <textarea
          rows="10"
          placeholder="Start typing domains"
          v-model="domains"></textarea>
        <Button
          text="Save"
          btnType="primary"
          @click="blacklistDomains" />
      </div>
    </div>
    <alert-modal
      :text="alertText"
      :type="alertType"
      ref="alert"
      @close="clearData">
      <div v-if="newDomains.length > 0">
        <p
          v-for="(domain, idx) in newDomains"
          :key="idx">
          {{ domain }}
        </p>
      </div>
    </alert-modal>
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/views/BaseLayout'
import Button from '@/components/form/Button'
import AlertModal from '@/components/modal/AlertModal'

export default {
  name: 'PowerRankingsBlacklist',
  components: {
    BaseLayout,
    Button,
    'alert-modal': AlertModal
  },
  data () {
    return {
      domains: '',
      blacklistedDomains: [],
      alertType: '',
      alertText: ''
    }
  },
  computed: {
    newDomains () {
      return this.domains.split('\n')
        .filter(domain => domain.trim() !== '')
        .filter(domain => !this.blacklistedDomains.includes(domain))
    },

    domainsArray () {
      return this.domains.split('\n')
    }
  },
  mounted () {
    this.getBlacklistedDomains()
  },
  methods: {
    log (e) {
      console.log(e)
    },

    clearData () {
      this.alertType = ''
      this.alertText = ''
      this.domains = ''
      this.getBlacklistedDomains()
    },

    blacklistDomains () {
      const formattedDomains = this.domainsArray
        .filter(domain => domain.trim() !== '')
        .map(domain => `https://${domain}`)

      if (this.newDomains.length > 0) {
        this.$arc.patch('domains/blacklist', {
          ...(formattedDomains.length > 0 ? { urls: formattedDomains } : {})
        }).then(res => {
          this.alertType = 'success'
          this.alertText = 'Domains have been blacklisted'
          this.$refs.alert.toggleModal()
        }).catch(err => {
          console.error(err)
        })
      } else {
        this.alertText = 'No new domains to blacklist'
        this.alertType = 'error'
        this.$refs.alert.toggleModal()
      }
    },

    getBlacklistedDomains () {
      this.$arc.get('domains/blacklist')
        .then(res => {
          const domainArr = res.data.domains.map(domain => domain.name)
          this.blacklistedDomains = domainArr
          this.domains = domainArr.join('\n')
        }).catch(err => {
          console.error(err)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.power-rankings-blacklist {
  p {
    font-weight: 400;
    max-width: 660px;
    margin: 0 auto;
    strong {
      display: block;
      margin-bottom: 10px;
    }
  }
  .spotlight {
    &__form {
      max-width: 800px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    &__block {
      background: #f0f5fb;
      margin: 30px -2rem 0;
      padding: 3rem 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      textarea {
        height: 400px;
        width: 100%;
        border: none;
        margin-bottom: 20px;
        padding: 1rem 2rem;
        font-size: 1rem;
        font-family: 'Open Sans', sans-serif;
        line-height: 1.5;
        &:focus {
          outline: 1px solid darken(#f0f5fb, 20%);
        }
      }
    }
  }
}
</style>
