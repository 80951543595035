import Cookies from 'js-cookie'

export const auth = {
  namespaced: true,
  state: {
    isAuthenticated: false,
    hasOutreachKey: !!Cookies.get('_.arc_score.salesOutreachKey'),
    profile: null,
    profilePicture: null
  },
  mutations: {
    setIsAuthenticated (state, data) {
      state.authData = data
    },
    setOutreachKey (state, hasKey) {
      state.hasOutreachKey = hasKey
    },
    setProfile (state, profile) {
      state.profile = profile
    },
    setProfilePicture (state, picture) {
      state.profilePicture = picture
    }
  },
  actions: {
    setIsAuthenticated ({ commit }, data) {
      commit('setIsAuthenticated', data)
    },
    setProfile ({ commit }, data) {
      commit('setProfile', data)
    },
    setProfilePicture ({ commit }, data) {
      commit('setProfilePicture', data)
    }
  },
  getters: {
    isAuthenticated: (state) => state.isAuthenticated,
    hasOutreachKey: (state) => state.hasOutreachKey,
    getProfile: (state) => state.profile,
    getProfilePicture: (state) => state.profilePicture
  }
}
