<template>
  <div
    :class="[
      { 'has-label': hasLabel }
    ]">
    <label
      class="switch">
      <input
        type="checkbox"
        :checked="isChecked"
        @change.stop="$emit('change', $event)">
      <span class="slider"></span>
    </label>
    <span class="text" v-show="hasLabel">{{ labelText }}</span>
  </div>
</template>

<script>
export default {
  name: 'Toggle',
  props: {
    hasLabel: {
      type: Boolean,
      default: false
    },
    labelText: String,
    isChecked: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
div.has-label {
  display: flex;
  align-items: center;
  .switch {
    display: inline-flex;
  }
  .text {
    display: inline-flex;
    font-size: 14px;
    font-weight: 700;
    &:first-child {
      margin-right: 10px;
    }
    &:last-child {
      margin-left: 10px;
    }
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #c8d5dd;
  border-radius: 34px;
  -webkit-transition: .4s;
  transition: .4s;
  &:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    border-radius: 50%;
    -webkit-transition: .4s;
    transition: .4s;
  }
}

input {
  &:checked + .slider {
    background-color: #32d094;
  }
  &:focus + .slider {
    box-shadow: 0 0 1px #32d094;
  }
  &:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
}
</style>
