<template>
  <div
    class="alert-modal"
    role="alert"
    :class="`${type ? 'is-' + type : ''}`"
    v-show="isActive">
    <div class="alert-modal__card">
      <span class="alert-modal__icon">
        <img
          src="@/assets/img/check.png"
          v-if="type === 'success'">
      </span>
      <h3 class="alert-modal__title">{{ text }}</h3>
      <div class="alert-modal__content">
        <slot></slot>
      </div>
      <button-group
        btnAlign="center">
        <Button
          text="Ok"
          btnType="fancy"
          :isUppercase="true"
          @click="toggleModal" />
      </button-group>
    </div>
  </div>
</template>

<script>
import Button from '@/components/form/Button'
import ButtonGroup from '@/components/form/ButtonGroup'

export default {
  name: 'AlertModal',
  components: {
    Button,
    ButtonGroup
  },
  data () {
    return {
      isActive: false
    }
  },
  props: {
    type: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    }
  },
  computed: {
    icon () {
      return this.type === 'success'
        ? 'check-circle'
        : ''
    }
  },
  methods: {
    toggleModal () {
      this.isActive = !this.isActive
      if (!this.isActive) {
        this.$emit('closed')
      }
    }
  }
}
</script>

<style lang="scss">
.alert-modal {
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  &.is-success {
    .alert-modal {
      &__icon {
        color: rgba(0, 192, 0);
      }
    }
  }
  &__title {
    font-family: "DM Serif Display", serif;
    font-size: 32px !important;
    line-height: 1.23;
    color: #0158E3;
    max-width: 250px;
    margin: 0 auto;
    &:after {
      content: '';
      display: block;
      width: 185px;
      height: 1px;
      background: #EE6152;
      margin: 23px auto;
    }
  }
  &__card {
    background: #fff;
    padding: 4.6875rem 2rem;
    width: 470px;
    border-radius: 6px;
  }
  &__icon {
    // font-size: 2.5rem;
    margin: 0 auto 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 65px;
      height: 65px;
      margin: 0 !important;
    }
  }
  &__content {
    margin-bottom: 20px;
    text-align: center;
    font-size: 1rem;
    line-height: 1.4;
  }
}
</style>
